import { QEQ } from './common/QE';
import { log as QELog } from './common/QE';
import { QETerm } from './common/QETerm';
import * as QEGrammar from './common/QEGrammar';
import { QEHelper } from './common/QEHelper';
import { QESolver } from 'QESolver';
import { QESolverStep } from './server/Solver/QESolverStep';
import { QEValGen } from './server/QEValGen';
import { QEValConstants } from './common/QEValConstants';

import * as QEWidget from './common/QEWidget';
import { QEWidgetMC as MC } from './common/Widget/QEWidgetMC';
import { QEWidgetQuad as Quad } from './common/Widget/Quad';
import { QEWidgetPolygon as Polygon } from './common/Widget/Polygon';
import { QEWidgetFractionSet as FractionSet } from './common/Widget/FractionSet';
import { QEWidgetFractionShape as FractionShape } from './common/Widget/FractionShape';
import { QEWidgetAnalogClock as AnalogClock } from './common/Widget/AnalogClock';
import { QEWidgetPseudo3d as Pseudo3d } from './common/Widget/Pseudo3d';
import { QEWidgetThermometer as Thermometer } from './common/Widget/Thermometer';
import { QEWidgetSpinner as Spinner } from './common/Widget/Spinner';
import { QEWidgetDice as Dice } from './common/Widget/Dice';
import { QEWidgetCoin as Coin } from './common/Widget/Coin';
import { QEWidgetEmoji as Emoji } from './common/Widget/Emoji';
import { QEWidgetBarGraph as BarGraph } from './common/Widget/Plot/BarGraph';
import { QEWidgetCircleGraph as CircleGraph } from './common/Widget/Plot/CircleGraph';
import { QEWidgetDotPlot as DotPlot } from './common/Widget/Plot/DotPlot';
import { QEWidgetHistogram as Histogram } from './common/Widget/Plot/Histogram';
import { QEWidgetLineGraph as LineGraph } from './common/Widget/Plot/LineGraph';
import { QEWidgetPictograph as Pictograph } from './common/Widget/Plot/Pictograph';
import { QEWidgetTable as Table } from './common/Widget/Table';
import { QEWidgetDropDown as DropDown } from './common/Widget/DropDown';
import { QEEqKeyboard } from 'QEEqKeyboard'; // aliased in webpack.config.js
import { QEWidgetGraph } from 'QEWidgetGraph'; // aliased in webpack.config.js
import { QEWidgetDragSource as DragSource } from './common/Widget/DragSource';
import { QEWidgetDragSort as DragSort } from './common/Widget/DragSort';
import { QEWidgetDropZone as DropZone } from './common/Widget/DropZone';
import { QEWidgetDropBucket as DropBucket } from './common/Widget/DropBucket';
import { QEWidgetDropList as DropList } from './common/Widget/DropList';
import { QEWidgetVerticalMath as VerticalMath } from './common/Widget/VerticalMath';

export const Q = QEQ;
export const log = QELog;
export const Term = QETerm;
export const Parser = QEGrammar;
export const Solver = QESolver;
export const SolverStep = QESolverStep;
export const Widget = Object.assign({}, QEWidget, {
	MC: MC,
	Graph: QEWidgetGraph,
	Quad: Quad,
	Polygon: Polygon,
	FractionSet: FractionSet,
	FractionShape: FractionShape,
	AnalogClock: AnalogClock,
	Pseudo3d: Pseudo3d,
	Thermometer: Thermometer,
	Spinner: Spinner,
	Dice: Dice,
	Coin: Coin,
	Emoji: Emoji,
	BarGraph: BarGraph,
	CircleGraph: CircleGraph,
	DotPlot: DotPlot,
	Histogram: Histogram,
	LineGraph: LineGraph,
	Pictograph: Pictograph,
	Table: Table,
	DropDown: DropDown,
	DragSource: DragSource,
	DragSort: DragSort,
	DropZone: DropZone,
	DropBucket: DropBucket,
	DropList: DropList,
	VerticalMath: VerticalMath,
});
export const EqKeyboard = QEEqKeyboard;
export const ValGen = QEValGen;
export const Helper = QEHelper;
export const ValConstants = QEValConstants;
