import { QETerm } from '../QETerm';
import { QEHelper } from '../QEHelper';
// import { QEValConstants } from '../QEValConstants';
import { QEWidget, DisplayOptions, TagElement } from '../QEWidget';

export class QEWidgetEmoji extends QEWidget {
	
	category_id: string;
	display_options: { [key: string]: any };
	emoji_id: string;	

	static default_style = {};
	
	static emoji_maps = {
		activities: {
			basketball: {
				char: '⛹️',
				genders: ['neutral', 'male', 'female'],
				label: 'Baskbetball Player',
				skin_tones: ['default', 'light','medium_light', 'medium', 'medium_dark', 'dark'],
				variants: {
					female: '⛹️‍♀️',
					female_dark: '⛹🏿‍♀️',
					female_default: '⛹️‍♀️',
					female_light: '⛹🏻‍♀️',
					female_medium_dark: '⛹🏾‍♀️',
					female_medium: '⛹🏽‍♀️',
					female_medium_light: '⛹🏼‍♀️',
					male: '⛹️‍♂️',
					male_dark: '⛹🏿‍♂️',
					male_default: '⛹️‍♂️',
					male_light: '⛹🏻‍♂️',
					male_medium_dark: '⛹🏾‍♂️',
					male_medium: '⛹🏽‍♂️',
					male_medium_light: '⛹🏼‍♂️',
					neutral: '⛹️',
					neutral_dark: '⛹🏿',
					neutral_default: '⛹️',
					neutral_light: '⛹🏻',
					neutral_medium_dark: '⛹🏾',
					neutral_medium: '⛹🏽',
					neutral_medium_light: '⛹🏼',
				}
			},
			// climbing: {chars: ['🧗', '🧗‍♂️', '🧗‍♀️'], genders: ['neutral', 'male', 'female'],label: '', skin_tones: ['default', 'light', 'medium_light', 'medium', 'medium_dark', 'dark'], variants: {female: '', female_dark: '', female_default: '', female_light: '', female_medium_dark: '', female_medium: '', female_medium_light: '', male: '', male_dark: '', male_default: '', male_light: '', male_medium_dark: '', male_medium: '', male_medium_light: '', neutral: '', neutral_dark: '', neutral_default: '', neutral_light: '', neutral_medium_dark: '', neutral_medium: '', neutral_medium_light: ''}},
			// cycling: {chars: ['🚴', '🚴‍♂️', '🚴‍♀️'], genders: ['neutral', 'male', 'female'],label: '', skin_tones: ['default', 'light', 'medium_light', 'medium', 'medium_dark', 'dark'], variants: {female: '', female_dark: '', female_default: '', female_light: '', female_medium_dark: '', female_medium: '', female_medium_light: '', male: '', male_dark: '', male_default: '', male_light: '', male_medium_dark: '', male_medium: '', male_medium_light: '', neutral: '', neutral_dark: '', neutral_default: '', neutral_light: '', neutral_medium_dark: '', neutral_medium: '', neutral_medium_light: ''}},
			dancing: {
				char: '🕺',
				genders: ['male', 'female'],
				label: '',
				skin_tones: ['default', 'light','medium_light', 'medium', 'medium_dark', 'dark'],
				variants: {
					female: '💃',
					female_dark: '💃🏿',
					female_default: '💃',
					female_light: '💃🏻',
					female_medium_dark: '💃🏾',
					female_medium: '💃🏽',
					female_medium_light: '💃🏼',
					male: '🕺',
					male_dark: '🕺🏿',
					male_default: '🕺',
					male_light: '🕺🏻',
					male_medium_dark: '🕺🏾',
					male_medium: '🕺🏽',
					male_medium_light: '🕺🏼',
				}
			},
			// fencing: {char: '🤺',label: '', skin_tones: ['default', 'light', 'medium_light', 'medium', 'medium_dark', 'dark'], variants: {neutral_dark: '', neutral_default: '', neutral_light: '', neutral_medium_dark: '', neutral_medium: '', neutral_medium_light: ''}},
			// golfing: {chars: ['🏌️', '🏌️‍♂️', '🏌️‍♀️'], genders: ['neutral', 'male', 'female'],label: '', skin_tones: ['default', 'light', 'medium_light', 'medium', 'medium_dark', 'dark'], variants: {female: '', female_dark: '', female_default: '', female_light: '', female_medium_dark: '', female_medium: '', female_medium_light: '', male: '', male_dark: '', male_default: '', male_light: '', male_medium_dark: '', male_medium: '', male_medium_light: '', neutral: '', neutral_dark: '', neutral_default: '', neutral_light: '', neutral_medium_dark: '', neutral_medium: '', neutral_medium_light: ''}},
			// gymnastics: {chars: ['🤸', '🤸‍♂️', '🤸‍♀️'], genders: ['neutral', 'male', 'female'],label: '', skin_tones: ['default', 'light', 'medium_light', 'medium', 'medium_dark', 'dark'], variants: {female: '', female_dark: '', female_default: '', female_light: '', female_medium_dark: '', female_medium: '', female_medium_light: '', male: '', male_dark: '', male_default: '', male_light: '', male_medium_dark: '', male_medium: '', male_medium_light: '', neutral: '', neutral_dark: '', neutral_default: '', neutral_light: '', neutral_medium_dark: '', neutral_medium: '', neutral_medium_light: ''}},
			// handball: {chars: ['🤾', '🤾‍♂️', '🤾‍♀️'], genders: ['neutral', 'male', 'female'],label: '', skin_tones: ['default', 'light', 'medium_light', 'medium', 'medium_dark', 'dark'], variants: {female: '', female_dark: '', female_default: '', female_light: '', female_medium_dark: '', female_medium: '', female_medium_light: '', male: '', male_dark: '', male_default: '', male_light: '', male_medium_dark: '', male_medium: '', male_medium_light: '', neutral: '', neutral_dark: '', neutral_default: '', neutral_light: '', neutral_medium_dark: '', neutral_medium: '', neutral_medium_light: ''}},
			// horseback_riding: {char: '🏇',label: '', skin_tones: ['default', 'light', 'medium_light', 'medium', 'medium_dark', 'dark'], variants: {neutral_dark: '', neutral_default: '', neutral_light: '', neutral_medium_dark: '', neutral_medium: '', neutral_medium_light: ''}},
			// mountain_biking: {chars: ['🚵', '🚵‍♂️', '🚵‍♀️'], genders: ['neutral', 'male', 'female'],label: '', skin_tones: ['default', 'light', 'medium_light', 'medium', 'medium_dark', 'dark'], variants: {female: '', female_dark: '', female_default: '', female_light: '', female_medium_dark: '', female_medium: '', female_medium_light: '', male: '', male_dark: '', male_default: '', male_light: '', male_medium_dark: '', male_medium: '', male_medium_light: '', neutral: '', neutral_dark: '', neutral_default: '', neutral_light: '', neutral_medium_dark: '', neutral_medium: '', neutral_medium_light: ''}},
			// rowing: {chars: ['🚣', '🚣‍♂️', '🚣‍♀️'], genders: ['neutral', 'male', 'female'],label: '', skin_tones: ['default', 'light', 'medium_light', 'medium', 'medium_dark', 'dark'], variants: {female: '', female_dark: '', female_default: '', female_light: '', female_medium_dark: '', female_medium: '', female_medium_light: '', male: '', male_dark: '', male_default: '', male_light: '', male_medium_dark: '', male_medium: '', male_medium_light: '', neutral: '', neutral_dark: '', neutral_default: '', neutral_light: '', neutral_medium_dark: '', neutral_medium: '', neutral_medium_light: ''}},
			running: {
				char: '🏃',
				genders: ['neutral', 'male', 'female'],
				label: '',
				skin_tones: ['default', 'light','medium_light', 'medium', 'medium_dark', 'dark'],
				variants: {
					female: '🏃‍♀️',
					female_dark: '🏃🏿‍♀️',
					female_default: '🏃‍♀️',
					female_light: '🏃🏻‍♀️',
					female_medium_dark: '🏃🏾‍♀️',
					female_medium: '🏃🏽‍♀️',
					female_medium_light: '🏃🏼‍♀️',
					male: '🏃‍♂️',
					male_dark: '🏃🏿‍♂️',
					male_default: '🏃‍♂️',
					male_light: '🏃🏼‍♂️',
					male_medium_dark: '🏃🏾‍♂️',
					male_medium: '🏃🏽‍♂️',
					male_medium_light: '🏃🏻‍♂️',
					neutral: '🏃',
					neutral_dark: '🏃🏿',
					neutral_default: '🏃',
					neutral_light: '🏃🏻',
					neutral_medium_dark: '🏃🏾',
					neutral_medium: '🏃🏽',
					neutral_medium_light: '🏃🏼',
				}
			},
			// skiing: {char: '⛷️',label: '', skin_tones: ['default', 'light', 'medium_light', 'medium', 'medium_dark', 'dark'], variants: {neutral_dark: '', neutral_default: '', neutral_light: '', neutral_medium_dark: '', neutral_medium: '', neutral_medium_light: ''}},
			// snowboarding: {char: '🏂',label: '', skin_tones: ['default', 'light', 'medium_light', 'medium', 'medium_dark', 'dark'], variants: {neutral_dark: '', neutral_default: '', neutral_light: '', neutral_medium_dark: '', neutral_medium: '', neutral_medium_light: ''}},
			standing: {
				char: '🧍',
				genders: ['neutral', 'male', 'female'],
				label: '',
				skin_tones: ['default', 'light','medium_light', 'medium', 'medium_dark', 'dark'],
				variants: {
					female: '🧍‍♀️',
					female_dark: '🧍🏿‍♀️',
					female_default: '🧍‍♀️',
					female_light: '🧍🏻‍♀️',
					female_medium_dark: '🧍🏾‍♀️',
					female_medium: '🧍🏽‍♀️',
					female_medium_light: '🧍🏼‍♀️',
					male: '🧍🏻‍♂️',
					male_dark: '🧍🏿‍♂️',
					male_default: '🧍🏻‍♂️',
					male_light: '🧍🏻‍♂️',
					male_medium_dark: '🧍🏾‍♂️',
					male_medium: '🧍🏽‍♂️',
					male_medium_light: '🧍🏼‍♂️',
					neutral: '🧍',
					neutral_dark: '🧍🏿',
					neutral_default: '🧍',
					neutral_light: '🧍🏻',
					neutral_medium_dark: '🧍🏾',
					neutral_medium: '🧍🏽',
					neutral_medium_light: '🧍🏼',
				}
			},
			// surfing: {chars: ['🏄', '🏄‍♂️', '🏄‍♀️'], genders: ['neutral', 'male', 'female'], label: '', skin_tones: ['default', 'light', 'medium_light', 'medium', 'medium_dark', 'dark'], variants: {female: '', female_dark: '', female_default: '', female_light: '', female_medium_dark: '', female_medium: '', female_medium_light: '', male: '', male_dark: '', male_default: '', male_light: '', male_medium_dark: '', male_medium: '', male_medium_light: '', neutral: '', neutral_dark: '', neutral_default: '', neutral_light: '', neutral_medium_dark: '', neutral_medium: '', neutral_medium_light: ''}},
			// swimming: {chars: ['🏊', '🏊‍♂️', '🏊‍♀️'], genders: ['neutral', 'male', 'female'], label: '', skin_tones: ['default', 'light', 'medium_light', 'medium', 'medium_dark', 'dark'], variants: {female: '', female_dark: '', female_default: '', female_light: '', female_medium_dark: '', female_medium: '', female_medium_light: '', male: '', male_dark: '', male_default: '', male_light: '', male_medium_dark: '', male_medium: '', male_medium_light: '', neutral: '', neutral_dark: '', neutral_default: '', neutral_light: '', neutral_medium_dark: '', neutral_medium: '', neutral_medium_light: ''}},
			walking: {
				char: '🚶',
				genders: ['neutral', 'male', 'female'],
				label: '',
				skin_tones: ['default', 'light','medium_light', 'medium', 'medium_dark', 'dark'],
				variants: {
					female: '🚶‍♀️',
					female_dark: '🚶🏿‍♀️',
					female_default: '🚶‍♀️',
					female_light: '🚶🏻‍♀️',
					female_medium_dark: '🚶🏾‍♀️',
					female_medium: '🚶🏽‍♀️',
					female_medium_light: '🚶🏼‍♀️',
					male: '🚶‍♂️',
					male_dark: '🚶🏿‍♂️',
					male_default: '🚶‍♂️',
					male_light: '🚶🏻‍♂️',
					male_medium_dark: '🚶🏾‍♂️',
					male_medium: '🚶🏽‍♂️',
					male_medium_light: '🚶🏼‍♂️',
					neutral: '🚶',
					neutral_dark: '🚶🏿',
					neutral_default: '🚶',
					neutral_light: '🚶🏻',
					neutral_medium_dark: '🚶🏾',
					neutral_medium: '🚶🏽',
					neutral_medium_light: '🚶🏼',
				}
			},
			// water_polo: {chars: ['🤽', '🤽‍♂️', '🤽‍♀️'], genders: ['neutral', 'male', 'female'], label: '', skin_tones: ['default', 'light', 'medium_light', 'medium', 'medium_dark', 'dark'], variants: {female: '', female_dark: '', female_default: '', female_light: '', female_medium_dark: '', female_medium: '', female_medium_light: '', male: '', male_dark: '', male_default: '', male_light: '', male_medium_dark: '', male_medium: '', male_medium_light: '', neutral: '', neutral_dark: '', neutral_default: '', neutral_light: '', neutral_medium_dark: '', neutral_medium: '', neutral_medium_light: ''}},
			// weight_lifting: {chars: ['🏋️', '🏋️‍♂️', '🏋️‍♀️'], genders: ['neutral', 'male', 'female'], label: '', skin_tones: ['default', 'light', 'medium_light', 'medium', 'medium_dark', 'dark'], variants: {female: '', female_dark: '', female_default: '', female_light: '', female_medium_dark: '', female_medium: '', female_medium_light: '', male: '', male_dark: '', male_default: '', male_light: '', male_medium_dark: '', male_medium: '', male_medium_light: '', neutral: '', neutral_dark: '', neutral_default: '', neutral_light: '', neutral_medium_dark: '', neutral_medium: '', neutral_medium_light: ''}},
			// wrestling: {chars: ['🤼', '🤼‍♂️', '🤼‍♀️'], genders: ['neutral', 'male', 'female'], label: '', skin_tones: ['default', 'light', 'medium_light', 'medium', 'medium_dark', 'dark'], variants: {female: '', female_dark: '', female_default: '', female_light: '', female_medium_dark: '', female_medium: '', female_medium_light: '', male: '', male_dark: '', male_default: '', male_light: '', male_medium_dark: '', male_medium: '', male_medium_light: '', neutral: '', neutral_dark: '', neutral_default: '', neutral_light: '', neutral_medium_dark: '', neutral_medium: '', neutral_medium_light: ''}},
			// yoga: {chars: ['🧘', '🧘‍♂️', '🧘‍♀️'], genders: ['neutral', 'male', 'female'], label: '', skin_tones: ['default', 'light', 'medium_light', 'medium', 'medium_dark', 'dark'], variants: {female: '', female_dark: '', female_default: '', female_light: '', female_medium_dark: '', female_medium: '', female_medium_light: '', male: '', male_dark: '', male_default: '', male_light: '', male_medium_dark: '', male_medium: '', male_medium_light: '', neutral: '', neutral_dark: '', neutral_default: '', neutral_light: '', neutral_medium_dark: '', neutral_medium: '', neutral_medium_light: ''}},	
		},
		alcohol: {
			beer: {char: '🍺', label: 'Beer', plural: 'beers'},
			cocktail: {char: '🍸', label: 'Cocktail', plural: '', alt_label: 'Martini'},
			sake: {char: '🍶', label: 'Sake', plural: ''},
			sparkling_wine: {char: '🍾', label: 'Sparkling Wine', plural: '', alt_label: 'Champagne'},
			spirit: {char: '🥃', label: 'Spirit', plural: '', alt_label: 'Whiskey'},
			tropical: {char: '🍹', label: 'Tropical Cocktail', plural: '', alt_label: 'Pina Colada'},
			wine: {char: '🍷', label: 'Wine', plural: ''},
		},
		animals: { // animal faces
			baby_chick: {char: '🐤', label: 'Chick', plural: 'Chicks'},
			bear: {char: '🐻', label: 'Bear', plural: 'Bears'},
			bird: {char: '🐦', label: 'Bird', plural: 'Birds'},
			cat_face: {char: '🐱', label: 'Cat', plural: 'Cats'},
			chicken: {char: '🐔', label: 'Chicken', plural: 'Chickens'},
			cow_face: {char: '🐮', label: 'Cow', plural: 'Cows'},
			dog_face: {char: '🐶', label: 'Dog', plural: 'Dogs'},
			fox: {char: '🦊', label: 'Fox', plural: 'Foxes'},
			hamster: {char: '🐹', label: 'Hamster', plural: 'Hamsters'},
			horse_face: {char: '🐴', label: 'Horse', plural: 'Horses'},
			koala: {char: '🐨', label: 'Koala', plural: 'Koalas'},
			lion: {char: '🦁', label: 'Lion', plural: 'Lions'},
			monkey_face: {char: '🐵', label: 'Monkey', plural: 'Monkeys'},
			// moose: {char: '🫎', label: 'Moose', plural: 'Moose'}, // not supported on win10
			mouse_face: {char: '🐭', label: 'Mouse', plural: 'Mice'},
			panda: {char: '🐼', label: 'Panda', plural: 'Pandas'},
			penguin: {char: '🐧', label: 'Penguin', plural: 'Penguins'},
			pig_face: {char: '🐷', label: 'Pig', plural: 'Pigs'},
			// polar_bear: {char: '🐻‍❄️', label: 'Polar Bear', plural: 'Polar Bears'}, // not supported on win10
			rabbit_face: {char: '🐰', label: 'Rabbit', plural: 'Rabbits'},
			tiger_face: {char: '🐯', label: 'Tiger', plural: 'Tigers'},
		},
		aquatic: {
			crab: {char: '🦀', label: 'Crab', plural: 'Crabs'},
			dolphin: {char: '🐬', label: 'Dolphin', plural: 'Dolphins'},
			fish: {char: '🐟', label: 'Fish', plural: 'Fishes'},
			// jelly_fish: {char: '🪼', label: 'Jellyfish', plural: ''}, // not supported on win10
			lobster: {char: '🦞', label: 'Lobster', plural: 'Lobsters'},
			octopus: {char: '🐙', label: 'Octopus', plural: 'Octopi'},
			puffer_fish: {char: '🐡', label: 'Puffer Fish', plural: ''},
			// seal: {char: '🦭', label: 'Seal', plural: 'Seals'}, // not supported on win10
			shark: {char: '🦈', label: 'Shark', plural: 'Sharks'},
			shrimp: {char: '🦐', label: 'Shrimp', plural: 'Shrimps'},
			squid: {char: '🦑', label: 'Squid', plural: 'Squids'},
			tropical_fish: {char: '🐠', label: 'Tropical Fish', plural: ''},
			whale_1: {char: '🐳', label: 'Whale', plural: 'Whales'},
			whale_2: {char: '🐋', label: 'Whale', plural: 'Whales'},
		},
		birds: {
			// black_bird: {char: '🐦‍⬛', label: 'Black Bird', plural: 'Black Birds', alt_label: 'Crow'}, // not supported on win10
			// dodo: {char: '🦤', label: 'Dodo Bird', plural: 'Dodo Birds'}, // not supported on win10
			duck: {char: '🦆', label: 'Duck', plural: 'Ducks'},
			eagle: {char: '🦅', label: 'Eagle', plural: 'Eagles'},
			flamingo: {char: '🦩', label: 'Flamingo', plural: 'Flamingoes'},
			hatched_chick: {char: '🐥', label: 'Chick', plural: 'Chicks', alt_label: 'Baby Chick'},
			hatching_chick: {char: '🐣', label: 'Baby Chick', plural: 'Baby Chicks', alt_label: 'Chick'},
			// goose: {char: '🪿', label: 'Goose', plural: 'Geese'}, // not supported on win10
			owl: {char: '🦉', label: 'Owl', plural: 'Owls'},
			parrot: {char: '🦜', label: 'Parrot', plural: 'Parrots'},
			// phoenix: {char: '🐦‍🔥', label: 'Phoenix', plural: 'Phoenixes'}, // not supported on win10
			rooster: {char: '🐓', label: 'Rooster', plural: 'Roosters'},
			swan: {char: '🦢', label: 'Swan', plural: 'Swans'},
			turkey: {char: '🦃', label: 'Turkey', plural: 'Turkeys'},
		},
		breakfast: {
			bacon: {char: '🥓', label: 'Bacon', plural: ''},
			bagel: {char: '🥯', label: 'Bagel', plural: 'Bagels'},
			bread: {char: '🍞', label: 'Bread', plural: ''},
			croissant: {char: '🥐', label: 'Croissant', plural: 'Croissant'},
			egg: {char: '🥚', label: 'Egg', plural: 'Eggs'},
			pancakes: {char: '🥞', label: 'Pancakes', plural: ''},
			waffles: {char: '🧇', label: 'Waffles', plural: ''},
		},
		bugs: {
			ant: {char: '🐜', label: 'Ant', plural: 'Ants'},
			bee: {char: '🐝', label: 'Bee', plural: 'Bees', alt_label: 'Honey Bee'},
			bettle: {char: '🪲', label: 'Beetle', plural: 'Beetles'},
			butterfly: {char: '🦋', label: 'Butterfly', plural: 'Butterflies'},
			caterpillar: {char: '🐛', label: 'Caterpillar', plural: 'Caterpillars'},
			cockroach: {char: '🪳', label: 'Cockroach', plural: 'Cockroaches'},
			cricket: {char: '🦗', label: 'Cricket', plural: 'Crickets'},
			// fly: {char: '🪰', label: 'Fly', plural: 'Flies'}, // not supported on win10
			lady_bug: {char: '🐞', label: 'Ladybug', plural: 'Ladybugs'},
			mosquito: {char: '🦟', label: 'Mosquito', plural: 'Mosquitoes'},
			scorpion: {char: '🦂', label: 'Scorpion', plural: 'Scorpions'},
			snail: {char: '🐌', label: 'Snail', plural: 'Snails'},
			spider: {char: '🕷️', label: 'Spider', plural: 'Spiders'},
			// worm: {char: '🪱', label: 'Worm', plural: 'Worms'}, // not supported on win10
		},
		candy: {
			chocolate: {char: '🍫', label: 'Chocolate', plural: 'Chocolate'},
			hard_candy: {char: '🍬', label: 'Candy', plural: 'Candies'},
			lollipop: {char: '🍭', label: 'Lollipop', plural: 'Lollipops'},
		},
		drinks: {
			// bubble_tea : {char: '🧋', label: 'Bubble Tea', plural: ''}, // not supported on win10
			coffee: {char: '☕', label: 'Coffee', plural: ''},
			juice: {char: '🧃', label: 'Juice', plural: ''},
			milk: {char: '🥛', label: 'Milk', plural: ''},
			tea: {char: '🍵', label: 'Tea', plural: ''},
		},
		fruits: {
			"banana": {char: '🍌', label: 'Banana', plural: 'Bananas'},
			// "blueberries": {char: '🫐', label: 'Blueberries', plural: 'Blueberries'}, // not supported on Win10
			"cherries": {char: '🍒', label: 'Cherry', plural: 'Cherries'},
			"coconut": {char: '🥥', label: 'Coconut', plural: 'Coconuts'},
			"grapes": {char: '🍇', label: 'Grapes', plural: 'Grapes'},
			"green_apple": {char: '🍏', label: 'Apple', label_alt: 'Green Apple', plural: 'Apples', plural_alt: 'Green Apples'},
			"kiwi_fruit": {char: '🥝', label: 'Kiwi', plural: 'Kiwis'},
			"lemon": {char: '🍋', label: 'Lemon', plural: 'Lemons'},
			// "lime": {char: '🍋‍🟩', label: 'Lime', plural: 'Limes'}, // not supported on Win10
			"mango": {char: '🥭', label: 'Mango', plural: 'Mangoes'},
			"melon": {char: '🍈', label: 'Melon', plural: 'Melons'},
			// "olive": {char: '🫒', label: 'Olive', plural: 'Olives'}, // not supported on Win10
			"peach": {char: '🍑', label: 'Peach', plural: 'Peaches'},
			"pear": {char: '🍐', label: 'Pear', plural: 'Pears'},
			"pineapple": {char: '🍍', label: 'Pineapple', plural: 'Pineapples'},
			"red_apple": {char: '🍎', label: 'Apple', label_alt: 'Red Apple', plural: 'Apples', plural_alt: 'Red Apples'},
			"strawberry": {char: '🍓', label: 'Strawberry', plural: 'Strawberries'},
			"tangerine": {char: '🍊', label: 'Orange', plural: 'Oranges'}, // aka mandarin, tangerine
			"tomato": {char: '🍅', label: 'Tomato', plural: 'Tomatoes'},
			"watermelon": {char: '🍉', label: 'Watermelon', plural: 'Watermelons'},
		},
		lunch: {
			bento: {char: '🍱', label: 'Bento Box', plural: ''},
			burger: {char: '🍔', label: 'Burger', plural: 'Burgers'},
			burrito: {char: '🌯', label: 'Burrito', plural: 'Burritos'},
			fries: {char: '🍟', label: 'Fries', plural: ''},
			hot_dog: {char: '🌭', label: 'Hot Dog', plural: 'Hot Dogs'},
			pita: {char: '🥙', label: '', plural: ''},
			pizza: {char: '🍕', label: 'Pizza', plural: ''},
			ramen: {char: '🍜', label: 'Ramen', plural: '', alt_label: 'Bowl of Noodles'},
			salad: {char: '🥗', label: 'Salad', plural: ''},
			sandwich: {char: '🥪', label: 'Sandwich', plural: 'Sandwiches'},
			spaghetti: {char: '🍝', label: 'Spaghetti', plural: ''},
			sushi: {char: '🍣', label: 'Sushi', plural: ''},
			taco: {char: '🌮', label: 'Taco', plural: 'Tacos'},
		},
		sweets: {
			cake: {char: '🍰', label: 'Cake', plural: '', alt_label: 'Slice of Cake'},
			cookie: {char: '🍪', label: 'Cookie', plural: 'Cookies'},
			cupcake: {char: '🧁', label: 'Cupcake', plural: 'Cupcakes'},
			custard: {char: '🍮', label: 'Custard', plural: '', alt_label: 'Flan'},
			doughnut: {char: '🍩', label: 'Doughnut', plural: 'Doughnuts'},
			ice_cream: {char: '🍨', label: 'Ice Cream', plural: ''},
			pie: {char: '🥧', label: 'Pie', plural: 'Pies'},
			soft_serve: {char: '🍦', label: 'Soft Server', plural: '', alt_label: 'Ice Cream'},
		},
		symbols: {
			circle: {
				char: '⚫', 
				colours: ['black', 'blue', 'brown', 'green', 'orange', 'purple', 'red', 'white', 'yellow'],
				label: 'Circle', 
				plural: 'Circles', 
				variants: {
					black_circle: '⚫',
					blue_circle: '🔵',
					brown_circle: '🟤',
					green_circle: '🟢',
					orange_circle: '🟠',
					purple_circle: '🟣',
					red_circle: '🔴',
					white_circle: '⚪',
					yellow_circle: '🟡',
				}
			},
			heart: {
				char: '🖤', 
				colours: ['black', 'blue', 'brown', 'green', 'orange', 'purple', 'red', 'white', 'yellow'],
				label: 'Heart', 
				plural: 'Hearts', 
				variants: {
					black_heart: '🖤',
					blue_heart: '💙',
					brown_heart: '🤎',
					green_heart: '💚',
					orange_heart: '🧡',
					purple_heart: '💜',
					red_heart: '❤️',
					white_heart: '🤍',
					yellow_heart: '💛',
				}
			},
			square: {
				char: '⬛', 
				colours: ['black', 'blue', 'brown', 'green', 'orange', 'purple', 'red', 'white', 'yellow'],
				label: 'Square', 
				plural: 'Squares', 
				variants: {
					black_square: '⬛',
					blue_square: '🟦',
					brown_square: '🟫',
					green_square: '🟩',
					orange_square: '🟧',
					purple_square: '🟪',
					red_square: '🟥',
					white_square: '⬜',
					yellow_square: '🟨',
				}
			},
		},
		vegetables: {
			"avocado": {char: '🥑', label: 'Avocado', plural: 'Avocados'},
			// "beans": {char: '🫘', label: 'Beans', plural: 'Beans'}, // not supported on Win10
			// "bell_pepper": {char: '🫑', label: 'Bell Pepper', plural: 'Bell Peppers'}, // not supported on Win10
			"broccoli": {char: '🥦', label: 'Broccoli', plural: 'Broccoli'},
			// "brown_mushroom": {char: '🍄‍🟫', label: 'Mushroom', plural: 'Mushrooms'}, // not supported on Win10, brown mushroom
			"carrot": {char: '🥕', label: 'Carrot', plural: 'Carrots'},
			"chestnut": {char: '🌰', label: 'Chestnut', plural: 'Chestnuts'},
			"cucumber": {char: '🥒', label: 'Cucumber', plural: 'Cucumbers'},
			"ear_of_corn": {char: '🌽', label: 'Corn', plural: 'Corn'}, // ear of corn, corn on the cob
			"eggplant": {char: '🍆', label: 'Eggplant', plural: 'Eggplants'},
			"garlic": {char: '🧄', label: 'Garlic', plural: 'Garlic'},
			// "ginger": {char: '🫚', label: 'Ginger', plural: 'Ginger'}, // not supported on Win10
			"hot_pepper": {char: '🌶️', label: 'Hot Pepper', plural: 'Hot Peppers'}, // chili pepper
			"leafy_green": {char: '🥬', label: 'Leafy Green', plural: 'Leafy Greens'}, // bok choy, chinese cabbage, romaine lettuce
			"onion": {char: '🧅', label: 'Onion', plural: 'Onions'},
			// "pea_pod": {char: '🫛', label: 'Peas', plural: 'Peas'}, // not supported on Win10, pea pod
			"peanuts": {char: '🥜', label: 'Peanuts', plural: 'Peanuts'},
			"potato": {char: '🥔', label: 'Potato', plural: 'Potatoes'},
		},
		weather: {
			cloudy: {char: '☁️', label: 'Cloudy',},
			mostly_sunny: {char: '🌤️', label: 'Mostly Sunny'},
			partial_clouds: {char: '⛅', label: 'Mostly Cloudy',},
			rain: {char: '🌧️', label: 'Rain',},
			snow: {char: '🌨️', label: 'Snow',},
			sunny: {char: '☀️', label: 'Sunny',},
			sun_and_rain: {char: '🌦️', label: 'Mix of Sun and Rain',},
			// sun_big_clouds: {char: '', label: 'Mostly Sunny',}, // duplicate
			// sun_small_clouds: {char: '', label: 'Mostly Cloudy',}, // duplicate
			thunder: {char: '🌩️', label: 'Thunder Storm',},
			thunder_shower: {char: '⛈️', label: 'Thunder Showers',}
		},
		/* TODO: IMPLEMENT THESE MISSING SETS
			people: { // faces
				// need to define the keys from our emoji display test 
			}, 
			professions: {
				artist: {},
				astronaut, {},
				chef: {},
				construction: {},
				detective: {},
				doctor: {},
				engineer: {}, // technologist software engineer
				farmer: {},
				fireman: {},
				guard: {},
				judge: {},
				mechanic: {},
				metalworker: {}, // blowtorch?
				musician: {},
				ninja: {}, 
				pilot: {},
				policeman: {},
				professor?: {},
				royalty: {}, // king, queen, princess?
				scientist: {},
				superhero: {},
				supervillian: {},
				teacher: {},
				// bride, groomsmen, santa, fantasy hero, fairy, vampire, mermaid, elf, genie, zombie, troll
			},
		*/
	};

	static categories = Object.keys(QEWidgetEmoji.emoji_maps);

	static getCategoryItems(categoryId: string, addRandom: boolean) {
		
		let arr = [];
		let item_map = QEWidgetEmoji.emoji_maps[categoryId];

		if (addRandom) arr.push('random');

		Object.keys(item_map).forEach(function(key){
			arr.push(key);
		});

		return arr;
	}

	static getEmojiDataset() {
		return 
	}

	static getEmojiOptions(category_id: string, emoji_id: string, option_id: string, addRandom: boolean) {
		
		const emoji_set = QEWidgetEmoji.emoji_maps[category_id];
		
		if (!emoji_set) return false; // invalid category
		
		if (emoji_id === 'random') { // just set it first map entry
			if (Object.keys(emoji_set).length) {
				emoji_id = Object.keys(emoji_set)[0];	
			}	
		}
		
		const arr = [];
		const emoji = emoji_set[emoji_id];

		// console.log(emoji, QEWidgetEmoji.hasEmojiProperty(category_id, emoji_id, option_id));
		if (emoji && QEWidgetEmoji.hasEmojiProperty(category_id, emoji_id, option_id)) {
			// Need to also ensure property is an options array
			// console.log(emoji[option_id], Array.isArray(emoji[option_id]));
			if (Array.isArray(emoji[option_id])) {
				if (addRandom) arr.push('random');
				emoji[option_id].forEach(function(key){
					arr.push(key);
				});
			}
		}
		return arr;
	}

	static hasEmojiProperty(category_id: string, emoji_id: string, option_id: string) {
		
		const emoji_set = QEWidgetEmoji.emoji_maps[category_id];
		if (!emoji_set) return false; // not a valid 
		if (emoji_id === 'random') { // just set it first map entry
			if (Object.keys(emoji_set).length) {
				emoji_id = Object.keys(emoji_set)[0];	
			}	
		}
		const emoji = emoji_set[emoji_id];
		if (emoji) {
			return emoji.hasOwnProperty(option_id);
		}
		return false;
	}

	constructor(category_id: string, emoji_id: string, display_options: DisplayOptions = {}) {
		super();
		this.category_id = category_id;
		this.emoji_id = emoji_id;
		this.display_options = display_options;
	}

	/**
	* Instantiates and returns widget from serialized data
	* @param {string} serialized - serialized string containing value and display config
	* @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	* @param {Object} [options]
	*/
	
	static instantiate(serialized, resolved_data, options?) {
		
		const deserialized = JSON.parse(serialized);
		// console.log('deserialized:', deserialized);
		
		const set_id = deserialized.set_id;
		const emoji_id1 = deserialized.emoji_id1;
		const emoji_id2 = QEHelper.resolvePlaceholderToString(deserialized.emoji_id2, resolved_data, {});
		const emoji_set = QEWidgetEmoji.emoji_maps[set_id];
		
		// Build map and resolve any [$name] placeholders in display_options
		const display_options = QEHelper.resolveOptionsString(deserialized.display_options, resolved_data);

		let rand_index; 

		// Check if there was an unresolved dependencies
		if (!display_options) return null; 
		if (emoji_id2 === null) return null;

		// Set the actual emoji id (ie. override and then display)
		let emoji_id = emoji_id2.serialize_to_text() || emoji_id1;
		
		// When set to random, pick one from the current set
		if (emoji_id === 'random') {
			const set_items = QEWidgetEmoji.getCategoryItems(set_id);
			rand_index = Math.floor(Math.random()*set_items.length);
			emoji_id = set_items[rand_index];
		}
		// Check to ensure the emoji id is valid for the set
		if (!emoji_set[emoji_id]) {
			console.warn("Doesn't exist:", emoji_id, 'not in set', set_id, emoji_set);
			return null;
		}

		const emoji = emoji_set[emoji_id];
		// Check for emoji variant options
		if (display_options.colour && display_options.colour === 'random') {
			rand_index = Math.floor(Math.random()*emoji.colours.length);
			display_options.colour = emoji.colours[rand_index];
		}
		if (display_options.gender && display_options.gender === 'random') {
			rand_index = Math.floor(Math.random()*emoji.genders.length);
			display_options.gender = emoji.genders[rand_index];
		}
		if (display_options.skin_tone && display_options.skin_tone === 'random') {
			rand_index = Math.floor(Math.random()*emoji.skin_tones.length);
			display_options.skin_tone = emoji.skin_tones[rand_index];
		}
		
		// console.log('instantiate with:', set_id, emoji_id);
		let widget = new QEWidgetEmoji(set_id, emoji_id, display_options);
		return widget;
	}

	/**
	* Returns widget markup for inclusion in question output
	* @param {Object} options
	* @returns {string} Generated display markup
	*/
	
	display(options) {
		
		let display_options = Object.assign({}, this.display_options, options);
		let emoji_set = QEWidgetEmoji.emoji_maps[this.category_id];
		let emoji_char = emoji_set[this.emoji_id].char;
		let variant_id = '';

		// Now check for emoji options to display a variant
		if (display_options.colour) {
			variant_id += display_options.colour + '_' + this.emoji_id;
		}

		if (display_options.gender) {
			variant_id += display_options.gender;
		}
		
		if (display_options.skin_tone) {
			// Ensure a gender is added before appending the skin tone
			if (!variant_id) {
				variant_id += emoji_set[this.emoji_id].genders[0] + '_'; // set the gender to the first
			} else variant_id += '_';
			variant_id += display_options.skin_tone;
		}
		if (variant_id) {
			emoji_char = emoji_set[this.emoji_id]['variants'][variant_id];	
			if (!emoji_char) {
				console.warn("Emoji variant doesn't exist.", variant_id);
			}
		}

		// Check if just user wants to just ouput the emoji char
		if (this.display_options.no_wrapper) {
			return emoji_char;
		} 

		// Otherwise generate the wrapping container for the emoji
		let ml = ''; 
		ml += '<span';
		ml += 	' class="emoji ' + (display_options.container_classes ? display_options.container_classes : 'emoji--md') + '"';
		ml += 	' style="' + (display_options.container_styles ? display_options.container_styles : '') + '">';
		ml += emoji_char;
		ml += '</span>';
	
		return ml;
	}
	
	/*
	** Returns a given attribute for the emoji char
	** For things like its label, plural, etc...
	** TODO: need to hookup warning calls into the practice editor
	*/
	getAttribute(attr_name) {
		const emoji = QEWidgetEmoji.emoji_maps[this.category_id][this.emoji_id];
		// TODO: Ensure fallbacks are set and correct
		if (emoji && emoji.hasOwnProperty(attr_name)) {
			switch (attr_name) {
				case 'plural': // if the key is defined fallback to label (ie. noun is uncountable)
					// console.log(emoji, emoji['label'], emoji['plural']);
					if (emoji[attr_name]) {
						return emoji[attr_name];
					} else return emoji['label'];
					break;
				default: 
					return emoji[attr_name];
			} 
		} else {
			console.warn("Emoji doesn't have the specified attribute.", attr_name);
			return '';
		}
		return null;
	}
	getAttr(attr_name) {
		return this.getAttribute(attr_name);
	}

	/*
	** Returns a textual value instead of the emoji char
	** Needed when emoji are displayed in input widgets and variants are used
	** Cannot rely on just emoji_id, as different variants would appear as 'dupes' and get filtered out.
	*/
	getValue() {
		const emoji_set = QEWidgetEmoji.emoji_maps[this.category_id];
		let id = this.emoji_id; 
		if (emoji_set) { // ensure we have a valid set
			const emoji = emoji_set[this.emoji_id];
			const option_keys = Object.keys(this.display_options);
			if (emoji && option_keys.length) { // ensure we have a valid set item and display options to check
				// NOTE: This technique doesn't ensure consistent returns, could sort first.
				// for (let i = 0; i < option_keys.length; i++) {
				// 	let key = option_keys[i];
				// 	if (['colour', 'gender', 'skin_tone'].includes(key)) {
				// 		id += '_' + this.display_options[key];
				// 	}
				// }
				if (option_keys.includes('colour')) {
					id += '_' + this.display_options['colour'];
				}
				if (option_keys.includes('gender')) {
					id += '_' + this.display_options['gender'];
				}
				if (option_keys.includes('skin_tone')) {
					id += '_' + this.display_options['skin_tone'];
				}
			}
		}
		return id;
	}

	serialize_to_text() {
		return this.getValue();
	}

	// No need to export anything, is just a display widget.
	// exportValue(options?){
	// 	return {};
	// }
}

