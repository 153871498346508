import * as katex from 'katex';
import * as doT from 'dot';
import { log } from './QE';
import { tokenize_and_parse } from './QEGrammar';
import { QETerm } from './QETerm';
import { QEWidget, Eq, ImgSet, Tally, StringLookup, FormatSelector, WidgetList, DecimalGrid, PlaceBlocks } from './QEWidget';
import { QEWidgetTable as Table } from './Widget/Table';
import { QEWidgetFractionSet as FractionSet } from './Widget/FractionSet';
import { QEWidgetFractionShape as FractionShape } from './Widget/FractionShape';
import { QEWidgetMC as MC } from './Widget/QEWidgetMC';
import { QEWidgetMultiSelect as MultiSelect } from './Widget/MultiSelect';
import { QEWidgetMultiInput as MultiInput } from './Widget/MultiInput';
import { QEWidgetDropDown as DropDown } from './Widget/DropDown';
import { QEWidgetDragSource as DragSource } from './Widget/DragSource';
import { QEWidgetDragSort as DragSort } from './Widget/DragSort';
import { QEWidgetDropZone as DropZone } from './Widget/DropZone';
import { QEWidgetDropBucket as DropBucket } from './Widget/DropBucket';
import { QEWidgetDropList as DropList } from './Widget/DropList';
import { QEWidgetEmoji as Emoji } from './Widget/Emoji';
import { QEWidgetVerticalMath as VerticalMath } from './Widget/VerticalMath';
import { QEEqKeyboard as EqKeyboard } from 'QEEqKeyboard'; // aliased in webpack.config.js
import { QEWidgetGraph as Graph } from 'QEWidgetGraph'; // aliased in webpack.config.js
import { QESolver } from 'QESolver';

interface DisplayOptions {
	[key: string]: any;
}

export interface ExplanationStep {
	desc: string;
	widget_data?: unknown;
	substeps?: ExplanationStep[];
	value: QEValue;
}

export interface Solution {
	value: QEValue;
	steps: ExplanationStep[];
}

// NOTE: placeholder resolution "specifiers" (e.g. [$p1.display()]") end up calling underlying QEValue object functions (e.g. "display()).
// - The underlying functions may be called directly by javascript on a resolved QEValue object (i.e. from dotjs).
// - The underlying functions return straight value data, usually strings, numbers, or trees
// - When called by the placeholder resolution code, applyResolutionSpecifier will wrap the result in a QEValue object

export class QEValue {
	value: any;
	type?: string; // always
	subtype?: string; // only for Widget
	key_name?: string;

	constructor(data: { [key: string]: any }) {
		Object.assign(this, data);
	}
	static create(data: { [key: string]: any }): QEValue {
		// the static factory method is defined outside of QEValue,
		//   since it needs to reference subclasses that extend QEValue
		return createQEValue(data);
	}

	display_as(display_widget: QEValueWidget, options?: {}){
		options = options || {};

		if (!(display_widget instanceof QEValueWidget)) {
			log.warn('Error: display_as() called with non-widget');
			return null;
		}

		// special handling for MC: display_widget must be passed to MC so it can be used for displaying each choice
		if (this.subtype == "multi-choice") {
			options.display_as = display_widget;
			return this.display(options);
		} else {
			options.value = this;
			return display_widget.display(options);
		}
	}
	to_text(options: { [key: string]: string | boolean } = {}){
		return this.serialize_to_text(options);
	}
	apply_solver_step(step_key: string){
		// TODO: resolve step_key to string

		// call the specified solver step
		const solved = QESolver.applySolverStep(step_key, this);
		if (!solved) {
			log.warn("Error: applySolverStep returned undef for step_key: ", step_key);
			return null;
		}
		// the output value of a solver step should always be a QEValue object
		return solved.value;
	}
	solve_using(solver_key: string){
		// TODO: resolve solver_key to string

		// call the specified solver
		const solved = QESolver.solveUsing(solver_key, this);
		if (!solved) {
			log.warn("Error: solveUsing returned undef for solver_key: ", solver_key);
			return null;
		}
		// the output value of a solver should always be a QEValue object
		return solved.value;
	}

	applyResolutionSpecifier(specifier: string, resolved_data) {
		if (specifier.match(/^display_as\([^\)]*\)/)) {
			// [$value.display_as(widget_key)] or [$value.display_as(widget_key, key1:val1, ...)]
			// calls referenced widget with .display({ value: this, key1: val1, ... })

			const display_options_str = specifier.match(/^display_as\(([^\)]*)\)/)[1];
			const display_widget_key = display_options_str.split(/ *, */)[0];

			// get referenced display object for subsequent rendering
			const display_widget = resolved_data.resolved[display_widget_key];
			if (!display_widget) return null; // unresolved dependency

			// build map of any display options - strip display_widget_key from start of display option string
			const display_options: DisplayOptions = QEHelper.resolveOptionsString(display_options_str.replace(/^[^,]*,? */, ""), resolved_data);
			if (!display_options) return null; // unresolved dependency

			const markup = this.display_as(display_widget, display_options);
			if (markup === null) return null;
			return new QEValueString({ value: markup });
		} else if (specifier.match(/^display\([^\)]*\)/)) {
			const display_options_str = specifier.match(/^display\(([^\)]*)\)/)[1];

			// build map of any display options
			const display_options: DisplayOptions = QEHelper.resolveOptionsString(display_options_str, resolved_data);
			if (!display_options) return null; // unresolved dependency

			// pass display_options to current widget
			const markup = this.display(display_options);
			if (markup === null) return null;
			return new QEValueString({ value: markup });
		} else if (specifier.match(/^to_text\([^\)]*\)/)) {
			const options_str = specifier.match(/^to_text\(([^\)]*)\)/)[1];

			// build map of any display options
			const options: DisplayOptions = QEHelper.resolveOptionsString(options_str, resolved_data);
			if (!options) return null; // unresolved dependency

			return new QEValueString({ value: this.serialize_to_text(options) });
		} else if (specifier.match(/^apply_solver_step\([^)]*\)/)) {
			const step_key = specifier.match(/apply_solver_step\(([^)]*)\)/)[1];

			// no need to wrap the output in a QEValue since the output value  of a solver step should already be a QEValue
			return this.apply_solver_step(step_key);
		} else if (specifier.match(/^solve_using\([^)]*\)/)) {
			const solver_key = specifier.match(/solve_using\(([^)]*)\)/)[1];

			// no need to wrap the output in a QEValue since the output value  of a solver  should already be a QEValue
			return this.solve_using(solver_key);
		}

		log.warn("applyResolutionSpecifier: '" + specifier + "' not handled for QEValue: ");
		return null;
	}
	serialize_to_text(options: { [key: string]: string | boolean } = {}): string {
		log.warn("Error: calling serialize_to_text on base QEValue object");
		return null;
	}
	display(options: { [key: string]: string | boolean } = {}): string {
		log.warn("Error: calling display on base QEValue object");
		return null;
	}
	exportValue(options: { allow_private?: boolean } = {}) {
		log.warn("Error: calling exportValue on base QEValue object");
		return null;
	}
}

export class QEValueTree extends QEValue {
	value: QETerm;

	constructor(data: { value: QETerm }) {
		super(Object.assign({ type: "tree" }, data));
		this.value = data.value;

		// check if tree begins with a ROOT. If not, wrap it
		if (this.value.type != "ROOT") {
			const new_root = QETerm.create({ type: "ROOT" });
			new_root.pushChild(this.value);
			this.value = new_root;
		}
	}
	serialize_to_text(options: { [key: string]: string | boolean } = {}): string {
		return this.value.serialize_to_text(options);
	}
	display(options: { [key: string]: string | boolean } = {}): string {
		return this.value.display(options);
	}
	exportValue(options: { allow_private?: boolean } = {}) {
		return this.value.serialize_to_text(options);
	}

	// helper methods for dotjs output
	to_text(options: { [key: string]: string | boolean } = {}): string {
		return this.serialize_to_text(options);
	}
	to_num(): number {
		return Number(this.value.evaluate_to_float());
	}
	to_fixed(places: number): string {
		let num = this.to_num();
		if (Number.isNaN(num)) return num;
		return num.toFixed(places);
	}
	to_num_list(): number[] { // converts a QEValueTree list{} to an array of Number (or NaN for any non-numeric value)
		// verify tree.children[0] is a list{}
		const tree = this.value;
		if (tree.children[0].value !== "list" || !tree.children[0].children.length) {
			log.warn('Error: .list() specifier called on non-list tree: ', tree.serialize_to_text());
			return null;
		}
		return tree.children[0].children.map((x)=>{ return Number(x.serialize_to_text()); });
	}
	to_list(): string[] { // converts a QEValueTree list{} to an array of serialized values
		// verify tree.children[0] is a list{}
		const tree = this.value;
		if (tree.children[0].value !== "list" || !tree.children[0].children.length) {
			log.warn('Error: .list() specifier called on non-list tree: ', tree.serialize_to_text());
			return null;
		}
		return tree.children[0].children.map((x)=>{ return x.serialize_to_text(); });
	}
	// TODO: naming should be consistent with the resolution specifiers (i.e. list_to_array)

	list(): QETerm[] { // converts a QEValueTree list{} to an array of QETerm nodes
		// verify tree.children[0] is a list{}
		const tree = this.value;
		if (tree.children[0].value !== "list" || !tree.children[0].children.length) {
			log.warn('Error: .list() specifier called on non-list tree: ', tree.serialize_to_text());
			return null;
		}
		return tree.children[0].children;
	}
	list_get(index: number): QETerm { // returns the QETerm node at the specified list index
		index = QEHelper.resolveToNumber(index);
		if (index === null) return null;

		// verify tree.children[0] is a list{}
		const tree = this.value;
		if (tree.children[0].value !== "list" || !tree.children[0].children.length) {
			log.warn('Error: .list() specifier called on non-list tree: ', tree.serialize_to_text());
			return null;
		}
		return tree.children[0].children[index];
	}

	static applySpecifierEvaluateToFloat(tree) {
		// check that first child of ROOT is not a comparator CHAIN
		if (tree.children[0].isComparatorChain()) {
			log.warn("Error: evaluate_to_float() or to_num() specifier called on comparator tree: ", tree.serialize_to_text());
			return new QEValueTree({ value: tree });
		}

		const val = tree.evaluate_to_float();
		if (Number.isNaN(val)) {
			log.warn("Error: evaluate_to_float() or to_num() resulted in NaN");
			return new QEValueTree({ value: tree });
		}
		const val_term = QETerm.create({ type: "RATIONAL", value: val.toString() });
		return new QEValueTree({ value: val_term });
	}
	static applySpecifierEvaluateToFixed(specifier, tree, resolved_data): QEValueString {
		// get fixed places
		let places = specifier.match(/to_fixed\(([^)]*)\)/)[1];
		if (!Number.isInteger(Number(places))) {
			log.warn("Error: to_fixed() specifier called with non-integer places value");
			return new QEValueTree({ value: tree });
		}
		places = Number(places);

		// evalute to num and return num.toFixed(places)
		const val = tree.evaluate_to_float();
		if (Number.isNaN(val)) {
			log.warn("Error: to_fixed() resulted in NaN");
			return new QEValueString({ value: Number.NaN });
		}
		return new QEValueString({ value: val.toFixed(places) });
	}
	static applySpecifierLHS(tree, resolved_data) {
		// check that first child of ROOT is a comparator CHAIN, or a binary mode comparator: EQUAL, LESS, etc.
		if (tree.children[0].isComparatorChain())
			return new QEValueTree({ value: tree.children[0].children[0]});
		else
			log.warn("Error: lhs specifier called on non-comparator tree: ", tree.serialize_to_text());
		return new QEValueTree({ value: tree });
	}
	static applySpecifierRHS(tree, resolved_data) {
		// check that first child of ROOT is a comparator CHAIN, or a binary mode comparator: EQUAL, LESS, etc.
		if (tree.children[0].isComparatorChain())
			return new QEValueTree({ value: tree.children[0].children[2]});
		else
			log.warn("Error: rhs specifier called on non-comparator tree: ", tree.serialize_to_text());
		return new QEValueTree({ value: tree });
	}
	static applySpecifierRoundTo(specifier, tree, resolved_data) {
		// NOTE: the precision_key is a 10s exponent number like "0.01", rather than a 10s exponent like "-2"

		// get rounding place - default to 1
		let precision_key = specifier.match(/round_to\(([^)]*)\)/)[1];
		if (!precision_key) {
			precision_key = "1";
		}

		// placeholder resolution of precision
		let precision_num;
		if (precision_key.match(/^\$/)) {
			const resolved_precision_key = QEHelper.resolvePlaceholderToString('['+ precision_key +']', resolved_data);
			if (resolved_precision_key === null)
				return null;
			precision_num = Number(resolved_precision_key.serialize_to_text());
		} else {
			precision_num = Number(precision_key);
		}
		if (isNaN(precision_num)) {
			log.warn("Error: round_to() specifier called with NaN precision: ", precision_key);
			return null;
		}

		// confirm tree is a rational number
		if (tree.children[0].type != "RATIONAL") {
			log.warn("Error: round_to() specifier called on non-rational tree: ", tree.serialize_to_text());
			return null;
		}

		const log10_exp = Math.round(Math.log10(precision_num));

		// call round_number solver
		const solved = QESolver.solveUsing('round_number', createQEValue({
			type: "map",
			value: { number: tree, place: QETerm.create({ type: "RATIONAL", value:log10_exp.toString() }) }
		}));
		if (!solved) {
			log.warn("Error: solveUsing returned undef for solver_key: round_number");
			return null;
		}
		// the output value of a solver should always be a QEValue object
		return solved.value;
	}
	static applySpecifierListGet(specifier, tree, resolved_data) {
		const index_key = specifier.match(/list_get\(([^)]*)\)/)[1];

		// verify tree.children[0] is a list{}
		if (tree.children[0].value !== "list") {
			log.warn('Error: .list_get specifier called on non-list tree: ', tree.serialize_to_text());
			return null;
		}

		// placeholder resolution of list index
		let index_num;
		if (index_key.match(/^\$/)) {
			const resolved_index_key = QEHelper.resolvePlaceholderToString('['+ index_key +']', resolved_data);
			if (resolved_index_key === null)
				return null;
			index_num = Number(resolved_index_key.serialize_to_text());
		} else {
			index_num = Number(index_key);
		}

		if (Number.isNaN(index_num) || index_num < 0 || index_num >= tree.children[0].children.length) {
			log.warn('Error: list_get index out of bounds: ', index_num);
			return null;
		}

		return new QEValueTree({ value: tree.children[0].children[index_num]});
	}
	static applySpecifierListLength(tree) {
		// verify tree.children[0] is a list{}
		if (tree.children[0].value !== "list") {
			log.warn('Error: .list_length specifier called on non-list tree: ', tree.serialize_to_text());
			return null;
		}

		const len = tree.children[0].children.length.toString();
		const len_tree = tokenize_and_parse(len, {}).tree;
		return new QEValueTree({ value: len_tree});
	}
	static applySpecifierListMin(tree) {
		// verify tree.children[0] is a list{}
		if (tree.children[0].value !== "list" || !tree.children[0].children.length) {
			log.warn('Error: .list_min() specifier called on non-list tree: ', tree.serialize_to_text());
			return null;
		}

		// note: if the first item is NaN, subsequent items will not be < NaN, so that item will remain the "min"
		const list = tree.children[0];
		let min = list.children[0];
		let min_val = min.evaluate_to_float();
		for (let i = 1; i < list.children.length; i++){
			let val = list.children[i].evaluate_to_float();
			if (val < min_val) {
				min_val = val;
				min = list.children[i];
			}
		}
		return new QEValueTree({ value: min });
	}
	static applySpecifierListMax(tree) {
		// verify tree.children[0] is a list{}
		if (tree.children[0].value !== "list" || !tree.children[0].children.length) {
			log.warn('Error: .list_max() specifier called on non-list tree: ', tree.serialize_to_text());
			return null;
		}

		// note: if the first item is NaN, subsequent items will not be > NaN, so that item will remain the "max"
		const list = tree.children[0];
		let max = list.children[0];
		let max_val = max.evaluate_to_float();
		for (let i = 1; i < list.children.length; i++){
			let val = list.children[i].evaluate_to_float();
			if (val > max_val) {
				max_val = val;
				max = list.children[i];
			}
		}
		return new QEValueTree({ value: max });
	}
	static applySpecifierListSort(tree) {
		// verify tree.children[0] is a list{}
		if (tree.children[0].value !== "list") {
			log.warn('Error: .list_sort() specifier called on non-list tree: ', tree.serialize_to_text());
			return null;
		}

		// sort list children
		const clone = tree.clone();
		const list = clone.children[0];
		list.children.sort(function(a_term, b_term){
			const a = a_term.evaluate_to_float();
			const b = b_term.evaluate_to_float();

			// sort NaN values to the end of the list
			return !Number.isNaN(a) && Number.isNaN(b) ? -1 : Number.isNaN(a) && !Number.isNaN(b) ? 1 : Number.isNaN(a) && Number.isNaN(b) ? 0 : a < b ? -1 : a > b ? 1 : 0;
		});

		return new QEValueTree({ value: clone });
	}
	static applySpecifierListSortDesc(tree) {
		// verify tree.children[0] is a list{}
		if (tree.children[0].value !== "list") {
			log.warn('Error: .list_sortdesc() specifier called on non-list tree: ', tree.serialize_to_text());
			return null;
		}

		// sort list children
		const clone = tree.clone();
		const list = clone.children[0];
		list.children.sort(function(b_term, a_term){ // NOTE: arg order flipped, so sort order is descending
			const a = a_term.evaluate_to_float();
			const b = b_term.evaluate_to_float();

			// sort NaN values to the end of the list
			return !Number.isNaN(a) && Number.isNaN(b) ? -1 : Number.isNaN(a) && !Number.isNaN(b) ? 1 : Number.isNaN(a) && Number.isNaN(b) ? 0 : a < b ? -1 : a > b ? 1 : 0;
		});

		return new QEValueTree({ value: clone });
	}
	static applySpecifierListShuffle(tree) {
		// verify tree.children[0] is a list{}
		if (tree.children[0].value !== "list") {
			log.warn('Error: .list_shuffle() specifier called on non-list tree: ', tree.serialize_to_text());
			return null;
		}

		// shuffle list children
		const clone = tree.clone();
		clone.children[0].children = shuffle(clone.children[0].children);

		return new QEValueTree({ value: clone });
	}
	static applySpecifierListInsertItemAtIndex(specifier, tree, resolved_data) {
		const index_key = specifier.match(/list_insert\(([^,]*),[^)]*\)/)[1];
		const new_item_key = specifier.match(/list_insert\([^,]*,([^)]*)\)/)[1];

		// verify tree.children[0] is a list{}
		if (tree.children[0].value !== "list") {
			log.warn('Error: .list_insert specifier called on non-list tree: ', tree.serialize_to_text());
			return null;
		}

		// placeholder resolution of list index
		let index_num;
		if (index_key.match(/^\$/)) {
			const resolved_index_key = QEHelper.resolvePlaceholderToString('['+ index_key +']', resolved_data);
			if (resolved_index_key === null)
				return null;
			index_num = Number(resolved_index_key.serialize_to_text());
		} else {
			index_num = Number(index_key);
		}

		if (Number.isNaN(index_num) || index_num < 0 || index_num >= tree.children[0].children.length) {
			log.warn('Error: list_insert index out of bounds: ', index_num);
			return null;
		}

		// placeholder resolution of new_item
		let new_item;
		if (new_item_key.match(/^\[\$.*\]$/)) {
			new_item = QEHelper.resolvePlaceholderToTree(new_item_key, resolved_data);
			if (!new_item) return null;
		} else {
			const parsed = tokenize_and_parse(new_item_key, {parameter_map: resolved_data});
			if (parsed.tree == null)
				return null;
			new_item = new QEValueTree({ value: parsed.tree });
		}

		const clone = tree.clone();
		const list = clone.children[0];
		list.insertNthChild(index_num, new_item.value.children[0]);
		return new QEValueTree({ value: clone });
	}
	static applySpecifierListRemoveItemAtIndex(specifier, tree, resolved_data) {
		const index_key = specifier.match(/list_remove\(([^)]*)\)/)[1];

		// verify tree.children[0] is a list{}
		if (tree.children[0].value !== "list") {
			log.warn('Error: .list_remove specifier called on non-list tree: ', tree.serialize_to_text());
			return null;
		}

		// placeholder resolution of list index
		let index_num;
		if (index_key.match(/^\$/)) {
			const resolved_index_key = QEHelper.resolvePlaceholderToString('['+ index_key +']', resolved_data);
			if (resolved_index_key === null)
				return null;
			index_num = Number(resolved_index_key.serialize_to_text());
		} else {
			index_num = Number(index_key);
		}

		if (Number.isNaN(index_num) || index_num < 0 || index_num >= tree.children[0].children.length) {
			log.warn('Error: list_remove index out of bounds: ', index_num);
			return null;
		}

		// verify tree.children[0] is a list{}
		if (tree.children[0].value !== "list") {
			log.warn('Error: .list_sort() specifier called on non-list tree: ', tree.serialize_to_text());
			return null;
		}

		const clone = tree.clone();
		const list = clone.children[0];
		list.removeNthChild(index_num);
		return new QEValueTree({ value: clone });
	}
	static applySpecifierListReplaceItemAtIndex(specifier, tree, resolved_data) {
		const index_key = specifier.match(/list_replace\(([^,]*),[^)]*\)/)[1];
		const new_item_key = specifier.match(/list_replace\([^,]*,([^)]*)\)/)[1];

		// verify tree.children[0] is a list{}
		if (tree.children[0].value !== "list") {
			log.warn('Error: .list_replace specifier called on non-list tree: ', tree.serialize_to_text());
			return null;
		}

		// placeholder resolution of list index
		let index_num;
		if (index_key.match(/^\$/)) {
			const resolved_index_key = QEHelper.resolvePlaceholderToString('['+ index_key +']', resolved_data);
			if (resolved_index_key === null)
				return null;
			index_num = Number(resolved_index_key.serialize_to_text());
		} else {
			index_num = Number(index_key);
		}

		if (Number.isNaN(index_num) || index_num < 0 || index_num >= tree.children[0].children.length) {
			log.warn('Error: list_replace index out of bounds: ', index_num);
			return null;
		}

		// placeholder resolution of new_item
		let new_item;
		if (new_item_key.match(/^\[\$.*\]$/)) {
			new_item = QEHelper.resolvePlaceholderToTree(new_item_key, resolved_data);
			if (!new_item) return null;
		} else {
			const parsed = tokenize_and_parse(new_item_key, {parameter_map: resolved_data});
			if (parsed.tree == null)
				return null;
			new_item = new QEValueTree({ value: parsed.tree });
		}

		const clone = tree.clone();
		const list = clone.children[0];
		list.replaceNthChildWith(index_num, new_item.value.children[0]);
		return new QEValueTree({ value: clone });
	}
	static applySpecifierListToArray(tree) {
		// verify tree.children[0] is a list{}
		if (tree.children[0].value !== "list") {
			log.warn('Error: .list_to_array specifier called on non-list tree: ', tree.serialize_to_text());
			return null;
		}

		const list = tree.children[0];
		const array = list.children.map(function(child){ return child.serialize_to_text(); });

		return new QEValueJSON({ value: array});
	}
	static applySpecifierFracToMixed(tree, resolved_data) {
		// convert any fracs to mfracs
		const clone = tree.clone();
		const terms = clone.findAllChildren("value", "frac");
		for (let i = 0; i < terms.length; i++) {
			const frac = terms[i];
			const num = frac.children[0];
			const den = frac.children[1];
			if (num.type !== "RATIONAL" || !Number.isInteger(Number(num.value)) ||
				den.type !== "RATIONAL" || !Number.isInteger(Number(den.value)) ||
				Number(den.value) == 0) {
				continue; // not a valid frac
			}

			// create a new mfrac
			const new_whole = Math.trunc(Number(num.value) / Number(den.value));
			const new_num = Number(num.value) % Number(den.value);
			const new_den = Number(den.value);

			if (!new_num) {
				// no remaining frac: integer instead of mfrac
				const new_integer = QETerm.create({ type: "RATIONAL", value: new_whole.toString() });

				// replace the frac
				frac.replaceWith(new_integer);
			} else if (new_whole) {
				const new_mfrac = QETerm.create({ type: "FUNCTION", value: "mfrac" });
				new_mfrac.pushChild(QETerm.create({ type: "RATIONAL", value: new_whole.toString() }));
				new_mfrac.pushChild(QETerm.create({ type: "RATIONAL", value: new_num.toString() }));
				new_mfrac.pushChild(QETerm.create({ type: "RATIONAL", value: new_den.toString() }));

				// replace the frac
				frac.replaceWith(new_mfrac);
			}
			// else zero whole part and zero numerator; can skip
		}

		return new QEValueTree({ value: clone });
	}
	static applySpecifierMixedToFrac(tree, resolved_data) {
		// convert any mfracs to fracs
		const clone = tree.clone();
		const terms = clone.findAllChildren("value", "mfrac");
		for (let i = 0; i < terms.length; i++) {
			const mfrac = terms[i];
			const whole = mfrac.children[0];
			const num = mfrac.children[1];
			const den = mfrac.children[2];
			if (whole.type !== "RATIONAL" || !Number.isInteger(Number(whole.value)) ||
				num.type !== "RATIONAL" || !Number.isInteger(Number(num.value)) ||
				den.type !== "RATIONAL" || !Number.isInteger(Number(den.value))) {
				continue; // not a valid mfrac
			}

			// create a new fraction
			const new_num = Number(whole.value) * Number(den.value) + Number(num.value);
			const new_den = Number(den.value);

			const new_frac = QETerm.create({ type: "FUNCTION", value: "frac" });
			new_frac.pushChild(QETerm.create({ type: "RATIONAL", value: new_num.toString() }));
			new_frac.pushChild(QETerm.create({ type: "RATIONAL", value: new_den.toString() }));

			// replace the mfrac
			mfrac.replaceWith(new_frac);
		}

		return new QEValueTree({ value: clone });
	}
	static applySpecifierSortAddTerms(value, resolved_data) {
		// apply CT_sortAdditiveChainTerms solver step
		const solved = QESolver.applySolverStep("CT_sortAdditiveChainTerms", value);
		if (!solved) {
			log.warn("Error: applySolverStep returned undef for step_key: ", "CT_sortAdditiveChainTerms", value);
			return null;
		}
		return solved.value;
	}
	static applySpecifierSortMultiplyTerms(value, resolved_data) {
		// apply CT_sortAdditiveChainTerms solver step
		const solved = QESolver.applySolverStep("CT_sortMultiplicativeChainTerms", value);
		if (!solved) {
			log.warn("Error: applySolverStep returned undef for step_key: ", "CT_sortMultiplicativeChainTerms", value);
			return null;
		}
		return solved.value;
	}
	static applySpecifierTimeHours(tree) {
		// verify tree.children[0] is time_hm{} or time_hms
		if (["time_hm", "time_hms"].indexOf(tree.children[0].value) == -1) {
			log.warn('Error: .hours specifier called on non-time tree: ', tree.serialize_to_text());
			return null;
		}
		return new QEValueTree({ value: tree.children[0].children[0] });
	}
	static applySpecifierTimeMinutes(tree) {
		// verify tree.children[0] is time_hm{} or time_hms
		if (["time_hm", "time_hms"].indexOf(tree.children[0].value) == -1) {
			log.warn('Error: .hours specifier called on non-time tree: ', tree.serialize_to_text());
			return null;
		}
		return new QEValueTree({ value: tree.children[0].children[1] });
	}
	static applySpecifierTimeSeconds(tree) {
		// verify tree.children[0] is time_hm{} or time_hms
		if (["time_hm", "time_hms"].indexOf(tree.children[0].value) == -1) {
			log.warn('Error: .hours specifier called on non-time tree: ', tree.serialize_to_text());
			return null;
		}
		if (tree.children[0].value == "time_hms") {
			return new QEValueTree({ value: tree.children[0].children[2] });
		} else {
			return new QEValueTree({ value: QETerm.create({ type: "RATIONAL", value: "00" }) });
		}
	}
	applyResolutionSpecifier(specifier: string, resolved_data) {
		if (specifier.match(/^evaluate_to_float\(\)/)) {
			return QEValueTree.applySpecifierEvaluateToFloat(this.value);
		} else if (specifier.match(/^to_num\(\)/)) {
			return QEValueTree.applySpecifierEvaluateToFloat(this.value);
		} else if (specifier.match(/^to_fixed\([^)]*\)/)) {
			return QEValueTree.applySpecifierEvaluateToFixed(specifier, this.value, resolved_data);
		} else if (specifier == 'lhs') {
			return QEValueTree.applySpecifierLHS(this.value, resolved_data);
		} else if (specifier == 'rhs') {
			return QEValueTree.applySpecifierRHS(this.value, resolved_data);
		} else if (specifier.match(/^round_to\([^)]*\)/)) {
			return QEValueTree.applySpecifierRoundTo(specifier, this.value, resolved_data);
		} else if (specifier.match(/^list_get\([^)]*\)/)) {
			return QEValueTree.applySpecifierListGet(specifier, this.value, resolved_data);
		} else if (specifier == "list_len") {
			return QEValueTree.applySpecifierListLength(this.value);
		} else if (specifier == "list_length") {
			return QEValueTree.applySpecifierListLength(this.value);
		} else if (specifier.match(/^list_min\(\)/)) {
			return QEValueTree.applySpecifierListMin(this.value);
		} else if (specifier.match(/^list_max\(\)/)) {
			return QEValueTree.applySpecifierListMax(this.value);
		} else if (specifier.match(/^list_sort\(\)/)) {
			return QEValueTree.applySpecifierListSort(this.value);
		} else if (specifier.match(/^list_sortdesc\(\)/)) {
			return QEValueTree.applySpecifierListSortDesc(this.value);
		} else if (specifier.match(/^list_shuffle\(\)/)) {
			return QEValueTree.applySpecifierListShuffle(this.value);
		} else if (specifier.match(/^list_insert\([^,]*,[^)]*\)/)) {
			return QEValueTree.applySpecifierListInsertItemAtIndex(specifier, this.value, resolved_data);
		} else if (specifier.match(/^list_remove\([^)*]\)/)) {
			return QEValueTree.applySpecifierListRemoveItemAtIndex(specifier, this.value, resolved_data);
		} else if (specifier.match(/^list_replace\([^,]*,[^)]*\)/)) {
			return QEValueTree.applySpecifierListReplaceItemAtIndex(specifier, this.value, resolved_data);
		} else if (specifier.match(/^list_to_array\(\)/)) {
			return QEValueTree.applySpecifierListToArray(this.value);
		} else if (specifier.match(/^frac_to_mixed\(\)/)) {
			return QEValueTree.applySpecifierFracToMixed(this.value, resolved_data);
		} else if (specifier.match(/^mixed_to_frac\(\)/)) {
			return QEValueTree.applySpecifierMixedToFrac(this.value, resolved_data);
		} else if (specifier.match(/^sort_add_terms\(\)/)) {
			return QEValueTree.applySpecifierSortAddTerms(this, resolved_data); // passes full QEValue to solver
		} else if (specifier.match(/^sort_multiply_terms\(\)/)) {
			return QEValueTree.applySpecifierSortMultiplyTerms(this, resolved_data); // passes full QEValue to solver
		} else if (specifier == "hours") {
			return QEValueTree.applySpecifierTimeHours(this.value);
		} else if (specifier == "minutes") {
			return QEValueTree.applySpecifierTimeMinutes(this.value);
		} else if (specifier == "seconds") {
			return QEValueTree.applySpecifierTimeSeconds(this.value);
		}

		// fallback
		return super.applyResolutionSpecifier(specifier, resolved_data);
	}
}

export class QEValueString extends QEValue {
	constructor(data: { value: string | number | boolean }) {
		// cast boolean and number values to string
		if (typeof data.value == "boolean" || typeof data.value == "number") {
			data.value = data.value.toString();
		}

		super(Object.assign({type: "string"}, data));
	}
	serialize_to_text(options: { [key: string]: string | boolean } = {}): string {
		return this.value;
	}
	display(options: { [key: string]: string | boolean } = {}): string {
		return this.value;
	}
	exportValue(options: { allow_private?: boolean } = {}) {
		return this.value;
	}

	// helper methods for dotjs output
	to_text(): string {
		return this.value;
	}
	to_num(): number {
		return Number(this.value);
	}
	to_fixed(places: number): string {
		let num = this.to_num();
		if (Number.isNaN(num)) return num;
		return num.toFixed(places);
	}

	applyResolutionSpecifier(specifier: string, resolved_data) {
		if (specifier.match(/^to_tree\(\)/)) {
			// parse and check output for error
			const parsed = tokenize_and_parse(this.value, {parameter_map: resolved_data});
			if (parsed.tree == null)
				return null;

			return new QEValueTree({ value: parsed.tree });
		} else if (specifier.match(/^pad_left\([^,)]+,[^,)]+\)/)) {
			const pad_opts_str = specifier.match(/^pad_left\(([^,)]+,[^,)]+)\)/)[1];
			const pad_opts = pad_opts_str.split(/,/);
			const len = parseInt(pad_opts[0]) || 0;
			const pad_char = pad_opts[1];

			let str = this.value;
			while (str.length < len) {
				str = pad_char + str;
			}
			return new QEValueString({ value: str });
		} else if (specifier.match(/^pad_right\([^,)]+,[^,)]+\)/)) {
			const pad_opts_str = specifier.match(/^pad_right\(([^,)]+,[^,)]+)\)/)[1];
			const pad_opts = pad_opts_str.split(/,/);
			const len = parseInt(pad_opts[0]) || 0;
			const pad_char = pad_opts[1];

			let str = this.value;
			while (str.length < len) {
				str += pad_char;
			}
			return new QEValueString({ value: str });
		} else if (specifier.match(/^to_fixed\([^)]*\)/)) {
			// get fixed places
			let places = specifier.match(/to_fixed\(([^)]*)\)/)[1];
			if (!Number.isInteger(Number(places))) {
				log.warn("Error: to_fixed() specifier called with non-integer places value");
				return new QEValueString({ value: this.value });
			}
			places = Number(places);

			// evalute to num and return num.toFixed(places)
			const val = Number(this.value);
			if (Number.isNaN(val)) {
				log.warn("Error: to_fixed() resulted in NaN");
				return new QEValueString({ value: this.value });
			}
			return new QEValueString({ value: val.toFixed(places) });
		} else if (specifier.match(/^length/)) {
			return new QEValueString({ value: this.value.length });
		}

		// fallback
		return super.applyResolutionSpecifier(specifier, resolved_data);
	}
}

export class QEValueJSON extends QEValue {
	value: object;

	constructor(data: { value: object }) {

		// check that data contains only arrays, object maps, and primitives - reject complex objects like QEValues or QE.Terms.
		function containsComplexObjects(val){
			if (val instanceof Array) {
				for (let i = 0; i < val.length; i++) {
					if (containsComplexObjects(val[i])) {
						return true;
					}
				}
			} else if (val instanceof Object) {
				if (val.constructor !== Object) {
					log.log('Complex object found in JSON data: ', val);
					return true;
				}
				const keys = Object.keys(val);
				for (let i = 0; i < keys.length; i++) {
					if (containsComplexObjects(val[keys[i]])) {
						return true;
					}
				}
			}
			return false;
		}

		if (containsComplexObjects(data)) {
			log.log('ERROR. QEValueJSON instantiated with non-primitive key value.', data);
			debugger;
		}

		super(Object.assign({type: "json"}, data));
		this.value = data.value;
	}
	serialize_to_text(options: { [key: string]: string | boolean } = {}): string {
		return JSON.stringify(this.value);
	}
	display(options: { [key: string]: string | boolean } = {}): string {
		return JSON.stringify(this.value);
	}
	exportValue(options: { allow_private?: boolean } = {}) {
		return JSON.stringify(this.value);
	}
	applyResolutionSpecifier(specifier: string, resolved_data) {
		if (specifier.match(/^list_get\([^)]*\)/)) {
			if (this.value instanceof Array) {
				const index_key = specifier.match(/list_get\(([^)]*)\)/)[1];

				// placeholder resolution of list index
				let index_num;
				if (index_key.match(/^\$/)) {
					const resolved_index_key = QEHelper.resolvePlaceholderToString('['+ index_key +']', resolved_data);
					if (resolved_index_key === null)
						return null;
					index_num = Number(resolved_index_key.serialize_to_text());
				} else {
					index_num = Number(index_key);
				}

				if (Number.isNaN(index_num) || index_num < 0 || index_num >= this.value.length) {
					log.log('Error: list_get index out of bounds: ', index_num);
					return null;
				}

				// determine if referenced item is an Object, or string
				const item = this.value[index_num];
				if (item instanceof Object) {
					return new QEValueJSON({ value: item });
				} else {
					return new QEValueString({ value: item });
				}
			} else {
				log.log('Error: .list_get(index) specifier called on non-list JSON: ', JSON.stringify(this.value));
				return null;
			}
		} else if (specifier.match(/^map_get\([^)]*\)/)) {
			if (this.value instanceof Object && !(this.value instanceof Array)) {
				const key_str = specifier.match(/map_get\(([^)]*)\)/)[1];

				// placeholder resolution of key_str
				let key;
				if (key_str.match(/^\$/)) {
					const resolved_key_str = QEHelper.resolvePlaceholderToString('['+ key_str +']', resolved_data);
					if (resolved_key_str === null)
						return null;
					key = resolved_key_str.serialize_to_text();
				} else {
					key = key_str;
				}

				// determine if referenced item is an Object, or string
				const item = this.value[key];
				if (item === undefined) {
					log.log('Error: map_get key not found: ', key_str, key);
					return null;
				}

				if (item instanceof Object) {
					return new QEValueJSON({ value: item });
				} else {
					return new QEValueString({ value: item });
				}
			} else {
				log.log('Error: .map_get(key) specifier called on non-map JSON: ', JSON.stringify(this.value));
				return null;
			}
		} else if (specifier == "list_length") {
			if (this.value instanceof Array) {
				// return length as tree
				const len = this.value.length.toString();
				const len_tree = tokenize_and_parse(len, {}).tree;
				return new QEValueTree({ value: len_tree});
			} else {
				log.log('Error: .list_length specifier called on non-list JSON: ', JSON.stringify(this.value));
				return null;
			}
		}

		// fallback
		return super.applyResolutionSpecifier(specifier, resolved_data);
	}
}

// QEValueMap is similar to QEValueJSON but is a one-level deep map AND the values may be other QEValue objects
export class QEValueMap extends QEValue {
	value: object;

	constructor(data: { value: object }) {
		super(Object.assign({type: "map"}, data));
		this.value = data.value;
	}
	serialize_to_text(options: { [key: string]: string | boolean } = {}): string {
		const self = this;

		// serialize each value in the map
		const serialized = {};
		Object.keys(self.value).forEach(x => { serialized[x] = self.value[x].serialize_to_text(options); });
		return JSON.stringify(serialized);

/*
		return '{' + Object.keys(self.value).map(function (x) {
			return x + ':"' + self.value[x].serialize_to_text() + '"';
		}).join(',') + '}';
*/
	}
	display(options: { [key: string]: string | boolean } = {}): string {
		return this.serialize_to_text(options);
	}
//	exportValue(options: { allow_private?: boolean } = {}) {
//		return JSON.stringify(this.value);
//	}
}

export class QEValueBoolean extends QEValue {
	value: boolean;

	constructor(data: { value: boolean }) {
		super(Object.assign({type: "boolean"}, data));
		this.value = data.value;
	}
	serialize_to_text(options: { [key: string]: string | boolean } = {}): string {
		return JSON.stringify(this.value);
	}
	display(options: { [key: string]: string | boolean } = {}): string {
		return JSON.stringify(this.value);
	}
	exportValue(options: { allow_private?: boolean } = {}) {
		return this.value;
	}

	// helper methods for dotjs output
	to_text(): string {
		return this.serialize_to_text();
	}
	to_num(): number {
		return this.value == true ? 1 : 0;
	}
}

export class QEValueWidget extends QEValue {
	value: QEWidget;

	constructor(data: { value: QEWidget, subtype: string, key_name?: string }) {
		super(Object.assign({type: "widget"}, data));
		this.value = data.value;
	}
	applyResolutionSpecifier(specifier: string, resolved_data) {
		if (this.value instanceof Eq) {
			// pass-through to QEValueTree; this does mean any widget-specific info (such as display options) is lost, but that's already the case when invoking specifiers
			let tree = new QEValueTree({ value: this.value.value });
			return tree.applyResolutionSpecifier(specifier, resolved_data);
		} else if (this.value instanceof MC) {
			if (specifier == 'correct_target') {
				const correct_data = this.value.getCorrectChoice();
				if (correct_data) {
					// check that choice is an answer, and if so resolve to answer target
					if (correct_data.value.type == 'answer') {
						return correct_data.value.target;
					} else {
						log.log('Error: correct_target for mc not set: ', this);
						return null;
					}
				} else {
					log.log('Error: correct choice for mc not set: ', this);
					return null;
				}
			} else if (specifier == 'correct_value') {
				const correct_data = this.value.getCorrectChoice();
				if (correct_data) {
					return correct_data.value;
				} else {
					log.log('Error: correct choice for mc not set: ', this);
					return null;
				}
			} else if (specifier == 'correct_display') {
				const correct_data = this.value.getCorrectChoice();
				if (correct_data) {
					return new QEValueString({ value: correct_data.display });
				} else {
					log.log('Error: correct choice for mc not set: ', this);
					return null;
				}
			} else if (specifier == 'user_value') {
				const choice_data = this.value.user_value;
				if (choice_data) {
					return choice_data;
				} else {
					// no value set yet for user_value - likely not yet submitted
					return null;
				}
			}
		} else if (this.value instanceof StringLookup) {
			if (specifier.match(/^lookup\([^)]*\)/)) {
				let string_key = specifier.match(/lookup\(([^)]*)\)/)[1];
				if (string_key.match(/^\$/)) {
					const lookup_value_key = string_key.slice(1);

					// get referenced placeholder object
					const lookup_value = resolved_data.resolved[lookup_value_key];
					if (!lookup_value)
						return null;

					// serialize lookup_value
					string_key = lookup_value.serialize_to_text();
				}

				return new QEValueString({ value: this.value.lookup(string_key) });
			}
		} else if (this.value instanceof Table) {
			if (specifier.match(/^cell\([^)]*\)/)) {
				// getCell handles placeholder resolution of $col,$row
				const cell_key = specifier.match(/cell\(([^)]*)\)/)[1];
				return this.value.getCellValue(cell_key, resolved_data);
			} else if (specifier.match(/^col\([^)]*\)/)) {
				// getCol handles placeholder resolution of $col
				const col_key = specifier.match(/col\(([^)]*)\)/)[1];
				return this.value.getColList(col_key, resolved_data);
			} else if (specifier.match(/^row\([^)]*\)/)) {
				// getRow handles placeholder resolution of $row
				const row_key = specifier.match(/row\(([^)]*)\)/)[1];
				return this.value.getRowList(row_key, resolved_data);
			}
		}
		// TODO: other widget-type-specific specifier handlers

		return super.applyResolutionSpecifier(specifier, resolved_data);
	}
	serialize_to_text(options: { [key: string]: string | boolean } = {}): string {
		// pass-through to Widget
		return this.value.serialize_to_text(options);
	}
	getAttr(key: string): string {
		// pass-through to Widget
		return this.value.getAttr(key);
	}
	display(options: { [key: string]: string | boolean } = {}): string {
		return this.value.display(options);
	}
	exportValue(options: { allow_private?: boolean } = {}) {
		return this.value.exportValue(options);
	}

	// helper methods for dotjs output
	to_text(): string {
		return this.serialize_to_text();
	}
	to_num(): number {
		return Number(this.serialize_to_text());
	}
	to_fixed(places: number): string {
		let num = this.to_num();
		if (Number.isNaN(num)) return num;
		return num.toFixed(places);
	}
}

export class QEValueAnswer extends QEValue {
	value: Solution;
	target: QEValue;
	solution_key: string;
	target_key: string;

	constructor(data: { value: Solution, target: QEValue, solution_key: string, target_key: string }) {
		super(Object.assign({type: "answer"}, data));
		this.value = data.value;
	}
	serialize_to_text(options: { [key: string]: string | boolean } = {}): string {
		return this.value.value.serialize_to_text(options);
	}
	display(options: { [key: string]: string | boolean } = {}): string {
		return this.value.value.display(options);
	}

	// helper methods for dotjs output
	to_text(): string {
		return this.serialize_to_text();
	}
	to_num(): number {
		return Number(this.serialize_to_text());
	}
	to_fixed(places: number): string {
		let num = this.to_num();
		if (Number.isNaN(num)) return num;
		return num.toFixed(places);
	}

	applyResolutionSpecifier(specifier: string, resolved_data) {
		if (specifier == 'target') {
			return this.target;
		} else if (specifier == 'value') {
			return this.value.value; // Solution value
		}

		// default pass-through to Solution .value
		return this.value.value.applyResolutionSpecifier(specifier, resolved_data);
	}
}

// static factory to instantiate QEValue subclass
function createQEValue(data: { [key: string]: any }): QEValue {
	switch (data.type) {
		case "tree":
			return new QEValueTree({value: data.value});
		case "string":
			return new QEValueString({value: data.value});
		case "json":
			return new QEValueJSON({value: data.value});
		case "map":
			return new QEValueMap({value: data.value});
		case "boolean":
			return new QEValueBoolean({value: data.value});
		case "widget":
			return new QEValueWidget({subtype: data.subtype, value: data.value});
		case "answer":
			return new QEValueAnswer({value: data.value, target: data.target, solution_key: data.solution_key, target_key: data.target_key});
	}

	log.log("Error: unhandled QEValue subclass type: ", data);
	return null;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export class QEHelper {
	// helper function to parse an options string into a map
	//   - handles JSON, pseudo-json, and "key1:val1,key2:val2,..." key:val pairs
	static parseOptionsString(options_str: string = "", resolved_data?) {
		if (typeof options_str == "object") return options_str; // already parsed

		// attempt JSON parse, in case options string is JSON instead of comma-delimited text
		let options = {};

		if (options_str.match(/^{.*}$/)) {
			try {
				// attempt JSON parse, e.g. {"key1":"[$blah]"}
				options = JSON.parse(options_str);
			} catch (err) {
				// attempt pseudo-json parse, e.g. {key:[$blah]}

				// TODO: handle quotes in tokenization and parsing so we can handle '{some_key:","}'

				// strip surrounding curly braces
				let key_vals = options_str.replace(/^{(.*)}$/, (str, g1) => { return g1; });

				// tokenize with lookahead and lookbehind
				let tokens = key_vals.split(/(?=[\[\]\,\:\(\)])|(?<=[\[\]\,\:\(\)])/g);

				// parse: based on stack context, each token will set current key, append to current value, or clear current key
				//   the tokens "[", "]", "(", and ")" are mainly tracked so that we can differentiate between comma tokens
				//   contained within a value, and those used to separate named parameter fields
				let stack = []; //{ level: 'root', open: null };
				let cur_key = null;
				let cur_val = '';
				let err_state = false;
				for (let index = 0; index < tokens.length; index++) {
					const token = tokens[index].trim();
					if (token == '[') {
						cur_val += token;
						stack.push(token);
					} else if (token == '(') {
						cur_val += token;
						stack.push(token);
					} else if (token == ':') {
						if (!stack.length) {
							// at top-level
							if (cur_key !== null) {
								cur_val = ''; // init value
							} else {
								log.log('Parser mismatch! Separator token "'+ token +'" encountered at top of context stack but cur_key null at index: '+ index +' for tokens: ', tokens);
								break;
							}
						} else {
							cur_val += token;
						}
					} else if (token == ',') {
						if (!stack.length) {
							// at the top level, a comma completes and clears the current value
							options[cur_key] = cur_val;
							cur_key = null;
							cur_val = '';
						} else {
							cur_val += token;
						}
					} else if (token == ')') {
						if (!stack.length) {
							log.log('Parser mismatch! Content closing token "'+ token +'" encountered but already at top of context stack at index: '+ index +' for tokens: ', tokens);
							break;
						} else if (stack.slice(-1)[0] === '(') {
							cur_val += token;
							stack.pop();
						} else {
							log.log('Parser mismatch! Expected context to be "(" but got "'+ stack.slice(-1)[0] +'" at index: '+ index +' for tokens: ', tokens);
							break;
						}
					} else if (token == ']') {
						if (!stack.length) {
							log.log('Parser mismatch! Content closing token "'+ token +'" encountered but already at top of context stack at index: '+ index +' for tokens: ', tokens);
							break;
						} else if (stack.slice(-1)[0] === '[') {
							cur_val += token;
							stack.pop();
						} else {
							log.log('Parser mismatch! Expected context to be "[" but got "'+ stack.slice(-1)[0] +'" at index: '+ index +' for tokens: ', tokens);
							break;
						}
					} else {
						if (!stack.length && cur_key === null) {
							cur_key = token;

							if (cur_key == '"' || cur_key == "'") {
								log.warn('Problem parsing pseudo-json. Most likely trying to quote a comma. Use proper JSON.', {options_str: options_str});
							}
						} else {
							cur_val += token;
						}
					}
				}
				// store cur_key-cur_val
				if (cur_key !== null) options[cur_key] = cur_val;
			}
		} else {
			// no surrounding braces, treat as comma-separated pairs instead
			options_str.split(/,/).filter(function (opt_pair) {
				// only keep the "pair" if it is a colon-delimited pair
				let pair = opt_pair.split(/:/);
				if (pair.length == 2) {
					options[pair[0]] = pair[1];
				}
			});
		}

		return options;
	}

	// helper function to parse a JSON, pseudo-json, or "key1,val1,key2,val2,..." options string into a map of key-value pairs
	//    and to resolve placeholders in the resulting values
	// - placeholders are resolved to QEValue objects
	static resolveOptionsString(options_str = "", resolved_data) {
		// build map from options string
		const options = {};

		// first parseOptionsString to get a JSON map of key-value pairs, then resolve those values
		const unresolved_options = QEHelper.parseOptionsString(options_str);

		// resolve placeholder in value
		Object.keys(unresolved_options).forEach(function(pair_key){
			let pair_val = unresolved_options[pair_key];

			// NOTE: for option string $placeholder pair_val specified in a template (e.g. the "$data" in "[$graph.display(value:$data)]"),
			//    there can't be [] brackets, since otherwise the [$placeholder] would resolve and immediately serialize to string.
			if (typeof pair_val == "string" && pair_val.match(new RegExp('^\\[\\$[^\\[\\]]*\\]$'))) {
				// resolve "[$key]" pair_val
				const value_param = QEHelper.resolvePlaceholderToRefs(pair_val, resolved_data);
				if (!value_param) {
					// unresolved dependency
					return null;
				} else {
					pair_val = value_param;
				}
			} else if (typeof pair_val == "string" && pair_val.match(new RegExp('^\\$[^\.()]*$'))) {
				// resolve "$key" pair_val - auto-wrap in [] brackets for resolve
				const value_param = QEHelper.resolvePlaceholderToRefs('['+ pair_val +']', resolved_data);
				if (!value_param) {
					// unresolved dependency
					return null;
				} else {
					pair_val = value_param;
				}
			}

			// strip quotes surrounding pair value
			if (typeof pair_val == "string" && pair_val.match(new RegExp('^".*"$'))) {
				pair_val = pair_val.replace(/^"/, '').replace(/"$/, '');
			}

			options[pair_key] = pair_val;
		});

		return options;
	}

	static getResolutionSpecifierList(str) {
		if (!str.match(/\./))
			return [str];

		// split into list of specifiers on ".", but avoid splitting on specifiers containing ".", such as ".display(style_opactity:0.5)"
		const ref_list = [];
		let temp_str = str;
		while (temp_str.match(/\./)){
			if (temp_str.match(/^[^\.]*\([^\)]*\)\.?/)) {
				ref_list.push(temp_str.match(/^([^\.]*\([^\)]*\))\.?/)[1]);
				temp_str = temp_str.replace(/^[^\.]*\([^\)]*\)\.?/, '');
			} else {
				ref_list.push(temp_str.match(/^([^\.]*)\./)[1]);
				temp_str = temp_str.replace(/^[^\.]*\./, '');
			}
		}
		if (temp_str.length)
			ref_list.push(temp_str);

		return ref_list;
	}

	static applyPlaceholderSpecifiers(ref_key: string, resolved_data): QEValue {
		const ref_list = QEHelper.getResolutionSpecifierList(ref_key);
		const current_widget_key = ref_list.shift();

		let current_widget;
		if (current_widget_key.match(/^".+"$/)) {
			// if current_widget_key is enclosed by '"' tokens, convert enclosed string to QEValueString
			let enclosed = current_widget_key.match(/^"(.+)"$/)[1];
			current_widget = new QEValueString({value: enclosed});
		} else {
			current_widget = resolved_data.resolved[current_widget_key];
		}
		if (!current_widget) return null;

		// handling for resolution specifiers (e.g. lhs, rhs)
		for (let i = 0; i  < ref_list.length; i++) {
			const ref_token = ref_list[i];

			current_widget = current_widget.applyResolutionSpecifier(ref_token, resolved_data);
			if (current_widget === null) {
				// specifier references unresolved placeholder
				return null;
			}
		}

		return current_widget;
	}

	// resolvePlaceholderToRefs:
	// - for single ref placeholders (e.g. "[$p1]", or "[$eq1.lhs]"), returns the QEValue(Tree|Widget|etc.) of the placeholder, with applied specifiers
	// - for mixed or no placeholders (e.g. "[$p1]+[$p2]", or "123"), serializes placeholders to text and returns a QEValueString
	// - NOTE: applied specifiers such as "display()" or "display_as(...)" may result in markup
	static resolvePlaceholderToRefs(input_str: string | number | boolean, resolved_data, options: {[key: string]: string} = {}): QEValue {
		if (typeof input_str == "boolean")
			return new QEValueBoolean({ value: input_str });

		if (typeof input_str == "number")
			input_str = input_str.toString();

		// init if empty
		resolved_data.resolved = resolved_data.resolved || {};

		if (input_str === undefined) {
			log.warn('Undefined placeholder ref', Object.assign({}, {input_str: input_str}, options));
			return null;
		}

		if (input_str.match(new RegExp('^\\[\\$[^\\[\\]]*\\]$'))) {
			// Case 1: input_str is a single reference
			// - apply specifiers
			const match_list = input_str.match(new RegExp('\\[\\$([^\\[\\]]*)\\]'));
			const ref_key = match_list[1];

			return QEHelper.applyPlaceholderSpecifiers(ref_key, resolved_data);
		} else {
			// Case 2: input_str is mixed or has no placeholders (e.g. "[$p1]+[$p2]", or "123")
			// - serialize each placeholder to string and replace in input_str
			while (input_str.match(new RegExp('\\[\\$[^\\[\\]]*\\]'))) {
				const match_list = input_str.match(new RegExp('\\[\\$([^\\[\\]]*)\\]'));
				let ref_key = match_list[1];

				const specified_value = QEHelper.applyPlaceholderSpecifiers(ref_key, resolved_data);
				if (specified_value === null)
					return null;

				// escape non-regex-safe characters to prevent infinite loops
				ref_key = ref_key.replace(/([.*+?^${}()|\[\]\/\\])/g, "\\$1");

				// replace placeholder with serialized string
				const output_str = specified_value.serialize_to_text();
				input_str = input_str.replace(new RegExp('\\[\\$'+ ref_key +'\\]', 'g'), output_str);
			}

			// attempt to JSON parse the resolved string
			let json_value;
			try {
				json_value = JSON.parse(input_str);
			} catch(err) {
				// input_str not json
			}
			if (json_value && typeof json_value == 'object') {
				return new QEValueJSON({value: json_value});
			} else {
				return new QEValueString({value: input_str});
			}
		}
	}

	static resolvePlaceholderToTree(input_str: string | number, resolved_data, options: {[key: string]: string} = {}): QEValueTree {
		const resolved_ref = QEHelper.resolvePlaceholderToRefs(input_str, resolved_data, options);
		if (resolved_ref === null)
			return null;

		if (resolved_ref.type === "string") {
			// parse and check output for error
			const parsed = tokenize_and_parse(resolved_ref.value, {parameter_map: resolved_data});
			if (parsed.tree == null)
				return null;

			return new QEValueTree({ value: parsed.tree });
		} else if (resolved_ref.type === "tree") {
			return resolved_ref;
		} else if (resolved_ref instanceof QEValueWidget && resolved_ref.value instanceof Eq) {
			// TODO: use generic QEValue "export_to_tree()"?
			return new QEValueTree({ value: resolved_ref.value.value });
		}

		log.warn("Error: expected placeholder to resolve to tree, but got: ", Object.assign({}, {resolved_ref: resolved_ref, input_str: input_str}, options));
		return null;
	}

	static resolvePlaceholderToString(input_str: string | number, resolved_data, options: {[key: string]: string} = {}): QEValueString {
		// trim leading/trailing spaces
		input_str = typeof input_str != 'string' ? input_str : input_str.trim();

		const resolved_ref = QEHelper.resolvePlaceholderToRefs(input_str, resolved_data, options);
		if (resolved_ref === null)
			return null;

		if (resolved_ref.type === "string")
			return resolved_ref;

		const serialized = resolved_ref.serialize_to_text();
		if (serialized === null)
			return null;

		return new QEValueString({ value: serialized });
	}

	static resolvePlaceholderToJSON(input_str: string, resolved_data, options: {[key: string]: string} = {}): QEValueJSON {
		let resolved_ref = QEHelper.resolvePlaceholderToRefs(input_str, resolved_data, options);
		if (resolved_ref === null)
			return null;

		if (resolved_ref instanceof QEValueJSON)
			return resolved_ref;

		let serialized;
		if (resolved_ref instanceof QEValueWidget) {
			if (resolved_ref.value instanceof Graph)
				serialized = resolved_ref.value.exportValue().series;

			if (resolved_ref.value instanceof Table)
				serialized = resolved_ref.value.exportValue().values;
		}

		if (resolved_ref instanceof QEValueString)
			serialized = resolved_ref.value;

		let deserialized;
		try {
			deserialized = JSON.parse(serialized);
		} catch (err) {
			log.warn("Error: failed to parse JSON: ", Object.assign({}, {serialized: serialized, err: err}, options));
			return null;
		}

		return new QEValueJSON({ value: deserialized });
	}

	static resolvePlaceholderToMarkup(input_str: string | number, resolved_data, options: {[key: string]: string} = {}): QEValueString {
		// NOTE: mostly behaves like QEHelper.resolvePlaceholderToRefs, but serializes with "display()" instead of "serialize_to_text()". Could combine?

		if (typeof input_str == "number")
			input_str = input_str.toString();

		// init if empty
		resolved_data.resolved = resolved_data.resolved || {};

		if (input_str.match(new RegExp('^\\[\\$[^\\[\\]]*\\]$'))) {
			// Case 1: input_str is a single reference
			// - apply specifiers
			const match_list = input_str.match(new RegExp('\\[\\$([^\\[\\]]*)\\]'));
			const ref_key = match_list[1];

			const specifiedValue = QEHelper.applyPlaceholderSpecifiers(ref_key, resolved_data);
			if (specifiedValue === null)
				return null;

			// nested dropdowns in Eq trees must be rendered here, since they contain inline content that must be included in the tree markup
			if (specifiedValue instanceof QEValueWidget && specifiedValue.value instanceof Eq) {
				const eq_widget = specifiedValue.value;
				const tree = eq_widget.value;

				tree.findAllChildren('type', 'INPUT').forEach(function(node){
					const input_key_match = node.value.match(/\[\?(.*)\]/);
					if (!input_key_match) {
						return
					}

					// get the associated nested input widget
					const input_key = input_key_match[1];
					if (!resolved_data.resolved[input_key]) {
						log.warn("ERROR: nested input widget not included in exported data", Object.assign({}, {input_str: input_str}, options));
						return;
					}

					const nested_input_widget = resolved_data.resolved[input_key].value;
					if (nested_input_widget instanceof DropDown) {
						// render DropDown, and set node.attr content_markup
						let iw_ml = nested_input_widget.display();
						node.attr('content_markup', iw_ml);
					}

					if (nested_input_widget instanceof DropZone) {
						// render DropZone, and set node.attr content_markup
						let iw_ml = nested_input_widget.display();
						node.attr('content_markup', iw_ml);
					}

					if (nested_input_widget instanceof EqKeyboard) {
						// render EqKeyboard, and set node.attr content_markup
						let iw_ml = nested_input_widget.display();
						node.attr('content_markup', iw_ml);
					}
				});
			}

			return new QEValueString({ value: specifiedValue.display(options) });
		} else {
			// Case 2: input_str is mixed or has no placeholders (e.g. "[$p1]+[$p2]", or "123")
			// - serialize each placeholder to string and replace in input_str
			while (input_str.match(new RegExp('\\[\\$[^\\[\\]]*\\]'))) {
				const match_list = input_str.match(new RegExp('\\[\\$([^\\[\\]]*)\\]'));
				let ref_key = match_list[1];

				const specifiedValue = QEHelper.applyPlaceholderSpecifiers(ref_key, resolved_data);
				if (specifiedValue === null)
					return null;

				// escape non-regex-safe characters to prevent infinite loops
				ref_key = ref_key.replace(/([.*+?^${}()|\[\]\/\\])/g, "\\$1");

				// replace placeholder with serialized string
				const output_str = specifiedValue.display(options);
				input_str = input_str.replace(new RegExp('\\[\\$'+ ref_key +'\\]', 'g'), output_str);
			}
			return new QEValueString({ value: input_str });
		}
	}

	static resolveToNumber(value: string, resolved_data): number {
		let resolved = QEHelper.resolvePlaceholderToTree(value, resolved_data);
		if (!resolved) return null;

		return resolved.value.evaluate_to_float();
	}

	static resolveSpecifierKeyToNumber(key_value: string, resolved_data): number {
		// helper for resolving placeholder keys in placeholder ".specifiers(...)"
		if (key_value.match(/^\$/)) {
			return QEHelper.resolveToNumber('['+ key_value +']', resolved_data);
		}

		return Number(key_value);
	}

	static resolvePlotDataset(input_str: string, resolved_data, options: {[key: string]: string} = {}) {
		const deserialized = JSON.parse(input_str);

		// resolve any references in value
		let dataset;
		if (deserialized.dataset_from_ref) {
			let resolved = QEHelper.resolvePlaceholderToRefs(deserialized.dataset, resolved_data);
			if (!resolved) return null;

			if (resolved instanceof QEValueWidget) {
				if (resolved.value instanceof Table) {
					// JSON exported from Table: { headers: [{value: string}, ..], rows: [[ {value: string}, ...], ...] }
					let table_rows = resolved.value.values.rows;

					// validate table rows have >= 2 columns. col1 -> label, col2 -> value
					if (table_rows.length && table_rows[0] instanceof Array && table_rows[0].length >= 2) {
						dataset = [];
						for (let i = 0; i < table_rows.length; i++) {
							let row = table_rows[i];
							let point = {
								label: row[0].value.serialize_to_text(),
								value: row[1].value.serialize_to_text()
							};
							dataset.push(point);
						}
					} else {
						log.log('Error: resolvePlotDataset called on Table, but must have >= 1 row and >= 2 columns: ', resolved.value);
					}
				} else if (resolved.value instanceof Graph) {
					// TODO: support JSON exported from Graph: [{ type: string, values: [{x: string, y: string}, ...] }, ...]
					// TODO: expouse Graph first data series
					log.log('Graph: ', resolved.value);
				} else {
					log.log('Error: resolvePlotDataset called on widget ref, but widget is not Table or Graph: ', resolved.value);
				}
			} else if (resolved instanceof QEValueJSON) {
				dataset = resolved.value;
			} else {
				log.log('Warning: resolvePlotDataset called on non-json data reference: ', resolved);
				dataset = resolved.value;
			}

		} else {
			// JSON values specified in tool UI
			try {
				dataset = JSON.parse(deserialized.dataset);
			} catch (err) {
				log.log('Error: unable to parse dataset values: ', deserialized.dataset, err);
				return null;
			}
		}

		if (!(dataset instanceof Array)) {
			log.log('Error: dataset must be an array of {label, value} maps. ', dataset);
			return null;
		}

		// resolve placeholders in dataset labels and values
		for (let i = 0; i < dataset.length; i++) {
			let resolved = QEHelper.resolvePlaceholderToString(dataset[i].label, resolved_data);
			if (resolved !== null)
				dataset[i].label = resolved.value;

			let value = QEHelper.resolveToNumber(dataset[i].value, resolved_data);
			if (!Number.isInteger(value)) {
				log.log('Error: non-integer dataset value');
				return null;
			}

			dataset[i].value = value;
		}

		return dataset;
	}

	static resolveDataset(input_str: string, resolved_data, options: {[key: string]: string} = {}) {
		// resolve a reference or string to JSON
		// - will try to resolve all keys
		// - resolves references to strings by default, but "label" and "display" fields will be resolved to markup
		// - if referenced value is a Table, uses column headers as field names (e.g. "x", "y", "z", or "label", "value"

		const deserialized = JSON.parse(input_str);

		// resolve any references in value
		let dataset = [];
		if (deserialized.dataset_from_ref) {
			let resolved = QEHelper.resolvePlaceholderToRefs(deserialized.dataset, resolved_data);
			if (!resolved) return null;

			if (resolved instanceof QEValueWidget) {
				// cast Table content  to dataset
				if (resolved.value instanceof Table) {
					// JSON exported from Table: { headers: [{value: string}, ..], rows: [[ {value: string}, ...], ...] }
					let table_headers = resolved.value.values.headers;
					let table_rows = resolved.value.values.rows;

					// get key_names from table_headers - if no header row (or header row values are blank) then treat 1st column as "value", 2nd as "display", and ignore further columns
					let column_keys = ['value','display'];
					if (table_headers.length) {
						for (let th_idx = 0; th_idx < table_headers.length; th_idx++) {
							if (th_idx >= column_keys.length) {
								column_keys.push(table_headers[th_idx].value.serialize_to_text());
							} else {
								let column_key = table_headers[th_idx].value.serialize_to_text();
								if (column_key.length) {
									// override the default key for first or second column if a name is provided
									column_keys[th_idx] = column_key;
								}
							}
						}
					}

					if (table_rows.length && table_rows[0] instanceof Array && table_rows[0].length) {
						for (let i = 0; i < table_rows.length; i++) {
							let table_row = table_rows[i];
							let row = {};

							for (let key_idx = 0; key_idx < column_keys.length; key_idx++) {
								let key = column_keys[key_idx];
								if (!key.length) continue;

								// special fields: "label" and "display" are resolved to markup if possible
								if (['label', 'display'].indexOf(key) != -1) {
									row[key] = table_row[key_idx].display;
								} else if (key.length) {
									row[key] = table_row[key_idx].value.serialize_to_text(); // value should be a QEValue
								}
							}
							dataset.push(row);
						}
					} else {
						log.log('Error: resolvePlotDataset called on Table, but must have >= 1 row and >= 2 columns: ', resolved.value);
					}
				} else if (resolved.value instanceof Graph) {
					// TODO: support JSON exported from Graph: [{ type: string, values: [{x: string, y: string}, ...] }, ...]
					// TODO: expouse Graph first data series
					log.log('Graph: ', resolved.value);
				} else {
					log.log('Error: resolveDataset called on widget ref, but widget is not Table or Graph: ', resolved.value);
				}
			} else if (resolved instanceof QEValueJSON) {
				dataset = resolved.value;
			} else if (resolved instanceof QEValueAnswer) {
				// TODO: handle QEValueAnswer
			} else {
				log.warn('Warning: resolveDataset called on non-json data reference: ', resolved);
				return null;
			}

		} else {
			// JSON values specified in tool UI
			try {
				dataset = JSON.parse(deserialized.dataset);
			} catch (err) {
				log.log('Error: unable to parse dataset values: ', deserialized.dataset, err);
				return null;
			}
		}

		if (!(dataset instanceof Array)) {
			log.log('Error: dataset must be an array of {key: value} maps. ', dataset);
			return null;
		}

		// resolve placeholders in dataset fields
		for (let row_idx = 0; row_idx < dataset.length; row_idx++) {
			let row = dataset[row_idx];
			let keys = Object.keys(row);

			let value_display;
			for (let key_idx = 0; key_idx < keys.length; key_idx++) {
				let key = keys[key_idx];

				if (key == 'value') {
					// render the "value" field to markup, in case it's needed for a "display" field that has been left blank
					let resolved = QEHelper.resolvePlaceholderToMarkup(row[key], resolved_data);
					if (!resolved) return null;
					value_display = resolved.value;
				}

				// special fields: "label" and "display" are resolved to markup if possible
				if (['label', 'display'].indexOf(key) != -1) {
					let resolved = QEHelper.resolvePlaceholderToMarkup(row[key], resolved_data);
					if (!resolved) return null;

					row[key] = resolved.value;
				} else {
					let resolved = QEHelper.resolvePlaceholderToString(row[key], resolved_data);
					if (!resolved) return null;

					row[key] = resolved.value;
				}
			}

			// if there isn't a "display" field or it has been left blank, set it using the "value" field, resolved to markup
			if ((row['display'] === undefined || !row['display'].length) && value_display !== undefined) {
				row['display'] = value_display;
			}
		}
		return dataset;
	}

	static populateTemplate(template: string, dest_data, options: {[key: string]: any} = {}): string {
		// regex replace template placeholders with resolved parameters
		const display_options = Object.assign({ display: 1, output_type: "markup" }, options);

		let replace_counter = 100;

		// inline_string_placeholder allows arbitrary string values to be used with placeholder specifiers
		// processing of it is performed last, so named value placeholders can be included in the arbitrary string
		const inline_string_placeholder = '"[^"]*"';
		var widget_keys = Object.keys(dest_data.resolved).concat(inline_string_placeholder);

		// compile doT.js template
		let template_fn;
		try {
			// prepend included dotjs templates
			if (options.dotjs_templates) {
				template_fn = doT.template('{{ const log = it["log"]; }}'+ options.dotjs_templates + template, Object.assign({}, doT.templateSettings, {strip: display_options.keep_whitespace ? false: true}));
			} else {
				template_fn = doT.template('{{ const log = it["log"]; }}'+ template, Object.assign({}, doT.templateSettings, {strip: display_options.keep_whitespace ? false: true}));
			}
		} catch (err) {
			log.log('dotjs template compile error: ', err);
			template = '<div style="width: 100%; background: red; color: #fff; padding: 5px 10px;">Syntax error parsing dotjs template. See console for details.</div>'+ template;
		}
		if (template_fn && options.dotjs_enabled) {
			// execute doT.js template
			try {
				let dot_template = template_fn(Object.assign({ log: log }, dest_data.resolved));
				template = dot_template;
			} catch (err) {
				log.log('dotjs error: ', err, dest_data.resolved);
				template = '<div style="width: 100%; background: red; color: #fff; padding: 5px 10px;">Syntax error executing dotjs template func. See console for details.</div>'+ template;
			}
		}
		template = template.trim();

		// insert widgets in output
		for (let i = 0 ; i < widget_keys.length; i++) {
			const widget_key = widget_keys[i];
			const dest_widget = dest_data.resolved[widget_key];

			// loop over template, and for each match, run it through QEValGen.resolvePlaceholderToMarkup to get back the rendered content
			while (replace_counter && (
				template.match(new RegExp('\\[\\$' + widget_key + '\\.[^\\]]*\\]')) ||
				template.match(new RegExp('\\[\\$' + widget_key + '\\]'))
			)) {
				replace_counter--;
				if (!replace_counter)
					log.log('ERROR: infinite loop on populateTemplate replace.');

				// widget_ref may contain specifiers
				let widget_ref;
				if (template.match(new RegExp('\\[\\$' + widget_key + '\\.[^\\]]*\\]')))
					widget_ref = template.match(new RegExp('(\\[\\$' + widget_key + '\\.[^\\]]*\\])'))[1];
				else
					widget_ref = template.match(new RegExp('(\\[\\$' + widget_key + '\\])'))[1];

				const resolved_markup = QEHelper.resolvePlaceholderToMarkup(widget_ref, dest_data, display_options);
				if (!resolved_markup) {
					log.log("Warning unresolved dependency for: ", widget_ref);
					return null; // unresolved dependency
				}

				let widget_ml = resolved_markup.value;
				if (widget_ml === null && options.require_dependencies)
					return null; // abort

				// check if the placeholder references a widget
				const non_display_ref = QEHelper.resolvePlaceholderToRefs(widget_ref, dest_data);

				// wrap widget values in div so client can attach handler logic
				if (dest_widget) {
					// but don't wrap widget_ml if the resolved target is a simple string
					if (dest_widget.value instanceof Graph && non_display_ref.value instanceof Graph) {
						// fix for Safari bug: svg in inline-block does not display
						widget_ml = '<div class="widget' + (options.disable_input ? ' disable_input' : '') + '" data-widget_key="' + widget_key + '">' + widget_ml + '</div>';
					} else if (dest_widget.value instanceof QEWidget && non_display_ref.value instanceof QEWidget) {
						widget_ml = '<div class="widget' + (options.disable_input ? ' disable_input' : '') + '" data-widget_key="' + widget_key + '" style="display: inline-block; vertical-align: middle;">' + widget_ml + '</div>';
					}
				}

				// escape non-regex-safe characters to prevent infinite loops
				widget_ref = widget_ref.replace(/([.*+?^${}()|\[\]\/\\])/g, "\\$1");

				template = template.replace(new RegExp(widget_ref, 'g'), widget_ml);
			}
		}

		if ((
			template.match(new RegExp('\\[\\$[^\\]]*\\.[^\\[\\]]*\\]')) ||
			template.match(new RegExp('\\[\\$[^\\]]*\\]'))
		) && options.require_dependencies) {
			// unresolved dependency in template
			return null;
		}

		// check string for '<katex>' and '</katex>' -> if found, latex render
		if (template.indexOf('<katex>') !== -1) {
			template = template.replace(/<katex>(.*?)<\/katex>/g, function (full, inner) {
				let rendered_katex = '';
				try {
					rendered_katex = katex.renderToString(inner, { displayMode: true });
				} catch(err){
					log.error('Fatal error rendering katex content', {content: inner});
					rendered_katex = '<div class="bad-katex" style="border: 1px solid red; padding-right: 5px;"><span style="background: red; color: #fff; padding: 3px;">Malformed katex content:</span> '+ inner +'</div>';
				}
				return rendered_katex;
			});
		}

		return template;
	}

	// eg:   getPrimeFactorDecomposition(60) --> [[2,2], [3,1], [5,1]]
	// returns [1,1] factor if called with argument 1
	// never returns 1
    static getPrimeFactorDecomposition(num: number): undefined | [number, number][] {
		if (num < 1)
			return undefined;

		if (num === 1) {
			return [[1, 1]];
		}

		const prime_factors = [];
		let max = Math.sqrt(num);
		let i = 2;
		while (i <= max) {
			if (num % i !== 0) {
				i++;
				continue;
			}
			// i is a prime factor of num
			const back = prime_factors.length - 1;
			if (back >= 0 && prime_factors[back][0] === i) {
				prime_factors[back][1]++;
			} else {
				prime_factors.push([i, 1]);
			}
			num /= i;
			max = Math.sqrt(num);
		}

		const back = prime_factors.length - 1;
		if (back >= 0 && prime_factors[back][0] === num) {
			prime_factors[back][1]++;
		} else {
			prime_factors.push([num, 1]);
		}

		return prime_factors;
	}

	static testKatex() {
		return katex.renderToString("Test katex", { displayMode: true });
	}

	static getWidgetTag(widget_key, options) {
		options = options || {};
		return '<div class="widget' + (options.disable_input ? ' disable_input' : '') + '" data-widget_key="' + widget_key + '" style="display: inline-block; vertical-align: middle;"></div>';
	}

	// serialization helper function
	static exportValue(value, options: { allow_private?: boolean } = {}) {
		// TODO: type value: QEValue. Needs fix to engine/server/Solver/Steps/GraphSolver.ts

		if (typeof value != 'object' || !value.type) {
			log.log('Error: attempting to export non-value type: ', value);
			return null;
		}

		let value_final = value;
		if (value_final instanceof QEValueAnswer) {
			value_final = value_final.value.value;
		}

		if (value_final instanceof QEValueWidget) {
			return value_final.value.exportValue(options);
		} else if (value_final.type == 'tree') {
			return value_final.serialize_to_text();
		} else if (value_final.type == 'string') {
			return value_final.value;
		} else if (value_final.type == 'boolean') {
			return value_final.value;
		} else if (value_final.type == 'json') {
			// serialize
			return JSON.stringify(value_final.value);
		}

		log.log('Error. Attempting to export unhandled value type: ', value);
		return '';
	}

	static deserializeInputWidget(resolved_widgets, widget_key, user_input_configs, input_widget_configs) {
		const widget_data = input_widget_configs[widget_key];
		if (!widget_data) {
			// config missing
			return;
		}
		if (resolved_widgets[widget_key]) {
			// already deserialized
			return;
		}

		// deserialize based on widget type
		if (widget_data.type == 'equation') {
			const display_options = JSON.parse(widget_data.display_options || '{}');
			const tree = tokenize_and_parse(widget_data.value).tree;

			// if Eq widget contains input nodes (keyboard inputs), assign each input node an index_id attribute so we can distinguish between multiple inputs in an equation
			let input_key_index = 0;
			tree.findAllChildren('type', 'INPUT').forEach(function(node){
				const input_key_match = node.value.match(/\[\?(.*)\]/);
				if (input_key_match) {
					node.attr('input_key_index', input_key_index);

					// deserialize the associated KB widget
					const input_key = input_key_match[1];
					if (!input_widget_configs[input_key]) {
						log.log("ERROR: nested input info not included in exported data");
						return;
					}

					const combined_kb_key = widget_key +'__'+ input_key +'__'+ input_key_index;
					const iw_data = input_widget_configs[input_key];
					const display_options = JSON.parse(iw_data.display_options || '{}');

					// check if it is a keyboard or a dropdown
					if (iw_data.type == "keyboard") {
						const keyboard_type = iw_data.kb_type;
						const input_content = iw_data.value;

						// deserialize keyboard display options
						if (display_options.char_limit) {
							display_options.char_limit = parseInt(display_options.char_limit);
						}
						if (display_options.per_char_limit) {
							try {
								display_options.per_char_limit = JSON.parse(display_options.per_char_limit);
							} catch(err) {
								display_options.per_char_limit = {};
							}
						}

						resolved_widgets[combined_kb_key] = new EqKeyboard(input_content, {
							type: keyboard_type,
							name: input_key,
							input_key_index: input_key_index,
							display_options: display_options
						});

						// render EqKeyboard, and set node.attr content_markup
						let iw_ml = resolved_widgets[combined_kb_key].display();
						node.attr('content_markup', iw_ml);
					} else if (iw_data.type == "dropdown") {
						const dataset = JSON.parse(iw_data.dataset);
						resolved_widgets[combined_kb_key] = new DropDown(dataset, {
							name: input_key,
							input_key_index: input_key_index,
							display_options: display_options,
						});

						// init node.content
						if (dataset.length) {
							node.content = QEHelper.resolvePlaceholderToTree(dataset[0].value, {}).value;
						}

						// render DropDown, and set node.attr content_markup
						let iw_ml = resolved_widgets[combined_kb_key].display();
						node.attr('content_markup', iw_ml);
					} else if (iw_data.type == "dropzone") {
						const original_widget_config = input_widget_configs[input_key]
						const source_key = original_widget_config.source_key;
						resolved_widgets[combined_kb_key] = new DropZone(source_key, {
							name: input_key,
							input_key_index: input_key_index,
							display_options: display_options,
						});

						// render DropZone, and set node.attr content_markup
						let iw_ml = resolved_widgets[combined_kb_key].display();
						node.attr('content_markup', iw_ml);
					}

					// if the "answer" input widget key points to this keyboard input_key, then update to point to this combined_kb_key
					for (let i = 0; i < user_input_configs.length; i++) {
						if (user_input_configs[i].widget_key == input_key) {
							user_input_configs[i].widget_key = combined_kb_key;
						}
					}

					input_key_index++;
				}
			});

			resolved_widgets[widget_key] = new Eq(tree, display_options);
		} else if (widget_data.type == 'table') {
			const display_options = JSON.parse(widget_data.display_options || '{}');
			const values = JSON.parse(widget_data.values);

			// if Table widget contains keyboard inputs, assign each kb input an index_id attribute so we can distinguish between multiple inputs
			let input_key_index = 0;

			// if the input widget is a Table it should contain one or more kb widget references
			let included_input_keys = [];
			let rows = values.rows;
			for (let j = 0; j < rows.length; j++) {
				let row = rows[j];
				for (let k = 0; k < row.length; k++) {
					let cell = row[k];

					if (typeof cell.value === "object" &&
						(cell.value.type === "keyboard" || cell.value.type === "dropdown" || cell.value.type === "dropzone")
					) {
						// deserialize the associated input widget
						const input_key = cell.value.name;
						if (!input_widget_configs[input_key]) {
							log.log("ERROR: nested input info not included in exported data");
							return;
						}

						const combined_iw_key = widget_key +'__'+ input_key +'__'+ input_key_index;
						const iw_data = input_widget_configs[input_key];
						const display_options = JSON.parse(iw_data.display_options || '{}');

						// check if it is a keyboard or a dropdown
						if (iw_data.type == "keyboard") {
							const keyboard_type = iw_data.kb_type;
							const input_content = iw_data.value;

							// deserialize keyboard display options
							if (display_options.char_limit) {
								display_options.char_limit = parseInt(display_options.char_limit);
							}
							if (display_options.per_char_limit) {
								try {
									display_options.per_char_limit = JSON.parse(display_options.per_char_limit);
								} catch(err) {
									display_options.per_char_limit = {};
								}
							}

							resolved_widgets[combined_iw_key] = new EqKeyboard(input_content, {
								type: keyboard_type,
								name: input_key,
								input_key_index: input_key_index,
								display_options: display_options
							});
						} else if (iw_data.type == "dropdown") {
							const dataset = JSON.parse(iw_data.dataset);
							resolved_widgets[combined_iw_key] = new DropDown(dataset, {
								name: input_key,
								input_key_index: input_key_index,
								display_options: display_options,
							});
						} else if (iw_data.type == "dropzone") {
							const original_widget_config = input_widget_configs[input_key]
							const source_key = original_widget_config.source_key;
							resolved_widgets[combined_iw_key] = new DropZone(source_key, {
								name: input_key,
								input_key_index: input_key_index,
								display_options: display_options,
							});
						}

						// replace cell value with instantiated keyboard
						cell.value = resolved_widgets[combined_iw_key];

						// store the combined_iw_key so the cell can wrap it in a widget container
						cell.key_name = combined_iw_key;

						// if the "answer" input widget key points to this keyboard input_key, then update to point to this combined_iw_key
						for (let i = 0; i < user_input_configs.length; i++) {
							if (user_input_configs[i].widget_key == input_key) {
								user_input_configs[i].widget_key = combined_iw_key;
							}
						}

						input_key_index++;
					}
				}
			}

			resolved_widgets[widget_key] = new Table(values, display_options);
		} else if (widget_data.type == 'keyboard') {
			const keyboard_type = widget_data.kb_type;
			const input_content = widget_data.value;
			const display_options = JSON.parse(widget_data.display_options || '{}');

			// deserialize keyboard display options
			if (display_options.char_limit) {
				display_options.char_limit = parseInt(display_options.char_limit);
			}
			if (display_options.per_char_limit) {
				try {
					display_options.per_char_limit = JSON.parse(display_options.per_char_limit);
				} catch(err) {
					display_options.per_char_limit = {};
				}
			}

			resolved_widgets[widget_key] = new EqKeyboard(input_content, { type: keyboard_type, name: widget_key, input_key_index: 0, display_options: display_options });
		} else if (widget_data.type == 'multi-choice') {
			resolved_widgets[widget_key] = new MC({choices: widget_data.choices}, {});
		} else if (widget_data.type == 'multi_select') {
			const dataset: { label: string, key: string }[] = JSON.parse(widget_data.dataset);
			resolved_widgets[widget_key] = new MultiSelect(dataset, {});
		} else if (widget_data.type == 'multi_input') {
			const dataset: { label: string, key: string }[] = JSON.parse(widget_data.dataset);
			resolved_widgets[widget_key] = new MultiInput(dataset, {});
		} else if (widget_data.type == 'dropdown') {
			const dataset: { label: string, value: string }[] = JSON.parse(widget_data.dataset);
			const display_options = JSON.parse(widget_data.display_options || '{}');
			resolved_widgets[widget_key] = new DropDown(dataset, {display_options: display_options});
		} else if (widget_data.type == 'drag-source') {
			const dataset = JSON.parse(widget_data.dataset || "{}");
			const display_options = JSON.parse(widget_data.display_options || '{}');
			resolved_widgets[widget_key] = new DragSource(dataset, display_options);
		} else if (widget_data.type == 'drag-sort') {
			const dataset = JSON.parse(widget_data.dataset || "{}");
			const display_options = JSON.parse(widget_data.display_options || '{}');
			resolved_widgets[widget_key] = new DragSort(dataset, display_options);
		} else if (widget_data.type == 'dropzone') { // TODO: display options
			const source_key = widget_data.source_key;
			const display_options = JSON.parse(widget_data.display_options || '{}');
			resolved_widgets[widget_key] = new DropZone(source_key, {});
		} else if (widget_data.type == 'drop-bucket') {
			const source_key = widget_data.source_key;
			const max_quantity = widget_data.max_quantity;
			const display_options = JSON.parse(widget_data.display_options || '{}');
			resolved_widgets[widget_key] = new DropBucket(source_key, max_quantity, { display_options: display_options });
		} else if (widget_data.type == 'drop-list') {
			const source_key = widget_data.source_key;
			const max_quantity = widget_data.max_quantity;
			const display_options = JSON.parse(widget_data.display_options || '{}');
			resolved_widgets[widget_key] = new DropList(source_key, max_quantity, { display_options: display_options });
		} else if (widget_data.type == 'vertical-math') {
			const tree = tokenize_and_parse(widget_data.value).tree;
			const display_options = JSON.parse(widget_data.display_options || '{}');
			resolved_widgets[widget_key] = new VerticalMath(tree, display_options);
		} else if (widget_data.type == 'graph') {
			// NOTE: graph inputs are rendered after question template markup setup
			const series_data = JSON.parse(widget_data.series || '[]');
			const display_options = JSON.parse(widget_data.display_options || '{}');
			const input_options = JSON.parse(widget_data.input_options || '{}');
			resolved_widgets[widget_key] = new Graph(series_data, display_options, input_options);
		} else if (widget_data.type == 'format_selector') {
			// deserialize nested format widgets
			const format_widget_keys = JSON.parse(widget_data.formats);
			const format_widgets = [];
			for (let i = 0; i < format_widget_keys.length; i++) {
				const format_widget_key = format_widget_keys[i];

				// deserialize format_widget if not yet deserialized
				if (!resolved_widgets[format_widget_key]) {
					QEHelper.deserializeInputWidget(resolved_widgets, format_widget_key, user_input_configs, input_widget_configs);
				}
				format_widgets.push(resolved_widgets[format_widget_key]);
			}
			resolved_widgets[widget_key] = new FormatSelector(format_widget_keys, format_widgets);
		} else if (widget_data.type == 'widget_list') {
			// deserialize nested sub widgets
			const sub_widget_keys = JSON.parse(widget_data.sub_widget_keys);
			const sub_widgets = [];
			for (let i = 0; i < sub_widget_keys.length; i++) {
				const sub_widget_key = sub_widget_keys[i];

				// deserialize sub_widget if not yet deserialized
				if (!resolved_widgets[sub_widget_key]) {
					QEHelper.deserializeInputWidget(resolved_widgets, sub_widget_key, user_input_configs, input_widget_configs);
				}
				sub_widgets.push(resolved_widgets[sub_widget_key]);
			}
			const active_index = widget_data.active_index;
			resolved_widgets[widget_key] = new WidgetList(sub_widget_keys, sub_widgets, active_index);
		} else if (widget_data.type == 'decimal_grid') {
			const display_options = JSON.parse(widget_data.display_options || '{}');
			resolved_widgets[widget_key] = new DecimalGrid(widget_data.value, display_options);
		} else if (widget_data.type == 'fraction_set') {
			const display_options = JSON.parse(widget_data.display_options || '{}');
			resolved_widgets[widget_key] = new FractionSet(widget_data.value, widget_data.wanted_equal_groups, widget_data.num_equal_groups, display_options);
		} else if (widget_data.type == 'fraction_shape') {
			const display_options = JSON.parse(widget_data.display_options || '{}');
			resolved_widgets[widget_key] = new FractionShape(widget_data.value, display_options);
		} else {
			log.log('ERROR. Unknown widget type: ', widget_data);
		}

		if (resolved_widgets[widget_key]) resolved_widgets[widget_key].name = widget_key;
	}
}

export function numberToDecimal(number){
	if (typeof number == 'undefined') {
		return number;
	} else if (typeof number == 'string') {
		number = parseFloat(number);
	}

	number = number.toFixed(12);
	number = number.replace(/\.?0*$/, '');
	number = parseFloat(number);
	return number;
}

// shuffle helper function - NOT in-place shuffle
// - prevents shuffle from producing the original array by checking shuffled order and performing forced element swap when same order returned
//    - this uses an intermediate index-array to make shuffled order detection easy
export function shuffle(arr) {
	if (arr.length < 2) return arr.slice(0);

	// shuffling array of index numbers to make it easy to check if original order
	let indexes = arr.map((x, i)=>{ return i; });
	let counter = indexes.length, idx, temp;
	while (counter > 0) {
		idx = Math.floor(Math.random() * counter);
		counter--;

		temp = indexes[counter];
		indexes[counter] = indexes[idx];
		indexes[idx] = temp;
	}

	// check if indexes are in original order
	let ordered = true;
	for (let i = 0; i < indexes.length - 1; i++) {
		if (indexes[i + 1] < indexes[i]) ordered = false;
	}
	if (ordered || indexes.length <= 3) { // if list has 2 or 3 items, always shuffle
		// pick two indexes and swap them
		let idx1_pos = Math.floor(Math.random() * indexes.length);
		let idx1_val = indexes[idx1_pos];
		indexes.splice(idx1_pos, 1); // remove idx1 from the index list to prevent picking it again
		let idx2_pos = Math.floor(Math.random() * indexes.length);
		let idx2_val = indexes[idx2_pos];

		// insert idx1 back in list at its original location
		indexes.splice(idx1_pos, 0, idx1_val);

		// increment idx2_pos if it's >= idx1_pos since we just inserted an item back in vefore it
		if (idx2_pos >= idx1_pos) idx2_pos++;

		// swap values
		indexes[idx1_pos] = idx2_val;
		indexes[idx2_pos] = idx1_val;
	}

	// build shuffled array
	let shuffled_arr = indexes.map((idx)=>{ return arr[idx]; });
	return shuffled_arr;
}
