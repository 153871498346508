import { QETerm } from '../QETerm';
import { QEHelper, shuffle } from '../QEHelper';
import { QEWidget, DisplayOptions } from '../QEWidget';
import * as jQuery from 'jquery';

export class QEWidgetDragSort extends QEWidget {
	display_options: { [key: string]: any };
	dataset: { display: string, value: string }[];
	user_value: string[];
	name: string;

	// DragSort is used to present the user with a collection of draggable items
	constructor(dataset: { display: string, value: string }[], display_options?: DisplayOptions = {}) {
		super();

		this.dataset = dataset;
		this.user_value = dataset.map(function(x){ return x.value; });
		this.display_options = display_options;
	}

	/**
	* Instantiates and returns widget from serialized data
	* @param {string} serialized - serialized string containing value and display config
	* @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	* @param {Object} [options]
	*/
	static instantiate(serialized, resolved_data, options?) {
		const deserialized = JSON.parse(serialized);

		// each dataset item should have a value (string) and optional display (placeholder reference?) field
		const dataset = QEHelper.resolveDataset(serialized, resolved_data, options);
		if (!dataset) return null; // unresolved dependency, or resolution error

		// cull duplicates
		let dataset_values = [];
		dataset = dataset.filter(function(item){
			if (dataset_values.indexOf(item.value) != -1) return false;
			dataset_values.push(item.value);
			return true;
		});

		// shuffle values
		if (deserialized.shuffle) {
			dataset = shuffle(dataset);
		}

		// build map and resolve any [$name] placeholders in display_options
		const display_options = QEHelper.resolveOptionsString(deserialized.display_options, resolved_data);

		// check if there was an unresolved dependency
		if (!display_options) return null;

		let widget = new QEWidgetDragSort(dataset, display_options);
		return widget;
	}

	/**
	* Returns serialized tree
	* @returns {string} Serialized value string
	*/
	serialize_to_text(options: { [key: string]: string | boolean } = {}): string {
		return JSON.stringify(this.dataset);
	}

	/**
	* Returns widget markup for inclusion in question output
	* @param {Object} options
	* @returns {string} Generated display markup
	*/
	display(options = {}) {
		var display_options = Object.assign({}, this.display_options, options);

		let dataset = this.dataset;
		if (display_options.value && display_options.value.type == "json" && display_options.value.value instanceof Array) {
			dataset = display_options.value.value.map(function(x){ return { value: x, display: x }; });
		}

		let container_classes = display_options.container_classes || "";
		let container_styles = "";
		let item_styles = "";
		let ml = '';

		if (display_options.container_styles) {
			container_styles = ' style="' + display_options.container_styles + '"';
		}

		if (display_options.item_styles) {
			item_styles = ' style="' + display_options.item_styles + '"';
		}

		ml += '<div class="drag-sorts ' + container_classes +'"' + container_styles +'>';

		for (let i = 0; i < dataset.length; i++) {
			// insert delimeter string (e.g. " > ") between items
			if (i && display_options.delimiter) ml += '<span>'+ display_options.delimiter +'</span>';

			let item = dataset[i];
			let display = item.display;
			if (display === undefined || !display.length) {
				display = item.value;
			}
			ml += '<div class="drag-sort" '+ item_styles + ' data-index="'+ i +'" value="'+ item.value +'">'+ display +'</div>';
		}
		ml += '</div>';

		return ml;
	}

	bindEventHandlers(widget_container) {
		// DragSort input events are bound by the calling code since they may involve interactions between multiple widgets
		return;
	}

	setInputValue(value: string[]) {
		this.user_value = value;
	}

	getInputValue(input_widgets?): string[] { return this.user_value; }

	isUserInputComplete(): boolean {
		return true;
	}

	serialize_to_text(): string {
		return JSON.stringify(this.user_value);
	}

	exportValue(options?){
		return {
			type: 'drag-sort',
			name: this.name,
			dataset: JSON.stringify(this.dataset),
			display_options: JSON.stringify(this.display_options || {}),
		};
	}
}

