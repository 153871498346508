import * as doT from 'dot';
import { tokenize_and_parse } from '../common/QEGrammar';
import { QEQ } from '../common/QE';
import { log } from '../common/QE';
import { QEHelper, numberToDecimal, QEValue, QEValueTree, QEValueString, QEValueJSON, QEValueMap, QEValueBoolean, QEValueWidget, QEValueAnswer } from '../common/QEHelper';
import { QEValConstants } from '../common/QEValConstants';
import { QESolver } from 'QESolver';
import { Eq, ImgSet, Tally, StringLookup, FormatSelector, WidgetList, DecimalGrid, PlaceBlocks } from '../common/QEWidget';
import { QEWidgetMC as MC } from '../common/Widget/QEWidgetMC';
import { QEWidgetMultiInput as MultiInput } from '../common/Widget/MultiInput';
import { QEWidgetMultiSelect as MultiSelect } from '../common/Widget/MultiSelect';
import { QEWidgetDropDown as DropDown } from '../common/Widget/DropDown';
import { QEWidgetDragSource as DragSource } from '../common/Widget/DragSource';
import { QEWidgetDragSort as DragSort } from '../common/Widget/DragSort';
import { QEWidgetDropZone as DropZone } from '../common/Widget/DropZone';
import { QEWidgetDropBucket as DropBucket } from '../common/Widget/DropBucket';
import { QEWidgetDropList as DropList } from '../common/Widget/DropList';
import { QEWidgetVerticalMath as VerticalMath } from '../common/Widget/VerticalMath';
import { QEWidgetQuad as Quad } from '../common/Widget/Quad';
import { QEWidgetPolygon as Polygon } from '../common/Widget/Polygon';
import { QEWidgetFractionSet as FractionSet } from '../common/Widget/FractionSet';
import { QEWidgetFractionShape as FractionShape } from '../common/Widget/FractionShape';
import { QEWidgetAnalogClock as AnalogClock } from '../common/Widget/AnalogClock';
import { QEWidgetPseudo3d as Pseudo3d } from '../common/Widget/Pseudo3d';
import { QEWidgetThermometer as Thermometer } from '../common/Widget/Thermometer';
import { QEWidgetSpinner as Spinner } from '../common/Widget/Spinner';
import { QEWidgetDice as Dice } from '../common/Widget/Dice';
import { QEWidgetCoin as Coin } from '../common/Widget/Coin';
import { QEWidgetEmoji as Emoji } from '../common/Widget/Emoji';
import { QEWidgetBarGraph as BarGraph } from '../common/Widget/Plot/BarGraph';
import { QEWidgetCircleGraph as CircleGraph } from '../common/Widget/Plot/CircleGraph';
import { QEWidgetDotPlot as DotPlot } from '../common/Widget/Plot/DotPlot';
import { QEWidgetHistogram as Histogram } from '../common/Widget/Plot/Histogram';
import { QEWidgetLineGraph as LineGraph } from '../common/Widget/Plot/LineGraph';
import { QEWidgetPictograph as Pictograph } from '../common/Widget/Plot/Pictograph';
import { QEWidgetTable as Table } from '../common/Widget/Table';
import { QEEqKeyboard as EqKeyboard } from 'QEEqKeyboard'; // aliased in webpack.config.js
import { QEWidgetGraph as Graph } from 'QEWidgetGraph'; // aliased in webpack.config.js

interface ParamConfig {
	name: string;
	type: "Tree" | "Tree List" | "Integer Range" | "Decimal Range" | "Fraction Range" |
		"Integer" | "Number" | "Number List" | "String" | "String List" |
		"Built-in String List" | "Integer List" | "Decimal List" | "JSON" | "Array" |
		"Sequence" | "JsonListFromStringList" | "TreeListFromTreeList | DotJS";
	value?: string;
	values?: string;
	exclude_vals?: string;
	min?: string;
	max?: string;
	len?: string;
	min_num?: string;
	max_num?: string;
	min_den?: string;
	max_den?: string;
	proper?: boolean;
	improper?: boolean;
	reduce?: boolean;
	negative_parens?: boolean;
	places?: string;
	join?: string;
	canonicalize?: boolean;
	unique_vals?: boolean;
	keep_trailing?: boolean;
}

interface WidgetConfig {
	name: string;
	type: string;
	serialized: string;
}

interface AnswerConfig {
	name: string;
	solution_key: string;
	target: string;
	options: string;
}

interface UserInputConfig {
	widget_key: string,
}

interface EvalConfig {
	user_value: string,
	answer_value: string,
	compare_method?: string,
	compare_method_json_fields?: string,
	canonicalization_steps?: string,
}

interface AnswerValidationConfig {
	input_configs: UserInputConfig[],
	eval_configs: EvalConfig[],
	answer_template: string,
	solution_answer_key: string,
	solution_display_options: string,
	solution_template: string,
}

//interface UIConfig {
//	template_markdown:
//}

interface QuestionConfig {
	id: number,
	name_key: string,
	question_type: string,
	input_type: string,
	video_key: string,
//	applications: any,
//	ui_config: any,
	style_sm: string,
	style_xs: string,
	title: string,
	instruction: string,
	template: string,
	params: ParamConfig[],
	widgets: WidgetConfig[],
	answers: AnswerConfig[],
	answer_validation: AnswerValidationConfig,
}

interface ResolvedData {
	question_type: string,
	input_type: string,
	video_key: string,
	style_sm: string,
	style_xs: string,
	title: string,
	instruction: string,
	template: string,
	resolved: {
		[key: string]: QEValue;
	},
	regen_data: {},
	input_configs: UserInputConfig[],
}

export class QEValGen {

	constructor() { }

	// TODO: update QEValGen.parseOptionsString in practice_editor.js to use QEHelper.parseOptionsString, then deprecate here
	static parseOptionsString(options_str: string = "", resolved_data: ResolvedData) {
		return QEHelper.parseOptionsString(options_str, resolved_data);
	}
	/**
	 * Iterates over parameter config data to resolve parameter values and populate resolved_data.resolved with instantiated parameter values
	 * @param {Object[]} params
	 * @param {string} params[].type
	 * @param {string} params[].name
	 * @param {string} [params[].min]
	 * @param {string} [params[].max]
	 * @param {string} [params[].value]
	 * @param {string} [params[].values]
	 * @param {string} [params[].exclude_vals]
	 * @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	 * @param {Object} resolved_data.resolved
	 */
	static resolveParameterValues(params: ParamConfig[], resolved_data: ResolvedData, options: {[key: string]: any} = {}): string[] {
		let unresolved = [];

		// checks whether a serialized value contains unresolved placeholders
		function findUnresolved(param_key: string, value: string): boolean {
			if (typeof value == 'undefined')
				return false;

			if (value.match(/\[\$[^\]]+\]/g)) {
				let dependencies = value.match(/\[\$[^\]]+\]/g).map(function (x) {
					// strip specifiers
					x = x.replace(/\[\$([^\]]+)\]/, function (str, m1) { return m1; });
					x = x.replace(/\..*$/, '');
					return x;
				});
				for (let i = 0; i < dependencies.length; i++) {
					if (resolved_data.resolved[dependencies[i]] === undefined) {
						unresolved.push(param_key);
						return true;
					}
				}
			}
			return false;
		}
		// helper to check a list of serialized values for unresolved placeholders
		function findAllUnresolved(param_key: string, values: string[]): boolean {
			for (let i = 0; i < values.length; i++) {
				if (findUnresolved(param_key, values[i])) {
					return true;
				}
			}
			return false;
		}

		function valGenerateWithExclude(genFunc: Function, exclude_val_str: string, options: { delimiter?: string } = {}) {
			// genFunc() is passed a serialized list of resolved excluded values. It can use this list to filter a generated list of possible values to avoid retry-and-reject -based exclusion
			if (typeof exclude_val_str == 'undefined' || !exclude_val_str.length)
				return genFunc([]);

			// number of times to retry generating a value, if the generated value is found in a list of excluded values
			const max_exclude_retries: number = 100;
			let num_exclude_retries: number = max_exclude_retries

			// TODO: if exclude_val_str references a list{...}, we need to extract the list values

			let resolved_exclude_val_str: QEValueString = QEHelper.resolvePlaceholderToString(exclude_val_str, resolved_data);

			// build map of excluded values
			let exclude_vals = {};
			if (resolved_exclude_val_str !== null) {
				let exclude_val_list = resolved_exclude_val_str.value.split(options.delimiter || ',');
				for (let ev_idx = 0; ev_idx < exclude_val_list.length; ev_idx++) {
					exclude_vals[exclude_val_list[ev_idx].trim()] = 1;
				}
			}

			// generate value. Retry if present in map of excluded values. (num_excluded_vals / num_possible_vals)^20 chance of failing to find a non-excluded value. Feeling lucky?
			let value;
			do {
				num_exclude_retries--;
				value = genFunc(Object.keys(exclude_vals));
			} while (exclude_vals[value] && num_exclude_retries > 0);

			if (exclude_vals[value]) {
				log.error('Error: ' + max_exclude_retries + ' attempts to generate a value not found in exclude_val list "' + exclude_val_str + '", but failed. Using last generated value.');
			}

			return value;
		}

		// recurse through JSON structure and for each map value check for unresolved references and deserialize if all references resolved
		function resolveMapItem(param_key: string, container, obj){
			for (let field_name in obj) {
				let obj_value = obj[field_name];

				if (obj_value instanceof Array) {
					container[field_name] = [];

					let unresolved = resolveListItem(param_key, container[field_name], obj_value);
					if (unresolved)
						return unresolved;
				} else if (obj_value instanceof Object) {
					container[field_name] = {};

					let unresolved = resolveMapItem(param_key, container[field_name], obj_value);
					if (unresolved)
						return unresolved;
				} else if (typeof obj_value == "number") {
					container[field_name] = obj_value;
				} else {
					if (typeof obj_value != "string")
						obj_value = obj_value +""; // cast to string

					if (findUnresolved(param_key, obj_value))
						return true; // unresolved

					let values_str: QEValueString = QEHelper.resolvePlaceholderToString(obj_value, resolved_data);

					// check if there was an error
					if (values_str === null)
						return true; // unresolved

					if (!Number.isNaN(Number(values_str.value))) {
						container[field_name] = Number(values_str.value);
					} else {
						container[field_name] = values_str.value;
					}
				}
			}
			return false;
		}
		function resolveListItem(param_key: string, container, list){
			for (let i = 0; i < list.length; i++) {
				let list_item = list[i];
				if (list_item instanceof Array) {
					let child_list = [];

					let unresolved = resolveListItem(param_key, child_list, list_item);
					if (unresolved)
						return unresolved;

					container.push(child_list);
				} else if (list_item instanceof Object) {
					let child_obj = {};

					let unresolved = resolveMapItem(param_key, child_obj, list_item);
					if (unresolved)
						return unresolved;

					container.push(child_obj);
				} else if (typeof list_item == "number") {
					container.push(list_item);
				} else {
					if (typeof list_item != "string")
						list_item = list_item +""; // cast to string

					if (findUnresolved(param_key, list_item))
						return true; // unresolved

					let values_str: QEValueString = QEHelper.resolvePlaceholderToString(list_item, resolved_data);

					// check if there was an error
					if (values_str === null)
						return true; // unresolved

					// if value is numeric, cast to number
					if (!Number.isNaN(Number(values_str.value))) {
						container.push(Number(values_str.value));
					} else {
						container.push(values_str.value);
					}
				}
			}
			return false;
		}

		////////////////////////////////////////////////////////////
		// resolution functions for each param.type
		function resolveParamOfTypeTree(param_key: string, value: string, canonicalize: boolean): QEValueTree {
			if (findUnresolved(param_key, value))
				return undefined;

			// replace all resolved parameter placeholders
			let resolved: QEValueTree = QEHelper.resolvePlaceholderToTree(value, resolved_data, {param_key: param_key});
			if (resolved === null)
				return null;

			if (canonicalize) {
				// run "canonicalize" Solution on tree to put in standardized form
				let canonicalized = QESolver.solveUsing('canonicalize', resolved, {});
				return canonicalized.value;
			}
			return resolved;
		}
		function resolveParamOfTypeTreeList(param_key: string, values: string, min: string, max: string, exclude_vals: string, canonicalize: boolean): QEValueTree {
			if (findAllUnresolved(param_key, [values, min, max, exclude_vals]))
					return undefined;

			// replace all resolved parameter placeholders. Should resolve to a string.
			let resolved_list = QEHelper.resolvePlaceholderToString(values, resolved_data, {param_key: param_key, param_field: 'values'});
			if (resolved_list === null)
				return null;

			// split values into list
			let list = resolved_list.value.split(/ *; *?/);

			// MIN INDEX
			let min_index = 0;
			if (min) {
				let min_resolved = QEHelper.resolvePlaceholderToTree(min, resolved_data, {param_key: param_key, param_field: 'min'});
				if (min_resolved === null) return null;
				min_index = Math.trunc(min_resolved.value.evaluate_to_float());
				min_index = Math.max(min_index, 0); // cap min_index
			}

			// MAX INDEX
			let max_index = list.length - 1;
			if (max) {
				let max_resolved = QEHelper.resolvePlaceholderToTree(max, resolved_data, {param_key: param_key, param_field: 'max'});
				if (max_resolved === null) return null;
				max_index = Math.trunc(max_resolved.value.evaluate_to_float());
				max_index = Math.min(max_index, list.length - 1); // cap max_index
			}

			let value = valGenerateWithExclude(function() { return list[min_index + Math.trunc(Math.random() * (max_index + 1 - min_index))]; }, exclude_vals, { delimiter: ';' });
			let resolved: QEValueTree = QEHelper.resolvePlaceholderToTree(value, resolved_data, {param_key: param_key, param_field: 'value'});

			if (canonicalize) {
				// run "canonicalize" Solution on tree to put in standardized form
				let canonicalized = QESolver.solveUsing('canonicalize', resolved, {});
				return canonicalized.value;
			}

			return resolved;
		}
		function resolveParamOfTypeIntegerRange(param_key: string, min_str: string, max_str: string, exclude_vals: string): QEValueTree {
//			if (findAllUnresolved(param_key, [min_str, max_str, exclude_vals]))
//				return undefined;

			// MIN
			let min_resolved = QEHelper.resolvePlaceholderToTree(min_str, resolved_data, {param_key: param_key, param_field: 'min'});
			if (min_resolved === null) {
				log.warn('Unresolved parameter dependency', {param_key: param_key, param_field: 'min', msg_type: 'unresolved'});
				return null;
			}
			let min = Math.trunc(min_resolved.value.evaluate_to_float());

			// MAX
			let max_resolved = QEHelper.resolvePlaceholderToTree(max_str, resolved_data, {param_key: param_key, param_field: 'max'});
			if (max_resolved === null) {
				log.warn('Unresolved parameter dependency', {param_key: param_key, param_field: 'max', msg_type: 'unresolved'});
				return null;
			}
			let max = Math.trunc(max_resolved.value.evaluate_to_float());

			// resolve range
			let value;
			if (min > max) {
				log.warn("Error: invalid Integer Range: param_key, min_str, max_str.", {param_key: param_key, min_str: min_str, max_str: max_str, min: min, max: max});
				return null;
			} else if (max - min < 1000) {
				// range size limited, expand to array and splice out exclude vals
				let integer_list = [];
				for (let i = min; i <= max; i++) {
					integer_list.push(i);
				}

				let resolved_exclude_val_str: QEValueString = QEHelper.resolvePlaceholderToString(exclude_vals, resolved_data, {param_key: param_key, param_field: 'exclude_list'});
				if (resolved_exclude_val_str === null) {
					log.warn('Unresolved parameter dependency', {param_key: param_key, param_field: 'exclude_vals', msg_type: 'unresolved'});
					return null;
				}

				// splice out excluded values
				if (resolved_exclude_val_str !== null) {
					let exclude_val_list = resolved_exclude_val_str.value.split(/,/);
					for (let ev_idx = 0; ev_idx < exclude_val_list.length; ev_idx++) {
						let idx = integer_list.indexOf(parseInt(exclude_val_list[ev_idx]));
						if (idx != -1) {
							integer_list.splice(idx, 1);
						}
					}
				}

				// TODO: check that remaining list is not empty

				value = integer_list[Math.trunc(Math.random() * integer_list.length)];
			} else {
				value = valGenerateWithExclude(function () { return Math.round(min + (max - min) * Math.random()); }, exclude_vals, {});
			}

			return QEHelper.resolvePlaceholderToTree(value, resolved_data, {param_key: param_key, param_field: 'value'});
		}
		function resolveParamOfTypeDecimalRange(param_key: string, min_str: string, max_str: string, places_str: string, exclude_vals: string, keep_trailing: boolean = false): QEValueTree {
			// as Integer Range, but instead of parseInt, parseFloat and set toFixed with the specified number of decimal places

			if (findAllUnresolved(param_key, [min_str, max_str, places_str, exclude_vals]))
				return undefined;

			// MIN
			let min_resolved = QEHelper.resolvePlaceholderToTree(min_str, resolved_data, {param_key: param_key, param_field: 'min'});
			if (min_resolved === null) return null;
			let min = numberToDecimal(min_resolved.value.evaluate_to_float());

			// MAX
			let max_resolved = QEHelper.resolvePlaceholderToTree(max_str, resolved_data, {param_key: param_key, param_field: 'max'});
			if (max_resolved === null) return null;
			let max = numberToDecimal(max_resolved.value.evaluate_to_float());

			// cap length to place values
			let places = QEHelper.resolvePlaceholderToTree(places_str, resolved_data, {param_key: param_key, param_field: 'places'}).value.serialize_to_text();

			if (min > max) {
				log.warn("Error: invalid Decimal Range: param_key, min_str, max_str, places_str.", param_key, min_str, max_str, places_str);
			}

			// resolve range
			let value = valGenerateWithExclude(function () {
				let val = min + (max - min) * Math.random();

				// cap length to place values
				val = val.toFixed(places);

				if (!keep_trailing) {
					// strip trailiing zeroes
					val = parseFloat(val).toString();
				}
				return val;
			}, exclude_vals, {});

			return QEHelper.resolvePlaceholderToTree(value, resolved_data, {param_key: param_key, param_field: 'value'});
		}
		function resolveParamOfTypeFractionRange(param_key: string,
			min_num_str: string, max_num_str: string, min_den_str: string, max_den_str: string,
			proper: boolean, improper: boolean, reduce: boolean, negative_parens: boolean,
			exclude_vals: string
		): QEValueTree {
			if (findAllUnresolved(param_key, [min_num_str, max_num_str, min_den_str, max_den_str, exclude_vals]))
				return undefined;

			// allow negative numerator
			// MIN NUM
			let min_num_resolved = QEHelper.resolvePlaceholderToTree(min_num_str, resolved_data, {param_key: param_key, param_field: 'min_num'});
			if (min_num_resolved === null) return null;
			let min_num = Math.trunc(min_num_resolved.value.evaluate_to_float());

			// MAX NUM
			let max_num_resolved = QEHelper.resolvePlaceholderToTree(max_num_str, resolved_data, {param_key: param_key, param_field: 'max_num'});
			if (max_num_resolved === null) return null;
			let max_num = Math.trunc(max_num_resolved.value.evaluate_to_float());

			// force denominator to be positive, minimum 1
			// MIN DEN
			let min_den_resolved = QEHelper.resolvePlaceholderToTree(min_den_str, resolved_data, {param_key: param_key, param_field: 'min_den'});
			if (min_den_resolved === null) return null;
			let min_den = Math.max(1, Math.abs(Math.trunc(min_den_resolved.value.evaluate_to_float())));

			// MAX DEN
			let max_den_resolved = QEHelper.resolvePlaceholderToTree(max_den_str, resolved_data, {param_key: param_key, param_field: 'max_den'});
			if (max_den_resolved === null) return null;
			let max_den = Math.max(1, Math.abs(Math.trunc(max_den_resolved.value.evaluate_to_float())));

			let value = valGenerateWithExclude(function () {
				// generate num & den, using proper/improper flags to constrain ratio
				// NOTE: I'm constraining num/den of proper/improper fractions by first resolving one of the values,
				//	and then using that as an additional constraint on the range of the other.
				//	This can result in the other value's bounds range being unintentionally expanded.
				let num, den;
				if (proper) { // e.g. 3/4
					// generate num first, then use abs as lower bound for den range
					num = Math.round(min_num + (max_num - min_num) * Math.random());

					let temp_min_den = Math.max(Math.abs(num) + 1, min_den);
					let temp_max_den = Math.max(Math.abs(num) + 1, max_den);
					den = Math.round(temp_min_den + (temp_max_den - temp_min_den) * Math.random());
				} else if (improper) { // e.g. 4/3
					// generate den first, then use as lower bound for abs num range
					den = Math.round(min_den + (max_den - min_den) * Math.random());

					if (min_num >= 0) {
						// if min_num is positive, then interval is continuous
						let temp_min_num = Math.max(den + 1, min_num);
						let temp_max_num = Math.max(den + 1, max_num);
						num = Math.round(temp_min_num + (temp_max_num - temp_min_num) * Math.random());
					} else if (max_num < 0) {
						// if max_num is negative, then interval is continuous
						let temp_min_num = Math.min(-den - 1, min_num);
						let temp_max_num = Math.min(-den - 1, max_num);
						num = Math.round(temp_min_num + (temp_max_num - temp_min_num) * Math.random());
					} else {
						// split interval, generate numerators for both positive and negative intervals
						//	then randomly select between them, weighted by interval ranges
						let neg_min_num = Math.min(-den - 1, min_num);
						let neg_max_num = Math.min(-den - 1, max_num);
						let neg_range = neg_max_num - neg_min_num;
						let neg_num = Math.round(neg_min_num + neg_range * Math.random());

						let pos_min_num = Math.max(den + 1, min_num);
						let pos_max_num = Math.max(den + 1, max_num);
						let pos_range = pos_max_num - pos_min_num;
						let pos_num = Math.round(pos_min_num + pos_range * Math.random());

						num = Math.random() < (neg_range + 1) / (neg_range + pos_range + 2) ? neg_num : pos_num;
					}
				} else {
					num = Math.round(min_num + (max_num - min_num) * Math.random());
					den = Math.round(min_den + (max_den - min_den) * Math.random());
				}

				// reduce to lowest terms
				if (reduce) {
					let q = new QEQ(num, den);
					num = q.num;
					den = q.den;
				}

				let frac;
				if (num < 0) {
					frac = '-\\frac{' + Math.abs(num) + ',' + den + '}';
				} else {
					frac = '\\frac{' + num + ',' + den + '}';
				}

				// negative_parens
				if (negative_parens && num < 0) {
					frac = '(' + frac + ')';
				}

				return frac;
			}, exclude_vals, { delimiter: ';' });

			return QEHelper.resolvePlaceholderToTree(value, resolved_data, {param_key: param_key, param_field: 'value'});
		}
		function resolveParamOfTypeInteger(param_key: string, value_str: string): QEValueTree {
			if (findUnresolved(param_key, value_str))
				return undefined;

			// replace all resolved parameter placeholders
			let resolved = QEHelper.resolvePlaceholderToTree(value_str, resolved_data, {param_key: param_key, param_field: 'value_str'});
			if (resolved === null) return null;

			// should return an integer; evaluate to simplify
			let value = resolved.value;
			let int_value = Math.trunc(value.evaluate_to_float());

			return QEHelper.resolvePlaceholderToTree(int_value.toString(), resolved_data, {param_key: param_key, param_field: 'value'});
		}
		function resolveParamOfTypeNumber(param_key: string, value_str: string): QEValueTree {
			if (findUnresolved(param_key, value_str))
				return undefined;

			// replace all resolved parameter placeholders
			let resolved = QEHelper.resolvePlaceholderToTree(value_str, resolved_data, {param_key: param_key, param_field: 'value_str'});
			if (resolved === null) {
				log.warn('Unresolved parameter dependency', {param_key: param_key, param_field: 'value_str'});
				return null;
			}

			// should return a Numeric type, therefore evaluate to simplify
			let value = resolved.value;

			// if the value is a simple number, don't execute evaluate_to_float() since it strips trailing zeroes. Simply keep the string value
			if (value.children[0].type == "RATIONAL") {
				value = value.children[0].value;
			} else {
				value = numberToDecimal(value.evaluate_to_float());
			}

			return QEHelper.resolvePlaceholderToTree(value, resolved_data, {param_key: param_key, param_field: 'value'});
		}
		function resolveParamOfTypeNumberList(param_key: string, values: string, min_str: string, max_str: string, exclude_vals: string): QEValueTree {
			if (findAllUnresolved(param_key, [values, min_str, max_str, exclude_vals]))
				return undefined;

			// replace all resolved parameter placeholders. Should resolve to a string.
			let resolved = QEHelper.resolvePlaceholderToString(values, resolved_data, {param_key: param_key, param_field: 'values_list'});
			if (resolved === null)
				return null;

			// split values into list
			let list = resolved.value.split(/ *, */);

			// MIN INDEX
			let min_index = 0;
			if (min_str) {
				let min_resolved = QEHelper.resolvePlaceholderToTree(min_str, resolved_data, {param_key: param_key, param_field: 'min_index'});
				if (min_resolved === null) return null;
				min_index = Math.trunc(min_resolved.value.evaluate_to_float());
				min_index = Math.max(min_index, 0); // cap min_index
			}

			// MAX INDEX
			let max_index = list.length - 1;
			if (max_str) {
				let max_resolved = QEHelper.resolvePlaceholderToTree(max_str, resolved_data, {param_key: param_key, param_field: 'max_index'});
				if (max_resolved === null) return null;
				max_index = Math.trunc(max_resolved.value.evaluate_to_float());
				max_index = Math.min(max_index, list.length - 1); // cap max_index
			}

			let value = valGenerateWithExclude(function () { return list[min_index + Math.trunc(Math.random() * (max_index + 1 - min_index))]; }, exclude_vals, {});
			value = numberToDecimal(value);

			return QEHelper.resolvePlaceholderToTree(value, resolved_data, {param_key: param_key, param_field: 'value'});
		}
		function resolveParamOfTypeString(param_key: string, value: string): QEValueString {
			if (findUnresolved(param_key, value))
				return undefined;

			// replace all resolved parameter placeholders. Should resolve to a string.
			return QEHelper.resolvePlaceholderToString(value, resolved_data, {param_key: param_key, param_field: 'value'});
		}
		function resolveParamOfTypeStringList(param_key: string, values: string, min_str: string, max_str: string, exclude_vals: string): QEValueString {
			if (findAllUnresolved(param_key, [values, min_str, max_str, exclude_vals]))
				return undefined;

			// replace all resolved parameter placeholders. Should resolve to a string.
			let resolved = QEHelper.resolvePlaceholderToString(values, resolved_data, {param_key: param_key, param_field: 'values_list'});
			if (resolved === null) return null;

			// split values into list
			let list = resolved.value.split(/ *, */);

			// MIN INDEX
			let min_index = 0;
			if (min_str) {
				let min_resolved = QEHelper.resolvePlaceholderToTree(min_str, resolved_data, {param_key: param_key, param_field: 'min_index'});
				if (min_resolved === null) return null;
				min_index = Math.trunc(min_resolved.value.evaluate_to_float());
				min_index = Math.max(min_index, 0); // cap min_index
			}

			// MAX INDEX
			let max_index = list.length - 1;
			if (max_str) {
				let max_resolved = QEHelper.resolvePlaceholderToTree(max_str, resolved_data, {param_key: param_key, param_field: 'max_index'});
				if (max_resolved === null) return null;
				max_index = Math.trunc(max_resolved.value.evaluate_to_float());
				max_index = Math.min(max_index, list.length - 1); // cap max_index
			}
			let value = valGenerateWithExclude(function () { return list[min_index + Math.trunc(Math.random() * (max_index + 1 - min_index))]; }, exclude_vals, {});

			// replace all resolved parameter placeholders. Should resolve to a string.
			return QEHelper.resolvePlaceholderToString(value, resolved_data, {param_key: param_key, param_field: 'value'});
		}
		function resolveParamOfTypeBuiltinStringList(param_key: string, value_str: string, exclude_vals: string): QEValueString {
			if (findAllUnresolved(param_key, [value_str, exclude_vals]))
				return undefined;

			let key_resolved = QEHelper.resolvePlaceholderToString(value_str, resolved_data, {param_key: param_key, param_field: 'value_str'});
			if (key_resolved === null) return null;

			// fetch specified list, then handle as String List
			let list = QEValConstants.string_lists[key_resolved.value];
			if (!list)
				return undefined;

			let value = valGenerateWithExclude(function () { return list[Math.trunc(Math.random() * list.length)]; }, exclude_vals, {});

			return new QEValueString({ value: value });
		}
		function resolveParamOfTypeIntegerList(param_key: string, min_str: string, max_str: string, len_str: string, exclude_vals: string, unique_vals: boolean = false): QEValueTree {
			if (findAllUnresolved(param_key, [min_str, max_str, len_str, exclude_vals]))
				return undefined;

			// MIN
			let min_resolved = QEHelper.resolvePlaceholderToTree(min_str, resolved_data, {param_key: param_key, param_field: 'min'});
			if (min_resolved === null) return null;
			let min = Math.trunc(min_resolved.value.evaluate_to_float());

			// MAX
			let max_resolved = QEHelper.resolvePlaceholderToTree(max_str, resolved_data, {param_key: param_key, param_field: 'max'});
			if (max_resolved === null) return null;
			let max = Math.trunc(max_resolved.value.evaluate_to_float());

			// LENGTH
			let len_resolved = QEHelper.resolvePlaceholderToTree(len_str, resolved_data, {param_key: param_key, param_field: 'length'});
			let len = Math.trunc(len_resolved.value.evaluate_to_float());

			if (min > max) {
				log.warn("Error: invalid Integer List range: param_key, min_str, max_str.", param_key, min_str, max_str);
			}

			// build list
			let list = [];
			for (let loop_idx = 0; loop_idx < len; loop_idx++) {
				// resolve range
				const generated_value = valGenerateWithExclude(function () { return Math.round(min + (max - min) * Math.random()); }, exclude_vals, {});
				list.push(generated_value);
				if (unique_vals) {
					// if unique_vals flag set, add the generated value to the exclude_vals list so we can keep the list values unique
					if (exclude_vals.length) exclude_vals += ',';
					exclude_vals += generated_value;
				}
			}

			let list_str = 'list{' + list.join(',') + '}';
			return QEHelper.resolvePlaceholderToTree(list_str, resolved_data, {param_key: param_key, param_field: 'list_str'});
		}
		function resolveParamOfTypeDecimalList(param_key: string, min_str: string, max_str: string, len_str: string, places_str: string, exclude_vals: string, unique_vals: boolean = false, keep_trailing: boolean = false): QEValueTree {
			// as Integer Range, but instead of parseInt, parseFloat and set toFixed with the specified number of decimal places
			if (findAllUnresolved(param_key, [min_str, max_str, len_str, places_str, exclude_vals]))
				return undefined;

			// MIN
			let min_resolved = QEHelper.resolvePlaceholderToTree(min_str, resolved_data, {param_key: param_key, param_field: 'min'});
			if (min_resolved === null) return null;
			let min = numberToDecimal(min_resolved.value.evaluate_to_float());

			// MAX
			let max_resolved = QEHelper.resolvePlaceholderToTree(max_str, resolved_data, {param_key: param_key, param_field: 'max'});
			if (max_resolved === null) return null;
			let max = numberToDecimal(max_resolved.value.evaluate_to_float());

			// LENGTH
			let len_resolved = QEHelper.resolvePlaceholderToTree(len_str, resolved_data, {param_key: param_key, param_field: 'length'});
			if (len_resolved === null) return null;
			let len = Math.trunc(len_resolved.value.evaluate_to_float());

			// places
			let places_resolved = QEHelper.resolvePlaceholderToTree(places_str, resolved_data, {param_key: param_key, param_field: 'places'});
			if (places_resolved === null) return null;
			let places = Math.trunc(places_resolved.value.evaluate_to_float());

			if (min > max) {
				log.warn("Error: invalid Decimal List range: param_key, min_str, max_str.", param_key, min_str, max_str);
			}

			// build list
			let list = [];
			for (let loop_idx = 0; loop_idx < len; loop_idx++) {
				// resolve range
				const generated_value = valGenerateWithExclude(function() {
					let val = min + (max - min) * Math.random();

					// cap length to place values
					val = val.toFixed(places);

					if (!keep_trailing) {
						// strip trailiing zeroes
						val = parseFloat(val).toString();
					}
					return val;
				}, exclude_vals, {});

				list.push(generated_value);

				if (unique_vals) {
					// if unique_vals flag set, add the generated value to the exclude_vals list so we can keep the list values unique
					if (exclude_vals.length) exclude_vals += ',';
					exclude_vals += generated_value;
				}
			}

			let list_str = 'list{' + list.join(',') + '}';
			return QEHelper.resolvePlaceholderToTree(list_str, resolved_data, {param_key: param_key, param_field: 'list_str'});
		}
		function resolveParamOfTypeJSON(param_key: string, value_str: string): QEValueJSON {
			// resolve any [$refs] to string, then attempt json parse
			if (findAllUnresolved(param_key, [value_str]))
				return undefined;

			// replace all resolved parameter placeholders. Should resolve to a string.
			value_str = QEHelper.resolvePlaceholderToString(value_str, resolved_data, {param_key: param_key, param_field: 'value_str'}).serialize_to_text();

			// first JSON parse, then try to resolve each field value
			let deserialized;
			try {
				deserialized = JSON.parse(value_str);
			} catch (err) {
				log.warn('PARSE ERROR: ', value_str);
				return undefined;
			}

			// now iterate over each entry in values, and for each map value resolve
			let has_unresolved = false;
			let value = {};

			// support JSON arrays and JSON object maps
			if (deserialized instanceof Array) {
				value = [];
				has_unresolved = resolveListItem(param_key, value, deserialized);
			} else {
				has_unresolved = resolveMapItem(param_key, value, deserialized);
			}

			if (has_unresolved)
				return undefined;

			return new QEValueJSON({ value: value });
		}
		function resolveParamOfTypeArray(param_key: string, value_str: string, len_str: string, join_str: string): QEValueString {
			if (findAllUnresolved(param_key, [value_str, len_str, join_str]))
				return undefined;

			let len = 0;
			if (len_str) {
				let len_resolved = QEHelper.resolvePlaceholderToTree(len_str, resolved_data, {param_key: param_key, param_field: 'len_str'});
				if (len_resolved === null) return null;
				len = Math.trunc(len_resolved.value.evaluate_to_float());
			}

			let join = '';
			if (join_str) {
				let resolved: QEValueString = QEHelper.resolvePlaceholderToString(join_str, resolved_data, {param_key: param_key, param_field: 'join_str'});
				if (resolved !== null)
					join = resolved.value;
			}

			let fill = '';
			if (value_str) {
				let resolved: QEValueString = QEHelper.resolvePlaceholderToString(value_str, resolved_data, {param_key: param_key, param_field: 'fill_str'});
				if (resolved !== null)
					fill = resolved.value;
			}

			let arr = new Array(len).fill(fill).join(join);

			return new QEValueString({ value: arr });
		}
		function resolveParamOfTypeSequence(param_key: string, value_str: string, len_str: string, start_index_str: string): QEValueString {
			// resolve value_str to tree, replace variable "x" with index value
			if (findAllUnresolved(param_key, [value_str, len_str, start_index_str]))
				return undefined;

			// replace all resolved parameter placeholders
			let resolved_func: QEValueTree = QEHelper.resolvePlaceholderToTree(value_str, resolved_data, {param_key: param_key});
			if (resolved_func === null) return null;

			let len = 1;
			if (len_str) {
				let len_resolved = QEHelper.resolvePlaceholderToTree(len_str, resolved_data, {param_key: param_key, param_field: 'len'});
				if (len_resolved === null) return null;
				len = Math.trunc(len_resolved.value.evaluate_to_float());
				len = Math.max(len, 1); // cap len
			}

			let start_index = 0;
			if (start_index_str) {
				let start_index_resolved = QEHelper.resolvePlaceholderToTree(start_index_str, resolved_data, {param_key: param_key, param_field: 'start_index'});
				if (start_index_resolved === null) return null;
				start_index = Math.trunc(start_index_resolved.value.evaluate_to_float());
				start_index = Math.max(start_index, 0); // cap start_index
			}

			// substitute var for each index and build list{}
			let list_vals = [];
			for (let i = start_index; i < start_index + len; i++){
				let substituted;
				try {
					substituted = QESolver.solveUsing('substitute_variable_values', {
						type: 'map',
						value: { 'src': resolved_func, "x": QEHelper.resolvePlaceholderToTree(i.toString(), {}, {}) }
					}, {});
				} catch (err){
					log.warn('Error calling substitute_variable_values solver on: ', param_key, JSON.stringify({type:'map', value:{'src':resolved_func.serialize_to_text(),'x':i}}));
					substituted = null;
				}
				if (substituted === null) return null;

				// NOTE: substitute_variable_values substitutes and then applies simplify_bedmas steps, but does NOT evaluate to float, so we need to do so here
				let numeric_val = substituted.value.to_num();
				if (!Number.isNaN(numeric_val)) list_vals.push(numeric_val);
				else list_vals.push(substituted.value.serialize_to_text());
			}
			const list_str = '\\list{'+ list_vals.join(',') +'}';
			const list_tree = tokenize_and_parse(list_str, {});
			if (list_tree.tree == null) {
				log.warn('Error: failed to parse list_str as Tree', param_key, list_str);
				return null;
			}
			return new QEValueTree({ value: list_tree.tree });
		}
		function resolveParamOfTypeJsonListFromStringList(param_key: string, value_str: string, len_str: string, exclude_vals: string, unique_vals: boolean = false): QEValueString {
			if (findAllUnresolved(param_key, [value_str, len_str, exclude_vals]))
				return undefined;

			// replace all resolved parameter placeholders. Should resolve to a string.
			let resolved_list = QEHelper.resolvePlaceholderToString(value_str, resolved_data, {param_key: param_key, param_field: 'value'});
			if (resolved_list === null) return null;

			// split values into list
			let list = resolved_list.value.split(/ *, *?/).map((x)=>{ return x.trim(); });

			let len = 1;
			if (len_str) {
				let len_resolved = QEHelper.resolvePlaceholderToTree(len_str, resolved_data, {param_key: param_key, param_field: 'len'});
				if (len_resolved === null) return null;
				len = Math.trunc(len_resolved.value.evaluate_to_float());
				len = Math.max(len, 1); // cap len
			}

			// if unique_vals true and len > string list length return unresolved error
			if (unique_vals && len > list.length) {
				log.warn('Error: unique_vals true and len greater than list length', param_key);
				return null;
			}

			let new_list = [];
			for (let i = 0; i < len; i++){
				let value = valGenerateWithExclude(function() { return list[Math.trunc(Math.random() * list.length)]; }, exclude_vals, {});
				if (value === null) return null;

				if (unique_vals) {
					// remove the selected value from the list to prevent duplicates
					list = list.filter((x)=>{ return x != value; });
				}

				new_list.push(value);
			}
			return new QEValueJSON({ value: new_list });
		}
		function resolveParamOfTypeTreeListFromTreeList(param_key: string, value_str: string, len_str: string, exclude_vals: string, unique_vals: boolean = false): QEValueString {
			if (findAllUnresolved(param_key, [value_str, len_str, exclude_vals]))
				return undefined;

			// resolve value as a list{} tree
			let resolved_list = QEHelper.resolvePlaceholderToTree(value_str, resolved_data, {param_key: param_key, param_field: 'value'});
			if (resolved_list === null) return null;

			// verify resolved_list.value.children[0] is a list{}
			const tree = resolved_list.value;
			if (tree.children[0].value !== "list" || !tree.children[0].children.length) {
				log.warn('Error: TreeListFromTreeList value is non-list{} tree: ', param_key, tree.serialize_to_text());
				return null;
			}
			let list = [];
			for (let i = 0; i < tree.children[0].children.length; i++) {
				list.push(tree.children[0].children[i].serialize_to_text());
			}

			let len = 1;
			if (len_str) {
				let len_resolved = QEHelper.resolvePlaceholderToTree(len_str, resolved_data, {param_key: param_key, param_field: 'len'});
				if (len_resolved === null) return null;
				len = Math.trunc(len_resolved.value.evaluate_to_float());
				len = Math.max(len, 1); // cap len
			}

			// if unique_vals true and len > string list length return unresolved error
			if (unique_vals && len > list.length) {
				log.warn('Error: unique_vals true and len greater than list length', param_key);
				return null;
			}

			let new_list = [];

			// detect if exclude_vals has been comma-delimited
			let delimiter = ';';
			if (exclude_vals !== undefined && exclude_vals.match(/,/) && !exclude_vals.match(/[;{}]/)) {
				delimiter = ',';
				log.warn('Comma-delimited exclude_vals string found, but expecting semi-colon delimited. Please change to ;', param_key, exclude_vals);
			}

			for (let i = 0; i < len; i++){
				let value = valGenerateWithExclude(function() { return list[Math.trunc(Math.random() * list.length)]; }, exclude_vals, { delimiter: delimiter });
				if (value === null) return null;

				if (unique_vals) {
					// remove the selected value from the list to prevent duplicates
					list = list.filter((x)=>{ return x != value; });
				}

				new_list.push(value);
			}

			let list_str = 'list{' + new_list.join(',') + '}';
			return QEHelper.resolvePlaceholderToTree(list_str, resolved_data, {param_key: param_key, param_field: 'list_str'});
		}
		function resolveParamOfTypeDotJS(param_key: string, template: string, options: {[key: string]: any} = {}): QEValueString {
			if (findAllUnresolved(param_key, [template])) return undefined;

			// find dependencies -> needs to check for dotjs-style "it.param_name" dependencies
			let dep_regex = new RegExp(/(?<=[^0-9a-zA-Z])it\.([\w\-]+)/g); // look-behind of "it.*" to check it is preceded by a space or a dotjs opening tag
			let dependencies = {};
			let result;
			while (result = dep_regex.exec(template)){
				dependencies[result[1]] = 1;
			}
			let dep_keys = Object.keys(dependencies);
			for (let i = 0; i < dep_keys.length; i++) {
				if (resolved_data.resolved[dep_keys[i]] === undefined) {
					unresolved.push(param_key);
					return undefined;
				}
			}

			let output = QEHelper.populateTemplate(template, resolved_data, options);
			return new QEValueString({ value: output });
		}

		////////////////////////////////////////////////////////////
		// resolve QEValue based on param.type
		for (let param_idx = 0; param_idx < params.length; param_idx++) {
			let param = params[param_idx];
			let param_key = param.name;

			// check if this param has already been resolved
			if (resolved_data.resolved[param_key])
				continue;

			let resolved: QEValueTree | QEValueString | QEValueJSON;

			switch (param.type) {
				case "Tree":
					resolved = resolveParamOfTypeTree(param_key, param.value, param.canonicalize);
					break;
				case "Tree List":
					resolved = resolveParamOfTypeTreeList(param_key, param.values, param.min, param.max, param.exclude_vals, param.canonicalize);
					break;
				case "Integer Range":
					resolved = resolveParamOfTypeIntegerRange(param_key, param.min, param.max, param.exclude_vals);
					break;
				case "Decimal Range":
					resolved = resolveParamOfTypeDecimalRange(param_key, param.min, param.max, param.places, param.exclude_vals, param.keep_trailing);
					break;
				case "Fraction Range":
					resolved = resolveParamOfTypeFractionRange(param_key,
						param.min_num, param.max_num, param.min_den, param.max_den,
						param.proper, param.improper, param.reduce, param.negative_parens,
						param.exclude_vals);
					break;
				case "Integer":
					resolved = resolveParamOfTypeInteger(param_key, param.value);
					break;
				case "Number":
					resolved = resolveParamOfTypeNumber(param_key, param.value);
					break;
				case "Number List":
					resolved = resolveParamOfTypeNumberList(param_key, param.values, param.min, param.max, param.exclude_vals);
					break;
				case "String":
					resolved = resolveParamOfTypeString(param_key, param.value);
					break;
				case "String List":
					resolved = resolveParamOfTypeStringList(param_key, param.values, param.min, param.max, param.exclude_vals);
					break;
				case "Built-in String List":
					resolved = resolveParamOfTypeBuiltinStringList(param_key, param.value, param.exclude_vals);
					break;
				case "Integer List":
					resolved = resolveParamOfTypeIntegerList(param_key, param.min, param.max, param.len, param.exclude_vals, param.unique_vals);
					break;
				case "Decimal List":
					resolved = resolveParamOfTypeDecimalList(param_key, param.min, param.max, param.len, param.places, param.exclude_vals, param.unique_vals, param.keep_trailing);
					break;
				case "JSON":
					resolved = resolveParamOfTypeJSON(param_key, param.value);
					break;
				case "Array":
					resolved = resolveParamOfTypeArray(param_key, param.value, param.len, param.join);
					break;
				case "Sequence":
					resolved = resolveParamOfTypeSequence(param_key, param.value, param.len, param.start_index);
					break;
				case "JsonListFromStringList":
					resolved = resolveParamOfTypeJsonListFromStringList(param_key, param.value, param.len, param.exclude_vals, param.unique_vals);
					break;
				case "TreeListFromTreeList":
					resolved = resolveParamOfTypeTreeListFromTreeList(param_key, param.value, param.len, param.exclude_vals, param.unique_vals);
					break;
				case "DotJS":
					resolved = resolveParamOfTypeDotJS(param_key, param.value, options);
					break;

				default:
			}

			if (resolved) {
				resolved_data.resolved[param_key] = resolved;

				let track_data = {};
				track_data[param_key] = resolved.serialize_to_text();
				log.addTrackData(['params'], track_data);
			}
		}
		return unresolved;
	}
	/**
	 * Iterates over widget config data to resolve widget values and populate resolved_data.resolved with instantiated widget objects
	 * @param {Object[]} widgets
	 * @param {string} widgets[].type
	 * @param {string} widgets[].name
	 * @param {string} widgets[].serialized
	 * @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	 */
	static resolveWidgetValues(widgets: WidgetConfig[], resolved_data: ResolvedData) {
		// subtype keys from practice_editor: analog_clock, bar_graph, circle_graph, coin, decimal_grid, dice, dot_plot, dropdown, equation, format selector, fraction_set, fraction_shape, graph, histogram, image set, keyboard, line_graph, multi-choice, multi_input, multi_select, pictograph, place blocks, polygon, pseudo3d, quad, spinner, string lookup, table, tally, thermometer, widget_list
		// deserialize widgets
		for (let i = 0; i < widgets.length; i++) {
			let widget = widgets[i];
			let widget_key = widget.name;

			// skip if already resolved
			if (resolved_data.resolved[widget_key])
				continue;

			// NOTE: resolved_data dependency handling is performed by the individual instantiate() functions
			// parse based on type
			let resolved: QEValueWidget;
			let widget_obj;
			if (widget.type == 'equation') {
				widget_obj = Eq.instantiate(widget.serialized, resolved_data, {});
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "equation",
						value: widget_obj
					});

					// DEBUG: serialize trees so it's possible to see value without torment
					//				resolved.serialized = resolved.value.value.serialize_to_text();
				}
			} else if (widget.type == 'keyboard') {
				widget_obj = EqKeyboard.instantiate(widget.serialized, resolved_data, { name: widget_key });
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "keyboard",
						value: widget_obj
					});
				}
			} else if (widget.type == 'multi-choice') {
				// pass correct_index and choice index values, if provided in resolved_data.regen_data
				let regen_data = null;
				if (resolved_data.regen_data[widget_key])
					regen_data = resolved_data.regen_data[widget_key];

				widget_obj = MC.instantiate(widget.serialized, resolved_data, { regen_data: regen_data });
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "multi-choice",
						value: widget_obj
					});
				}
			} else if (widget.type == 'multi_input') {
				widget_obj = MultiInput.instantiate(widget.serialized, resolved_data, {});
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "multi_input",
						value: widget_obj
					});
				}
			} else if (widget.type == 'multi_select') {
				widget_obj = MultiSelect.instantiate(widget.serialized, resolved_data, {});
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "multi_select",
						value: widget_obj
					});
				}
			} else if (widget.type == 'dropdown') {
				widget_obj = DropDown.instantiate(widget.serialized, resolved_data, { name: widget_key });
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "dropdown",
						value: widget_obj
					});
				}
			} else if (widget.type == 'drag-source') {
				widget_obj = DragSource.instantiate(widget.serialized, resolved_data, { name: widget_key });
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "drag-source",
						value: widget_obj
					});
				}
			} else if (widget.type == 'drag-sort') {
				widget_obj = DragSort.instantiate(widget.serialized, resolved_data, { name: widget_key });
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "drag-sort",
						value: widget_obj
					});
				}
			} else if (widget.type == 'dropzone') {
				widget_obj = DropZone.instantiate(widget.serialized, resolved_data, { name: widget_key });
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "dropzone",
						value: widget_obj
					});
				}
			} else if (widget.type == 'drop-bucket') {
				widget_obj = DropBucket.instantiate(widget.serialized, resolved_data, { name: widget_key });
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "drop-bucket",
						value: widget_obj
					});
				}
			} else if (widget.type == 'drop-list') {
				widget_obj = DropList.instantiate(widget.serialized, resolved_data, { name: widget_key });
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "drop-list",
						value: widget_obj
					});
				}
			} else if (widget.type == 'vertical-math') {
				widget_obj = VerticalMath.instantiate(widget.serialized, resolved_data, { name: widget_key });
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "vertical-math",
						value: widget_obj
					});
				}
			} else if (widget.type == 'image set') {
				widget_obj = ImgSet.instantiate(widget.serialized, resolved_data, {});
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "image set",
						value: widget_obj
					});
				}
			} else if (widget.type == 'graph') {
				widget_obj = Graph.instantiate(widget.serialized, resolved_data, {});
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "graph",
						value: widget_obj
					});
				}
			} else if (widget.type == 'table') {
				let regen_data = null;
				if (resolved_data.regen_data[widget_key])
					regen_data = resolved_data.regen_data[widget_key];

				widget_obj = Table.instantiate(widget.serialized, resolved_data, { regen_data: regen_data });
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "table",
						value: widget_obj
					});
				}
			} else if (widget.type == 'string lookup') {
				widget_obj = StringLookup.instantiate(widget.serialized, resolved_data, {});
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "string lookup",
						value: widget_obj
					});
				}
			} else if (widget.type == 'tally') {
				widget_obj = Tally.instantiate(widget.serialized, resolved_data, {});
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "tally",
						value: widget_obj
					});
				}
			} else if (widget.type == 'decimal_grid') {
				widget_obj = DecimalGrid.instantiate(widget.serialized, resolved_data, {});
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "decimal_grid",
						value: widget_obj
					});
				}
			} else if (widget.type == 'place blocks') {
				widget_obj = PlaceBlocks.instantiate(widget.serialized, resolved_data, {});
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "place blocks",
						value: widget_obj
					});
				}
			} else if (widget.type == 'format selector') {
				widget_obj = FormatSelector.instantiate(widget.serialized, resolved_data, {});
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "format selector",
						value: widget_obj
					});
				}
			} else if (widget.type == 'widget_list') {
				let regen_data = null;
				if (resolved_data.regen_data[widget_key])
					regen_data = resolved_data.regen_data[widget_key];

				widget_obj = WidgetList.instantiate(widget.serialized, resolved_data, { regen_data: regen_data });
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "widget_list",
						value: widget_obj
					});
				}
			} else if (widget.type == 'quad') {
				widget_obj = Quad.instantiate(widget.serialized, resolved_data, {});
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "quad",
						value: widget_obj
					});
				}
			} else if (widget.type == 'polygon') {
				widget_obj = Polygon.instantiate(widget.serialized, resolved_data, {});
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "polygon",
						value: widget_obj
					});
				}
			} else if (widget.type == 'fraction_set') {
				widget_obj = FractionSet.instantiate(widget.serialized, resolved_data, {});
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "fraction_set",
						value: widget_obj
					});
				}
			} else if (widget.type == 'fraction_shape') {
				widget_obj = FractionShape.instantiate(widget.serialized, resolved_data, {});
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "fraction_shape",
						value: widget_obj
					});
				}
			} else if (widget.type == 'analog_clock') {
				widget_obj = AnalogClock.instantiate(widget.serialized, resolved_data, {});
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "analog_clock",
						value: widget_obj
					});
				}
			} else if (widget.type == 'pseudo3d') {
				widget_obj = Pseudo3d.instantiate(widget.serialized, resolved_data, {});
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "pseudo3d",
						value: widget_obj
					});
				}
			} else if (widget.type == 'thermometer') {
				widget_obj = Thermometer.instantiate(widget.serialized, resolved_data, {});
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "thermometer",
						value: widget_obj
					});
				}
			} else if (widget.type == 'spinner') {
				widget_obj = Spinner.instantiate(widget.serialized, resolved_data, {});
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "spinner",
						value: widget_obj
					});
				}
			} else if (widget.type == 'dice') {
				widget_obj = Dice.instantiate(widget.serialized, resolved_data, {});
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "dice",
						value: widget_obj
					});
				}
			} else if (widget.type == 'coin') {
				widget_obj = Coin.instantiate(widget.serialized, resolved_data, {});
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "coin",
						value: widget_obj
					});
				}
			} else if (widget.type == 'emoji') {
				widget_obj = Emoji.instantiate(widget.serialized, resolved_data, {});
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "emoji",
						value: widget_obj
					});
				}
			} else if (widget.type == 'bar_graph') {
				widget_obj = BarGraph.instantiate(widget.serialized, resolved_data, {});
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "bar_graph",
						value: widget_obj
					});
				}
			} else if (widget.type == 'circle_graph') {
				widget_obj = CircleGraph.instantiate(widget.serialized, resolved_data, {});
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "circle_graph",
						value: widget_obj
					});
				}
			} else if (widget.type == 'dot_plot') {
				widget_obj = DotPlot.instantiate(widget.serialized, resolved_data, {});
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "dot_plot",
						value: widget_obj
					});
				}
			} else if (widget.type == 'histogram') {
				widget_obj = Histogram.instantiate(widget.serialized, resolved_data, {});
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "histogram",
						value: widget_obj
					});
				}
			} else if (widget.type == 'line_graph') {
				widget_obj = LineGraph.instantiate(widget.serialized, resolved_data, {});
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "line_graph",
						value: widget_obj
					});
				}
			} else if (widget.type == 'pictograph') {
				widget_obj = Pictograph.instantiate(widget.serialized, resolved_data, {});
				if (widget_obj) {
					resolved = new QEValueWidget({
						subtype: "pictograph",
						value: widget_obj
					});
				}
			} else {
				log.warn('WARNING: unrecognized widget type. ', widget);
			}

			if (resolved) {
				resolved.name = widget_key;
				resolved_data.resolved[widget_key] = resolved;

			}
		}
	}
	/**
	 * Iterates over answer config data to resolve answer values and populate resolved_data.resolved with instantiated answer solution and value
	 * @param {Object[]} answers
	 * @param {string} answers[].name
	 * @param {string} answers[].target - serialized target value (usually refers to an Equation widget)
	 * @param {string} answers[].solution_key - name key for the QESolver to apply to the target value
	 * @param {string} answers[].options - serialized option data to pass to Solution
	 * @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	 */
	static resolveAnswerValues(answers: AnswerConfig[], resolved_data: ResolvedData) {
		// resolve field values based on field_config
		function resolveTargetVal(field_key, field_config) {
			let field_val;
			if (field_config.type == 'tree') {
				field_val = QEHelper.resolvePlaceholderToTree(field_key, resolved_data);

				if (!field_val) return null;

				// evaluation, type-checking and type-casting
				if (field_config.simplify) {
					// TODO: support format checking, e.g. list{}, frac{}

					let number_type = field_config.number_type;

					// if number_type specified, evaluate and cast to number_type
					if (number_type == 'decimal') {
						// should return a Numeric type, therefore evaluate to simplify
						let value = field_val.value;
						let dec_value = numberToDecimal(value.evaluate_to_float());
						field_val = QEHelper.resolvePlaceholderToTree(dec_value.toString(), resolved_data);
					} else if (number_type == 'integer') {
						// should return a Numeric type, therefore evaluate to simplify
						let value = field_val.value;
						let int_value = parseInt(value.evaluate_to_float());
						field_val = QEHelper.resolvePlaceholderToTree(int_value.toString(), resolved_data);
					} else {
						// just run bedmas simplification
						let simplified = QESolver.solveUsing('simplify_bedmas', field_val, {});
						if (!simplified) return null;

						field_val = simplified.value;
					}
				}
			} else if (field_config.type == 'string') {
				field_val = QEHelper.resolvePlaceholderToString(field_key, resolved_data);
			} else if (field_config.type == 'json') {
				field_val = QEHelper.resolvePlaceholderToJSON(field_key, resolved_data);
			} else if (field_config.type == 'widget') {
				field_val = QEHelper.resolvePlaceholderToRefs(field_key, resolved_data);
			} else {
				log.warn('Unsupported solver input_param type: ', field_key, field_config);
				return null;
			}

			if (!field_val) return null;

			return field_val;
		}

		// deserialize and solve answers
		for (let answer_idx = 0; answer_idx < answers.length; answer_idx++) {
			let answer = answers[answer_idx];
			let answer_key = answer.name;

			// skip if already resolved
			if (resolved_data.resolved[answer_key])
				continue;

			let answer_target = answer.target;
			const solution_key = answer.solution_key;
			const solution_config = QESolver.solvers[solution_key];

			// check for unresolved targets
			let missing_dependency = 0;

			// TODO: support parameter resolution here, including evaluation e.g. "[$start]+1"
			// - currently whichever Solver is specified is responsible for validating parameters and casting them to the required format, e.g. with parseInt(blah.serialize_to_text())

			// resolve the target value (or target map values)
			let target;
			if (solution_config.input_param.type == 'map') {
				target = { type: 'map', value: {} };

				// parse to JSON, then resolve each value
				let unresolved_target = QEHelper.parseOptionsString(answer_target, resolved_data);

				let target_keys = Object.keys(unresolved_target);
				for (let target_key_idx = 0; target_key_idx < target_keys.length; target_key_idx++) {
					const target_key = target_keys[target_key_idx];
					const input_param_field_config = solution_config.input_param.fields[target_key];

					// check if unneeded fields are being passed in, likely as a result of input_param field definition changing
					if (input_param_field_config === undefined) {
						log.log('Unneeded solution field "'+ target_key +'" passed in, likely as a result of input_param field definition changing. Skipping.');
						continue;
					}

					// resolve value based on solution_config.input_param config
					target.value[target_key] = resolveTargetVal(unresolved_target[target_key], input_param_field_config);
					if (!target.value[target_key]) {
						missing_dependency = 1;
						continue;
					}
				}
			} else {
				target = resolveTargetVal(answer_target, solution_config.input_param);
				if (!target) {
					missing_dependency = 1;
					continue;
				}
			}

			if (missing_dependency)
				continue;

			// deserialize and resolve solution_options
			let solution_options = QEHelper.resolveOptionsString(answer.options, resolved_data);

			// check if there was an unresolved dependency
			if (!solution_options)
				return null;

			let solution = QESolver.solveUsing(solution_key, target, solution_options);
			if (!solution) {
				// solver error
				return null;
			}

			resolved_data.resolved[answer_key] = new QEValueAnswer({
				value: solution,
				target: target,
				solution_key: answer.solution_key,
				target_key: answer.target,
			});

			// DEBUG: serialize trees so it's possible to see value without torment
			//		if (solution.type == 'tree') {
			//			resolved_data.resolved[answer_key].serialized = solution.value.serialize_to_text();
			//		}
		}
	}
	/**
	 * Resolves specified answer values and returns answer solution markup and value
	 * @param {Object} answer
	 * @param {string} answer.name
	 * @param {string} answers[].target - serialized target value (usually refers to an Equation widget)
	 * @param {string} answers[].solution_key - name key for the QESolver to apply to the target value
	 * @param {string} answers[].options - serialized option data to pass to Solution
	 * @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	 * @param {Object} [options] - display options to be passed through to Solution object
	 */
	static generateSolutionAnswer(answer_key, resolved_data, display_options) {
		// NOTE: similar to QEValGen.resolveAnswerValues but only resolves a single answer, and generates step-by-step solution
		display_options = display_options || {};

		let answer_ref_string = answer_key;
		if (!answer_ref_string.match(new RegExp('^\\[\\$[^\\]]*\\]$')))
			answer_ref_string = '[$' + answer_ref_string + ']';

		let answer_data = QEHelper.resolvePlaceholderToRefs(answer_ref_string, resolved_data);
		if (!(answer_data instanceof QEValueAnswer)) {
			log.warn('Error: solution_answer_key does not resolve to an answer value: ', answer_key);
			return null;
		}

		// deserialize and solve answer
		let solution = QESolver.solveUsing(answer_data.solution_key,
			answer_data.target,
			Object.assign({ highlight: 1, preserve_steps: 1 }, display_options)
		);

		// TODO: return solution map with error code instead
		if (!solution) {
			// unresolved - solver error
			return null;
		}

		return {
			solution_ml: QESolver.display(solution, display_options),
			value: solution,
		};
	}
	/**
	 * Resolves param/widget/answer references and returns instantiated param/widget/answer data.
	 * Contains ALL data relevant to display, not only the values immediately exportable to the client (which are filtered by exportGeneratedData)
	 * - this includes answer evaluation and presentation data
	 * @param {Object} question_config
	 * @param {string} src_data.question_type
	 * @param {string} src_data.input_type
	 * @param {string} src_data.video_key
	 * @param {Object} src_data.ui_config
	 * @param {string} src_data.style_sm
	 * @param {string} src_data.style_xs
	 * @param {string} src_data.title
	 * @param {string} src_data.instruction
	 * @param {string} src_data.template
	 * @param {Object[]} src_data.params
	 * @param {string} src_data.params[].name
	 * @param {string} src_data.params[].type
	 * @param {string} [src_data.params[].min]
	 * @param {string} [src_data.params[].max]
	 * @param {string} [src_data.params[].value]
	 * @param {string} [src_data.params[].values]
	 * @param {string} [src_data.params[].exclude_vals]
	 * @param {Object[]} src_data.widgets
	 * @param {string} src_data.widgets[].name
	 * @param {string} src_data.widgets[].type
	 * @param {string} src_data.widgets[].value
	 * @param {Object[]} src_data.answers
	 * @param {string} src_data.answers[].name
	 * @param {string} src_data.answers[].solution_key
	 * @param {string} src_data.answers[].solution_target
	 * @param {Object} [options]
	 * @param {Object} [options.resolved] map of resolved param|widget|answer names to objects containing resoved values
	 * @param {string} [options.resolved.name]
	 * @param {string} [options.resolved.type]
	 * @param {Object} [options.resolved.value]
	 */
	static generateDisplayData(question_config: QuestionConfig, options: any = {}) {
		// TODO: break "options" into separate resolved data and display options parameters
		options = options || {};

		// given src_data (params, widgets, answers), generate output data by deserializing and iteratively resolving values and dependencies
		// the resolved values are objects (Eq, MC, QESolver, etc., and must be re-serialized if being exported via API call
		let dest_data: ResolvedData = {
			question_type: question_config.question_type,
			input_type: question_config.input_type,
			video_key: question_config.video_key,
			applications: question_config.applications,
			ui_config: question_config.ui_config,
			style_sm: question_config.style_sm,
			style_xs: question_config.style_xs,
			title: question_config.title,
			instruction: question_config.instruction,
			template: question_config.template,
			resolved: {},
			regen_data: {},
			input_configs: [],
			eval_configs: [],
			answer_config: {
				answer_template: question_config.answer_validation.answer_template,
				solution_answer_key: question_config.answer_validation.solution_answer_key,
				solution_display_options: question_config.answer_validation.solution_display_options,
				solution_template: question_config.answer_validation.solution_template,
			},
		};

		let answer_validation = question_config.answer_validation;

		// answer_validation should be an array, and input_configs should be an array of corresponding inputs - cast to array if it's not already
		if (answer_validation.input_configs instanceof Array) {
			answer_validation.input_configs.forEach(function(input_config, i){
				dest_data.input_configs.push({
					widget_key: input_config.widget_key,
					options: input_config.options,
				});
			});

			// eval_configs
			(answer_validation.eval_configs || []).forEach(function(eval_config, i){
				dest_data.eval_configs.push({
					user_value: eval_config.user_value,
					answer_value: (eval_config.answer_value === undefined ? '' : eval_config.answer_value).trim(), // the source of the "correct" answer, evaluated as placeholder
					compare_method: eval_config.compare_method,
					compare_method_json_fields: eval_config.compare_method_json_fields,
					canonicalization_steps: eval_config.canonicalization_steps,
				});
			});
		} else {
			// legacy conversion - move name_key, widget_key, answer_value, canonicalization_steps into input_configs[] and eval_configs[]
			dest_data.input_configs.push({
				widget_key: answer_validation.widget_key,
			});
			dest_data.eval_configs.push({
				user_value: '[$input0]',
				answer_value: (answer_validation.answer_value === undefined ? '' : answer_validation.answer_value).trim(),
				compare_method: answer_validation.compare_method,
				compare_method_json_fields: answer_validation.compare_method_json_fields,
				canonicalization_steps: answer_validation.canonicalization_steps,
			});
		}

		// use already-instantiated widget values - allows question values to be regenerated with a given set of param values
		if (options.resolved) {
			for (let widget_key in options.resolved) {
				dest_data.resolved[widget_key] = options.resolved[widget_key];
			}
		}

		// use regen_data - allows widgets to be regenerated with pre-determined order. Used for MC widgets, to allow deterministic regeneration despite randomize_answer and shuffle_answers flags.
		if (options.regen_data) {
			for (let widget_key in options.regen_data) {
				dest_data.regen_data[widget_key] = options.regen_data[widget_key];
			}
		}

		// track unresolved params, widgets, answers
		let unresolved = { params: {}, widgets: {}, answers: {} };

		// go through each type of src_data value and identify any keys not present in dest_data
		let num_unresolved_params = 0;
		for (let i = 0; i < question_config.params.length; i++) {
			if (!dest_data.resolved[question_config.params[i].name]) {
				num_unresolved_params++;
				unresolved.params[question_config.params[i].name] = 1;
			}
		}

		let num_unresolved_widgets = 0;
		for (let i = 0; i < question_config.widgets.length; i++) {
			if (!dest_data.resolved[question_config.widgets[i].name]) {
				num_unresolved_widgets++;
				unresolved.widgets[question_config.widgets[i].name] = 1;
			}
		}

		let num_unresolved_answers = 0;
		for (let i = 0; i < question_config.answers.length; i++) {
			if (!dest_data.resolved[question_config.answers[i].name]) {
				num_unresolved_answers++;
				unresolved.answers[question_config.answers[i].name] = 1;
			}
		}

		// loop through resolution funcs for parameters, widgets, and answers until all resolved or no further resolution progress made
		let resolving = 1;
		while (resolving) {
			resolving = 0;

			if (num_unresolved_params) {
				QEValGen.resolveParameterValues(question_config.params, dest_data, options);

				// compare whether num_unresolved_params has changed
				let new_num_unresolved_params = 0;
				let new_unresolved_params = {};
				for (let i = 0; i < question_config.params.length; i++) {
					if (!dest_data.resolved[question_config.params[i].name]) {
						new_num_unresolved_params++;
						new_unresolved_params[question_config.params[i].name] = 1;
					}
				}
				if (new_num_unresolved_params < num_unresolved_params)
					resolving = 1;
				num_unresolved_params = new_num_unresolved_params;
				unresolved.params = new_unresolved_params;
			}

			if (num_unresolved_widgets) {
				QEValGen.resolveWidgetValues(question_config.widgets, dest_data);

				// compare whether num_unresolved_widgets has changed
				let new_num_unresolved_widgets = 0;
				let new_unresolved_widgets = {};
				for (let i = 0; i < question_config.widgets.length; i++) {
					if (!dest_data.resolved[question_config.widgets[i].name]) {
						new_num_unresolved_widgets++;
						new_unresolved_widgets[question_config.widgets[i].name] = 1;
					}
				}
				if (new_num_unresolved_widgets < num_unresolved_widgets)
					resolving = 1;
				num_unresolved_widgets = new_num_unresolved_widgets;
				unresolved.widgets = new_unresolved_widgets;
			}

			if (num_unresolved_answers) {
				QEValGen.resolveAnswerValues(question_config.answers, dest_data);

				// compare whether num_unresolved_answers has changed
				let new_num_unresolved_answers = 0;
				let new_unresolved_answers = {};
				for (let i = 0; i < question_config.answers.length; i++) {
					if (!dest_data.resolved[question_config.answers[i].name]) {
						new_num_unresolved_answers++;
						new_unresolved_answers[question_config.answers[i].name] = 1;
					}
				}
				if (new_num_unresolved_answers < num_unresolved_answers)
					resolving = 1;
				num_unresolved_answers = new_num_unresolved_answers;
				unresolved.answers = new_unresolved_answers;
			}
		}

		dest_data.template = QEHelper.populateTemplate(dest_data.template, dest_data, options);

		// report back unresolved keys - needed for tool highlighting
		dest_data.unresolved = unresolved;

		if (options.include_answers || (question_config.input_type == 'self-assessment')) {
			let answer_output = QEValGen.generateAnswerOutput(dest_data, options); // pass dotjs_templates
			dest_data.answer_output = answer_output;

			let solution_markup = QEValGen.generateSolutionOutput(dest_data, options); // pass dotjs_templates
			dest_data.answer_output.solution_markup = solution_markup;
		}

		return dest_data;
	}

	static regenerateQuestion(question_data, src_data, options: any = {}) {
		// instantiate params from src_data
		let dest_data = { resolved: {}, regen_data: {}, user_values: [], };

		// use param_type to determine type of regenerated parameter
		let param_type_outputs = {
			'Tree': 'tree',
			'Tree List': 'tree',
			'Integer Range': 'tree',
			'Integer List': 'tree',
			'Integer': 'tree', // not used in editor. Deprecate
			'Decimal Range': 'tree',
			'Decimal List': 'tree',
			'Fraction Range': 'tree',
			'Number': 'tree',
			'Number List': 'tree',
			'String': 'string',
			'String List': 'string',
			'Built-in String List': 'string',
			'JSON': 'json',
			'Array': 'string',
			'Sequence': 'tree',
			'JsonListFromStringList': 'json',
			'TreeListFromTreeList': 'tree',
			'DotJS': 'string',
		};

		// go through question_data keys and identify any keys present in src_data
		for (let i = 0; i < question_data.params.length; i++) {
			let param_key = question_data.params[i].name;
			let param_type = question_data.params[i].type;
			//log.log('param_key: ', param_key, src_data.src_params[param_key]);
			if (param_key in src_data.src_params) {
				// deserialize parameter value
				let deserialized;
				switch (param_type_outputs[param_type]) {
					case "string":
						deserialized = QEHelper.resolvePlaceholderToString(src_data.src_params[param_key], {});
						break;
					case "tree":
						deserialized = QEHelper.resolvePlaceholderToTree(src_data.src_params[param_key], {});
						break;
					case "json":
						try {
							deserialized = new QEValueJSON({ value: JSON.parse(src_data.src_params[param_key]) });
						} catch (err) {
							log.warn('PARSE ERROR: ', err);
						}
						break;
					default:
						deserialized = QEHelper.resolvePlaceholderToRefs(src_data.src_params[param_key], {});
						log.warn("Warning: deserialized unknown param type in regenerateQuestion: ", question_data.params[i]);

				}
				dest_data.resolved[param_key] = deserialized;
			}
		}

		// NOTE: src_widgets should only be needed to set correct_index of a MC widget ...
		//  ... but that setting needs to be performed when the widget is instantiated or immediately after, since other values may reference the MC correct choice, e.g. "mc1.correct_target"
		for (let i = 0; i < question_data.widgets.length; i++) {
			let widget_key = question_data.widgets[i].name;
			if (typeof src_data.src_widgets[widget_key] != 'undefined') {
				let widget_data = src_data.src_widgets[widget_key];

				if (widget_data.type == 'multi-choice' ||
					widget_data.type == 'widget_list'
				) {
					dest_data.regen_data[widget_key] = widget_data;
				}
			}
		}

		// deserialize user input values, if present
		if (src_data.user_values) {
			// shallow copy serialized user input values
			dest_data.user_values = src_data.user_values.slice(0);

			// iterate over input_configs and deserialize the corresponding user input value
			let input_configs = question_data.answer_validation.input_configs;

			for (let i = 0; i < input_configs.length; i++) {
				let user_input_value = dest_data.user_values[i];
				let widget_key = input_configs[i].widget_key;
				let name_key = 'input'+ i;

				// if question input_type is "self-assessment", there is no input widget is defined
				if (question_data.input_type == 'self-assessment') {
					dest_data.resolved[name_key] = user_input_value;
					continue;
				}

				// get the originating widget type to determine what QEValue type to deserialize user_input_value to
				let widget_type = question_data.widgets.filter(function(widget_cfg){ return widget_cfg.name == widget_key; })[0].type;

				// NOTE: values coming from the client should be serialized strings
				// re-instantiate user input to QEValue subclass, based on widget type of input widget
				let user_answer_value;

				// TODO: combine sets of widget types ['keyboard', 'equation', 'format selector'].indexOf(widget_type) != -1

				if (widget_type == 'keyboard') {
					const parsed = tokenize_and_parse(user_input_value, {});
					if (parsed.tree == null) {
						log.warn('Error: failed to parse user input as Tree');
						return;
					}
					user_answer_value = new QEValueTree({ value: parsed.tree });
				} else if (widget_type == 'vertical-math') {
					const parsed = tokenize_and_parse(user_input_value, {});
					if (parsed.tree == null) {
						log.warn('Error: failed to parse user input as Tree');
						return;
					}
					user_answer_value = new QEValueTree({ value: parsed.tree });
				} else if (widget_type == 'equation') {
					const parsed = tokenize_and_parse(user_input_value, {});
					if (parsed.tree == null) {
						log.warn('Error: failed to parse user input as Tree');
						return;
					}
					user_answer_value = new QEValueTree({ value: parsed.tree });
				} else if (widget_type == 'format selector') {
					const parsed = tokenize_and_parse(user_input_value, {});
					if (parsed.tree == null) {
						log.warn('Error: failed to parse user input as Tree');
						return;
					}
					user_answer_value = new QEValueTree({ value: parsed.tree });
				} else if (widget_type == 'widget_list') {
					user_answer_value = new QEValueString({ value: user_input_value });
				} else if (widget_type == 'fraction_shape') {
					user_answer_value = new QEValueString({ value: user_input_value });
				} else if (widget_type == 'multi-choice') {
					user_answer_value = new QEValueString({ value: user_input_value });
				} else if (widget_type == 'multi_input') {
					user_answer_value = new QEValueJSON({ value: JSON.parse(user_input_value) });
				} else if (widget_type == 'multi_select') {
					user_answer_value = new QEValueJSON({ value: JSON.parse(user_input_value) });
				} else if (widget_type == 'dropdown') {
					user_answer_value = new QEValueString({ value: user_input_value });
				} else if (widget_type == 'drag-sort') {
					user_answer_value = new QEValueJSON({ value: user_input_value });
				} else if (widget_type == 'dropzone') {
					user_answer_value = new QEValueString({ value: user_input_value });
				} else if (widget_type == 'drop-bucket') {
					user_answer_value = new QEValueJSON({ value: user_input_value });
				} else if (widget_type == 'drop-list') {
					user_answer_value = new QEValueJSON({ value: user_input_value });
				} else if (widget_type == 'graph') {
					user_answer_value = new QEValueJSON({ value: JSON.parse(user_input_value) });
				} else if (widget_type == 'decimal_grid') {
					const parsed = tokenize_and_parse(user_input_value, {});
					if (parsed.tree == null) {
						log.warn('Error: failed to parse user input as Tree');
						return;
					}
					user_answer_value = new QEValueTree({ value: parsed.tree });
				} else if (widget_type == 'table') {
					user_answer_value = new QEValueJSON({ value: JSON.parse(user_input_value) });
				} else {
					log.warn('Error: unhandled input widget subtype: ', widget_type);
					return;
				}

				dest_data.resolved[name_key] = user_answer_value;
			}
		}

		// regenerate question values from src_data
		let display_data = QEValGen.generateDisplayData(question_data, Object.assign({}, dest_data, options));
		return display_data;
	}
	static generateExportData(question_data, extra_export_data, generation_options) {
		// generate question values
		let display_data = QEValGen.generateDisplayData(question_data, generation_options);
		return QEValGen.exportGeneratedData(question_data, display_data, extra_export_data);
	}
	static exportGeneratedData(question_data: any, display_data: any, extra_export_data: any = {}) {
		// exportGeneratedData: export input widgets (needed by client for user input), and src_data (values needed to regenerate question)
		// NOTE: the ONLY non-deterministic values should be params, the MC widget (with randomize_answer flag set), and WidgetList (which randomizes displayed widget).
		//    These values must be exported in src_data for question regeneration.

		// exported input_widgets are based on input_configs, but related input_widgets should be exported for the following cases:
		// - an exported keyboard/dropdown/dropzone that is nested in an Equation should result in that Equation being exported
		// - an exported Equation with nested keyboard/dropdown/dropzone nodes should result in the nested input(s) being exported
		// - an exported Table or Widget List with nested keyboard/dropdown/dropzone references should result in the nested input(s) being exported

		// serialize display data to the same format returned by practice API
		let export_data = {
			question_type: question_data.question_type,
			input_type: question_data.input_type,
			video_key: question_data.video_key,
			applications: question_data.applications,
			ui_config: question_data.ui_config,
			style_sm: question_data.style_sm,
			style_xs: question_data.style_xs,
			title: question_data.title,
			instruction: display_data.instruction,
			template: display_data.template,
			answer_output: display_data.answer_output, // only present if QEValGen.generateDisplayData called with include_answers flag

			// only include public values: { name_key, widget_key }
			input_configs: display_data.input_configs.map(function (x, i) {
				let input_widget_key = x.widget_key || '';
				let input_widget = display_data.resolved[input_widget_key];
				return {
					name_key: x.name_key || 'input'+ i,
					widget_key: x.widget_key,
					widget_type: (input_widget || {}).subtype,
					options: x.options,
				};
			}),
			input_widgets: {},
			display_widgets: {},
			src_data: undefined,
		};

		// export widgets needed for user input
		for (let i = 0; i < display_data.input_configs.length; i++) {
			let user_input = display_data.input_configs[i];
			let input_widget_key = user_input.widget_key;
			let input_widget = display_data.resolved[input_widget_key];
			if (!input_widget) {
				continue;
			}
			export_data.input_widgets[input_widget_key] = QEHelper.exportValue(input_widget);

			// if user_input is a nestable input widget, then check if it is referenced by any resolved Eq widgets, and if so, export the referencing Eq.
			// - needed for re-rendering parent eq on kb input update
			if (input_widget.value instanceof EqKeyboard || input_widget.value instanceof DropDown || input_widget.value instanceof DropZone) {
				for (let item_key in display_data.resolved) {
					if (!display_data.resolved[item_key])
						continue;
					let item = display_data.resolved[item_key];
					if (item.value instanceof Eq) {
						// if Eq references kb/dropdown/dropzone input, then export Eq
						let input_node = item.value.value.findAllChildren('type', 'INPUT').filter(function (x) { return x.value == '[?' + input_widget_key + ']'; });
						if (input_node.length)
							export_data.input_widgets[item_key] = QEHelper.exportValue(display_data.resolved[item_key]);
					}
				}
			} else if (input_widget.value instanceof Eq) {
				// if the input widget is an Eq it should contain one or more kb/dropdown widget references
				let included_input_keys = [];
				input_widget.value.value.findAllChildren('type', 'INPUT').forEach(function (node) {
					let input_key = node.value.match(/\[\?(.*)\]/);
					if (input_key && included_input_keys.indexOf(input_key[1]) == -1) {
						included_input_keys.push(input_key[1]);

						// add referenced input widget to exported widgets
						export_data.input_widgets[input_key[1]] = QEHelper.exportValue(display_data.resolved[input_key[1]]);
					}
				});
			} else if (input_widget.value instanceof FormatSelector) {
				// export included widgets
				let included_input_keys = [];
				input_widget.value.format_widget_keys.forEach(function (format_widget_key) {
					let format_widget = display_data.resolved[format_widget_key];
					if (!format_widget) {
						return;
					}
					export_data.input_widgets[format_widget_key] = QEHelper.exportValue(format_widget);

					// if the included widget is an Eq it should contain one or more kb widget references
					if (format_widget.value instanceof Eq) {
						format_widget.value.value.findAllChildren('type', 'INPUT').forEach(function (node) {
							let input_key = node.value.match(/\[\?(.*)\]/);
							if (input_key && included_input_keys.indexOf(input_key[1]) == -1) {
								included_input_keys.push(input_key[1]);

								// add referenced input widget to exported widgets
								export_data.input_widgets[input_key[1]] = QEHelper.exportValue(display_data.resolved[input_key[1]]);
							}
						});
					}
				});
			} else if (input_widget.value instanceof WidgetList) {
				// export included widgets
				let included_input_keys = [];
				input_widget.value.widget_keys.forEach(function (sub_widget_key) {
					let sub_widget = display_data.resolved[sub_widget_key];
					if (!sub_widget) {
						return;
					}
					export_data.input_widgets[sub_widget_key] = QEHelper.exportValue(sub_widget);

					// if the included widget is an Eq it should contain one or more kb widget references
					if (sub_widget.value instanceof Eq) {
						sub_widget.value.value.findAllChildren('type', 'INPUT').forEach(function (node) {
							let input_key = node.value.match(/\[\?(.*)\]/);
							if (input_key && included_input_keys.indexOf(input_key[1]) == -1) {
								included_input_keys.push(input_key[1]);

								// add referenced input widget to exported widgets
								export_data.input_widgets[input_key[1]] = QEHelper.exportValue(display_data.resolved[input_key[1]]);
							}
						});
					}
				});
			} else if (input_widget.value instanceof Table) {
				// if the input widget is a Table it should contain one or more kb widget references
				let included_input_keys = [];
				let rows = input_widget.value.values.rows;
				for (let j = 0; j < rows.length; j++) {
					let row = rows[j];
					for (let k = 0; k < row.length; k++) {
						let cell = row[k];
						if (cell.value instanceof QEValueWidget && (cell.value.value instanceof EqKeyboard || cell.value.value instanceof DropDown || cell.value.value instanceof DropZone)) {
							let input_key = cell.value.value.name;
							if (input_key && included_input_keys.indexOf(input_key) == -1) {
								included_input_keys.push(input_key);

								// add referenced input widget to exported widgets
								export_data.input_widgets[input_key] = QEHelper.exportValue(display_data.resolved[input_key]);
							}
						}
					}
				}
			}
		}

		// export widgets where data needed for client-side rendering
		for (let i = 0; i < question_data.widgets.length; i++) {
			let widget_key = question_data.widgets[i].name;
			let widget = display_data.resolved[widget_key];

			if (widget && widget.subtype == 'graph') {
				// TODO: display_widgets is being ignored by the client. Is it still necessary? Graph is now either a static svg, or an input widget if being used for input
				export_data.display_widgets[widget_key] = QEHelper.exportValue(widget);
			} else if (widget && widget.subtype == 'drag-source') {
				export_data.input_widgets[widget_key] = QEHelper.exportValue(widget);
			} else if (widget && widget.subtype == 'drag-sort') {
				export_data.input_widgets[widget_key] = QEHelper.exportValue(widget);
			}
		}

		let src_params = {};
		for (let i = 0; i < question_data.params.length; i++) {
			let param_key = question_data.params[i].name;
			let param_type = question_data.params[i].type;

			// TODO: exclude param types that don't rely on any random aspect
			//		if (param_type == 'Built-in Map') continue;
			if (!display_data.resolved[param_key]) {
				log.warn('Warning: cannot export unresolved parameter: "' + param_key + '"', question_data.params[i]);
			} else {
				src_params[param_key] = QEHelper.exportValue(display_data.resolved[param_key]);
			}
		}

		// export MC widgets - needed for subsequent question regeneration
		let src_widgets = {};
		for (let i = 0; i < question_data.widgets.length; i++) {
			let widget_key = question_data.widgets[i].name;
			let widget = display_data.resolved[widget_key];

			if (!widget) {
				continue; // skip unresolved
			}

			if (widget.subtype == 'multi-choice') {
				src_widgets[widget_key] = QEHelper.exportValue(widget, { allow_private: true });
			} else if (widget.subtype == 'widget_list') {
				src_widgets[widget_key] = QEHelper.exportValue(widget, {});
			}
		}

		let src_data = {
			name_key: question_data.name_key,
			question_id: question_data.question_id,
			src_params: src_params,
			src_widgets: src_widgets,
			extra_export_data: undefined,
		};

		// include extra passed data - e.g. studyplan_id
		if (extra_export_data) {
			src_data.extra_export_data = JSON.stringify(extra_export_data);
		}

		let data_string = JSON.stringify(src_data);
		export_data.src_data = data_string;

		return export_data;
	}
	/**
	 * Generates output markup and associated widget export data for the expected answer
	 */
	static generateAnswerOutput(display_data, options: any = {}) {
		let answer_config = display_data.answer_config;
		let answer_markup = '';

		// get eval_configs and attempt to produce answer_value(s) that can be shown in editor tools
		let answer_values = [];
		let eval_configs = display_data.eval_configs;
		eval_configs.forEach((eval_config)=>{
			// TODO: export answer_values for other compare_methods
			if (eval_config.compare_method == 'json_exact') {
			} else if (eval_config.compare_method == 'json_fields') {
			} else if (eval_config.compare_method == 'dotjs_template') {
			} else if (eval_config.compare_method == 'self-assessment') {
			} else { // serialized
				// resolve answer_value
				let answer_ref_string = (eval_config.answer_value === undefined ? '' : eval_config.answer_value).trim();
				if (!answer_ref_string.length) {
					log.warn('WARNING: no answer_value set');
					return;
				}

				let answer_value_data = QEHelper.resolvePlaceholderToRefs(answer_ref_string, display_data);
				if (!answer_value_data) {
					// TODO: error handling, particularly in editor tool - needs to be very visible that there is a problem with the question config
					log.warn('WARNING: no resolved data found for answer_ref_string: ', answer_ref_string);
					return;
				}
				// NOTE: answer_value_data will typically be "answer" output, in which case the actual value data (type and value) will be nested under value
				if (answer_value_data.type == 'answer') {
					answer_value_data = answer_value_data.value.value;
				}
				answer_values.push(answer_value_data.serialize_to_text({ correct_value: true }));
			}
		});

		if (answer_config.answer_template !== undefined && answer_config.answer_template.length) {
			// override with answer_config.answer_template, if present
			if (answer_config.answer_template) {
				answer_markup = answer_config.answer_template;
			}
		} else {
			// use first eval_config.answer_value instead
			let eval_config = display_data.eval_configs[0];

			if (eval_config.compare_method == 'dotjs_template') {
				answer_markup = '"'+ eval_config.user_value +'" should evaluate to true';
			} else {
				let answer_ref_string = eval_config.answer_value;
				if (!answer_ref_string) {
					log.warn('WARNING: no answer_value set');

					return { answer_markup: '' };
				}

				// check if it's a bare widget key
				if (display_data.resolved[answer_ref_string]) {
					answer_markup = '[$'+ answer_ref_string +']';
				} else {
					answer_markup = answer_ref_string;
				}

				// check that the answer string resolves
				let answer_value_data = QEHelper.resolvePlaceholderToRefs(answer_ref_string, display_data);
				if (!answer_value_data) {
					// TODO: error handling, particularly in editor tool - needs to be very visible that there is a problem with the question config
					log.warn('WARNING: no resolved data found for answer_ref_string: ', answer_ref_string);
					return { answer_markup: '' };
				}

				// if options.apply_canonicalization_steps and the answer_value references a QEValue
				if (options.apply_canonicalization_steps && (
					answer_value_data.value instanceof QEValueTree || answer_value_data.value instanceof Eq ||
					(answer_value_data instanceof QEValueAnswer && answer_value_data.value.value instanceof QEValueTree)
				)) {
					// store display_options before they are stripped by canonicalization
					let display_options = answer_value_data.value.display_options;

					// apply canonicalization steps - this is normally only called by the editor
					const answer_value_string = answer_value_data.serialize_to_text({ correct_value: true });
					const answer_value_tree = QEHelper.resolvePlaceholderToTree(answer_value_string, {});
					answer_value_data = QEValGen.applyAnswerCanonicalizationSteps(eval_config, answer_value_tree);
					answer_markup = answer_value_data.display(display_options);
				}
			}
		}
		answer_markup = QEHelper.populateTemplate(answer_markup, display_data, Object.assign({ show_correct: 1, disable_input: 1 }, options));

		let answer = {
			answer_markup: answer_markup,
			answer_values: answer_values,
		};
		return answer;
	}

	/**
	 * Generates output markup and associated widget export data for the solution
	 */
	static generateSolutionOutput(display_data, options: any = {}) {
		let answer_config = display_data.answer_config;
		let solution_template = answer_config.solution_template;
		let solution_answer_key = answer_config.solution_answer_key;
		let solution_markup = '';

		if (solution_template) {
			// solution_template takes precedence over step-by-step solution
			solution_markup = solution_template;
			solution_markup = QEHelper.populateTemplate(solution_markup, display_data, Object.assign({ show_correct: 1, disable_input: 1 }, options));
		} else if (solution_answer_key) {
			if (!solution_answer_key.match(/^\[\$/)) {
				solution_answer_key = '[$' + solution_answer_key + ']';
			}

			// NOTE: currently not passing dotjs_templates through to generateSolutionAnswer, since we do not support in solvers
			let display_options = QEHelper.resolveOptionsString(display_data.answer_config.solution_display_options, display_data);
			let solution_answer_obj = QEValGen.generateSolutionAnswer(solution_answer_key, display_data, display_options);

			if (!solution_answer_obj) {
				log.warn('WARNING: specified answer object missing - ', solution_answer_key, display_data);
				//					solution_markup += '<div class="step">WARNING: answer value <span style="font-weight: 700">'+ solution_answer_key +'</span> unresolved.</div>';
			} else {
				// iterate over solution_answer_obj.value.steps.widget_data, and render graphs and grids
				for (let j = 0; j < solution_answer_obj.value.steps.length; j++) {
					let step = solution_answer_obj.value.steps[j];

					solution_markup += '<div class="step">' + step.desc + '</div>';
				}
			}
		}
		solution_markup = QEHelper.populateTemplate(solution_markup, display_data, Object.assign({ show_correct: 1, disable_input: 1 }, options)).trim();

		return solution_markup;
	}

	static applyAnswerCanonicalizationSteps(input_config, target_tree) {
		// perform user answer canonicalization processing, such as pulling minus out of fractions,
		//		rounding, stripping trailing zeroes, adding implied leading zero, etc.
		const canonicalization_config = JSON.parse(input_config.canonicalization_steps || '{}');
		const canonicalization_steps = [
			{ name: 'order_multiplied_terms', step_key: 'CT_sortMultiplicativeChainTerms' },
			{ name: 'order_added_terms', step_key: 'CT_sortAdditiveChainTerms' },
			{ name: 'remove_function_brackets', step_key: 'CT_removeChainBrackets' },
//			{ name: 'reduce_fraction_numbers', step_key: '' },
//			{ name: 'reduce_fraction_vars', step_key: '' },
//			{ name: 'expand_brackets', step_key: '' },
			{ name: 'extract_numeric_fractions', step_key: 'CT_extractNumericFractions' },
			{ name: 'prepend_leading_zero', step_key: 'prependLeadingZero' },
			{ name: 'strip_trailing_zeros', step_key: 'stripTrailingZeros' },
		];

		// iterate over canonicalization_steps and apply any that are enabled to the user input and to the expected answer
		const applied_steps = [];
		canonicalization_steps.forEach(function(step){
			if (canonicalization_config[step.name]) {
				applied_steps.push(step);
			}
		});

		// build list of included steps and call applySolverSteps - with standard CHAIN and SIGN canonicalization first
		if (applied_steps.length) {
			const canonicalized_target_tree = QESolver.applySolverStepLists(
				QESolver.solvers.canonicalize.initial_steps, applied_steps, target_tree, {}
			).value;
			return canonicalized_target_tree;
		} else {
			return target_tree;
		}
	}

	static evaluateUserAnswers(display_data, user_input_values, options: any = {}) {
		// iterate over expected eval_configs and evaluate user input values
		let answers = [];
		let all_correct = true;

		// compares each field of val1 to val2. Extra fields in val2 ignored.
		// if compare_fields set, only compare those fields found in compare_fields list
		// e.g. deepCompare({a: 1}, {a: 1, b: 1}) --> true
		// e.g. deepCompare({a: 1, b: 1}, {a: 1}) --> false
		let deepCompare = function (val1, val2, compare_fields) {
			if (val1 instanceof Array) {
				if (!(val2 instanceof Array) || val1.length != val2.length) {
					return false;
				}

				for (let i = 0; i < val1.length; i++) {
					if (!deepCompare(val1[i], val2[i], compare_fields)) {
						return false;
					}
				}
			} else if (val1 instanceof Object) {
				if (!(val2 instanceof Object)) {
					return false;
				}

				let field_names = compare_fields;
				if (compare_fields.length) {
					// comparison fields specified
					field_names = compare_fields;
				} else {
					// if not specified, use all fields from val1
					field_names = Object.keys(val1);
				}

				for (let i = 0; i < field_names.length; i++) {
					const field_name = field_names[i];
					if (!deepCompare(val1[field_name], val2[field_name], compare_fields)) {
						return false;
					}
				}
			} else {
				// NOTE: using "==" instead of "===" here because values generated with placeholders are always serialized strings, whereas
				//    client returned values may be numbers. E.g. {x: "5", y: "3"} vs. { x: 5, y: 3 }
				// TODO: undef / truthy checks, so '' != 0
				let equal = (val1 == val2);
				return equal;
			}
			return true;
		};

		// iterate over eval_configs and evaluate each against whatever criteria they specify
		for (let i = 0; i < display_data.eval_configs.length; i++) {
			let eval_config = display_data.eval_configs[i];

			let compare_method = 'serialized'; // default
			if (eval_config.compare_method == 'json_exact') compare_method = 'json_exact';
			if (eval_config.compare_method == 'json_fields') compare_method = 'json_fields';
			if (eval_config.compare_method == 'dotjs_template') compare_method = 'dotjs_template';
			if (eval_config.compare_method == 'self-assessment') compare_method = 'self-assessment';

			// - if type is dotjs_template, then evaluate to string
			let is_correct: boolean = true;
			let user_value, answer_value; // these are used for returning and recording serialized values

			if (compare_method == 'dotjs_template') {
				// evaluate template and check if it returns "true"

				// TODO: we can include passed options.dotjs_templates here, for invocation of evaluation functions by the evaluation template - but leaving it alone for now

				// compile doT.js template
				let eval_template = eval_config.user_value;
				let template_fn;
				try {
					template_fn = doT.template('{{ const log = it["log"]; }}'+ eval_template);
				} catch (err) {
					log.warn('dotjs template compile error: ', err);
					return;
				}
				let template_output;
				if (template_fn) {
					// execute doT.js template
					try {
						template_output = template_fn(Object.assign({}, display_data.resolved));
					} catch (err) {
						log.warn('dotjs error: ', err, display_data.resolved);
						return;
					}
				}

				// compare template_output with "true"
				answer_value = 'true';
				user_value = template_output.trim();

				is_correct &&= user_value == answer_value;
			} else if (compare_method == 'json_exact' || compare_method == 'json_fields') {
				// resolve placeholders on user value
				let user_value_str = eval_config.user_value;
				let user_answer_value = QEHelper.resolvePlaceholderToJSON(user_value_str, display_data);
				if (!user_answer_value) {
					log.warn('Error: could not resolve user value ref: ', user_value_str);
					return { all_correct: false, answers: { answer_markup: 'ERROR: could not resolve user value ref' } };
				}

				// resolve answer_value
				let answer_ref_string = eval_config.answer_value.trim();
				if (!answer_ref_string.length) {
					log.warn('WARNING: no answer_value set');
					return { all_correct: false, answers: { answer_markup: 'WARNING: no answer_value set' } };
				}

				let answer_value_data = QEHelper.resolvePlaceholderToRefs(answer_ref_string, display_data);
				if (!answer_value_data) {
					// TODO: error handling, particularly in editor tool - needs to be very visible that there is a problem with the question config
					log.warn('WARNING: no resolved data found for answer_ref_string: ', answer_ref_string);
					return { all_correct: false, answers: { answer_markup: 'WARNING: no answer_value set' } };
				}

				// serialize values for recording
				answer_value = answer_value_data.serialize_to_text();
				user_value = user_answer_value.serialize_to_text()

				let compare_fields = [];
				if (eval_config.compare_method == "json_fields") {
					compare_fields = eval_config.compare_method_json_fields.split(/,/);
				}
				is_correct &&= deepCompare(JSON.parse(answer_value), JSON.parse(user_value), compare_fields);
			} else if (compare_method == 'self-assessment') {
				// self-assessment is 0|1
				is_correct &&= user_input_values[i] == 1;
			} else if (compare_method == 'serialized') {
				let user_value_str = eval_config.user_value;

				if (user_value_str.match(/^['"]/)) {
					log.warn('Warning: user answer value contains leading quotes. Likely misconfigured input widget.', user_value_str);
				}

				// resolve placeholders on user value
				let user_answer_value = QEHelper.resolvePlaceholderToRefs(user_value_str, display_data);
				if (!user_answer_value) {
					log.warn('Error: could not resolve user value ref: ', user_value_str);
					return { all_correct: false, answers: { answer_markup: 'ERROR: could not resolve user value ref' } };
				}

				// resolve answer_value
				let answer_ref_string = eval_config.answer_value.trim();
				if (!answer_ref_string.length) {
					log.warn('WARNING: no answer_value set');
					return { all_correct: false, answers: { answer_markup: 'WARNING: no answer_value set' } };
				}

				let answer_value_data = QEHelper.resolvePlaceholderToRefs(answer_ref_string, display_data);
				if (!answer_value_data) {
					// TODO: error handling, particularly in editor tool - needs to be very visible that there is a problem with the question config
					log.warn('WARNING: no resolved data found for answer_ref_string: ', answer_ref_string);
					return { all_correct: false, answers: { answer_markup: 'WARNING: no answer_value set' } };
				}

				// NOTE: answer_value_data will typically be "answer" output, in which case the actual value data (type and value) will be nested under value
				if (answer_value_data.type == 'answer') {
					answer_value_data = answer_value_data.value.value;
				}

				// TODO: apply further type-checking here and server warnings

				// only apply canonicalization steps if user input is a tree value
				if (user_answer_value instanceof QEValueTree) {
					const correct_answer_value_string = answer_value_data.serialize_to_text({ correct_value: true });
					const correct_answer_value_tree = QEHelper.resolvePlaceholderToTree(correct_answer_value_string, {});
					const user_answer_value_tree = QEHelper.resolvePlaceholderToTree(user_answer_value.serialize_to_text(), {});

					// canonicalize user_answer_value
					answer_value_data = new QEValueString({ value: QEValGen.applyAnswerCanonicalizationSteps(eval_config, correct_answer_value_tree).serialize_to_text() });
					user_answer_value = new QEValueString({ value: QEValGen.applyAnswerCanonicalizationSteps(eval_config, user_answer_value_tree).serialize_to_text() });
				} else if (typeof user_answer_value == "string") {
					user_answer_value = new QEValueString({ value: user_answer_value });
				}


				// compare serialized user_value and answer_value
				answer_value = answer_value_data.serialize_to_text({ correct_value: true });
				user_value = user_answer_value.serialize_to_text({ user_value: true });

				is_correct &&= user_value == answer_value;
			}

			// NOTE: we currently only generate the step-by-step solution and correct display value if the user answers incorrectly
			if (is_correct) {
				let answer = {
					user_value: user_value,
					answer_value: answer_value,
					is_correct: is_correct
				};

				// if a multi-choice input widget was used, we must send the widget_key of that widget back so the client can reflect the correct value on the widget,
				// - however the input configs and evaluation configs have been decoupled, so we return the widget key of the first input_config
				if (display_data.input_configs.length) answer.widget_key = display_data.input_configs[0].widget_key;

				answers.push(answer);
			} else {
				all_correct = false;

				// generate correct answer markup
				let answer_output = QEValGen.generateAnswerOutput(display_data, options);

				// generate solution_markup
				let solution_markup = '';
				let solution_answer_key = display_data.answer_config.solution_answer_key;
				let solution_template = display_data.answer_config.solution_template;
				let widget_data = null;

				if (solution_template) {
					// solution_template takes precedence over step-by-step solution
					solution_markup = solution_template;
					solution_markup = QEHelper.populateTemplate(solution_markup, display_data, Object.assign({ show_correct: 1, disable_input: 1 }, options));
				} else if (solution_answer_key) {
					if (!solution_answer_key.match(/^\[\$/)) {
						solution_answer_key = '[$' + solution_answer_key + ']';
					}

					// NOTE: currently not passing dotjs_templates through to generateSolutionAnswer, since we do not support in solvers
					let display_options = QEHelper.resolveOptionsString(display_data.answer_config.solution_display_options, display_data);
					let solution_answer_obj = QEValGen.generateSolutionAnswer(solution_answer_key, display_data, display_options);

					if (!solution_answer_obj) {
						log.warn('WARNING: specified answer object missing - ', solution_answer_key, display_data);
						//					solution_markup += '<div class="step">WARNING: answer value <span style="font-weight: 700">'+ solution_answer_key +'</span> unresolved.</div>';
					} else {
						// iterate over solution_answer_obj.value.steps.widget_data, and render graphs and grids
						for (let j = 0; j < solution_answer_obj.value.steps.length; j++) {
							let step = solution_answer_obj.value.steps[j];

							solution_markup += '<div class="step">' + step.desc + '</div>';
						}
					}
				}
				solution_markup = QEHelper.populateTemplate(solution_markup, display_data, Object.assign({ show_correct: 1, disable_input: 1 }, options)).trim();

				let answer = {
					user_value: user_value,
					is_correct: is_correct,
					answer_value: answer_value,
					answer_markup: answer_output.answer_markup,
					solution_markup: solution_markup,
				};

				// if a multi-choice input widget was used, we must send the widget_key of that widget back so the client can reflect the correct value on the widget,
				// - however the input configs and evaluation configs have been decoupled, so we return the widget key of the first input_config
				if (display_data.input_configs.length) answer.widget_key = display_data.input_configs[0].widget_key;

				answers.push(answer);
			}
		}

		return {
			answers: answers,
			all_correct: all_correct,
		};
	}
}

