import { QEHelper, shuffle } from '../QEHelper';
import { QEWidget } from '../QEWidget';
import * as jQuery from 'jquery';
import { log } from '../QE';

interface MCChoice {
	display: string;
	value: any; // QEValue
	index: number;
	is_correct: boolean;
	value_key: string;
}

export class QEWidgetMC extends QEWidget {
	choices: MCChoice[];
	display_options: { [key: string]: any };
	correct_index: number;
	init_correct_index: number;
	user_index: number;
	user_value: any;

	/**
	 * Multiple Choice display and input widget. Displays a shuffled set of "choices", which should be Eq widget values, but may be rendered by other widget types
	 * @constructor
	 * @param {Object} data
	 * @param {Object[]} data.choices - array of choice configs for a multiple choice question
	 * @param {Object} data.choices[].value - Widget object (usually Eq) containing the choice value
	 * @param {bool} data.choices[].is_correct - flag indicating whether this choice should be accepted as the correct answer
	 * @param {string} data.choices[].answer_key - string specifying the answer key to use for evaluating a solution. E.g. "a1"
	 * @param {string} [data.choices[].target_key] - string specifying the name key of the value to use for display
	 * @param {Object} [data.choices[].dependencies]
	 * @param {string} [data.choices[].display_options]
	 * @param {bool} data.randomize_answer - flag indicating whether one of the choices should be randomly chosen as correct
	 * @param {bool} data.sort_answers - flag indicating whether the answer choices should be presented in sorted (simple numeric sorting) order
	 * @param {bool} data.shuffle_answers - flag indicating whether the answer choices should be presented in shuffled order
	 */    
	constructor(data, display_options) {
		super();

		data = data || {};

		this.choices = data.choices || [];
		this.display_options = display_options;
	}

	/**
	 * Instantiates and returns a Multiple Choice widget from serialized data
	 * @param {string} serialized - serialized string containing choice data and display config
	 * @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	 * @param {Object} [options]
	 */
	static instantiate(serialized, resolved_data, options) {
		let deserialized = JSON.parse(serialized);
		options = options || {};
		let choices = [];
		let correct_index = -1;
		let init_correct_index = -1;
		let user_index = -1;
		let user_value = null;

		// NOTE: a maximum of one choice can be "correct", and used to set correct_index
		// - if more than one choice has "is_correct" checked, then the latest is_correct choice is used
		// - if a "correct_value" is specified, then the latest matching choice is used
		// - if "randomize_answer" is checked, then a random choice is used

		for (let i = 0; i < deserialized.choices.length; i++) {
			let choice = deserialized.choices[i];

			// resolve any references
			let resolved_value = QEHelper.resolvePlaceholderToRefs(choice.value, resolved_data);
			if (!resolved_value) return null;

			// resolve "display" field as populated template - may be empty
			let display_ml = QEHelper.populateTemplate(choice.display === undefined ? '' : choice.display, resolved_data, { require_dependencies: 1 });
			if (display_ml === null) {
				return null;
			}

			// if "display" field empty, render resolved_value instead
			if (!display_ml.length) {
				let resolved_display = QEHelper.resolvePlaceholderToMarkup(choice.value, resolved_data);
				display_ml = resolved_display.value;
			}

			let resolved_choice = { value: resolved_value, index: i, value_key: choice.value, display: display_ml };

			// track correct_index
			if (choice.is_correct) correct_index = i;

			choices.push(resolved_choice);
		}

		// build map of excluded values
		let exclude_vals = {};
		let exclude_val_str = deserialized.exclude_vals;
		if (exclude_val_str !== undefined && exclude_val_str.length) {
			let resolved_excludes = QEHelper.resolvePlaceholderToString(exclude_val_str, resolved_data);
			let resolved_exclude_val_str = resolved_excludes.value;

			if (resolved_exclude_val_str) {
				let exclude_val_list = resolved_exclude_val_str.split(/,/);
				for (let ev_idx = 0; ev_idx < exclude_val_list.length; ev_idx++) {
					exclude_vals[exclude_val_list[ev_idx]] = 1;
				}
			} else return null;
		}

		// resolve correct_value, if set
		let correct_value;
		let correct_val_str = deserialized.correct_value;
		if (correct_val_str !== undefined && correct_val_str.length) {
			let resolved_correct = QEHelper.resolvePlaceholderToString(correct_val_str, resolved_data);
			if (resolved_correct) {
				correct_value = resolved_correct.value;
			} else return null;
		}

		// if a correct_value was specified, look for matching choice
		if (correct_value !== undefined) {
			// go through choices and set correct_index on match
			choices.map(function (x, i) {
				// check choice value type - depending on type (e.g. widget.eq, tree, answer) serialization method may differ
				let serialized = '';
				let choice_value = x.value;

				serialized = choice_value.serialize_to_text();
				if (serialized === correct_value) {
					correct_index = i;
				}
			});
		}

		// if deserialized.randomize_answer set, then randomly select one of the choices as correct
		if (deserialized.randomize_answer) {
			correct_index = Math.trunc(Math.random() * choices.length);
		}

		// save pre-filtered correct index
		init_correct_index = correct_index;

		// if options.regen_data, set correct_index, prior to any value culling
		if (options.regen_data) {
			user_value = options.regen_data.user_value;

			correct_index = options.regen_data.init_correct_index;
		}

		// cull exclude values
		let num_culled = 0;
		choices = choices.filter(function (x, i) {
			// check choice value type - depending on type (e.g. widget.eq, tree, answer) serialization method may differ
			let serialized = '';
			let choice_value = x.value;

			serialized = choice_value.serialize_to_text();

			// if previous values have been filtered out, the index value of this item needs to be reduced
			x.index -= num_culled;

			let serialized_display = '';
			if (x.display !== undefined && x.display.length) {
				serialized_display = x.display;
			}

			// ensure correct_index choice is not culled
			if (exclude_vals[serialized] && i != correct_index) {
				// if a lower index value is culled, decrement correct_index
				if (i < correct_index) correct_index--;
				num_culled++;

				return false;
			} else if (serialized_display && exclude_vals[serialized_display] && i != correct_index) {
				// if a lower index value is culled, decrement correct_index
				if (i < correct_index) correct_index--;
				num_culled++;

				return false;
			} else return true;
		});

		// cull duplicates - first build a map of serialized values and count of each, then filter out duplicates
		let values = {};
		let display_values = {};
		choices.map(function (x, i) {
			// check choice value type - depending on type (e.g. widget.eq, tree, answer) serialization method may differ
			let serialized = '';
			let choice_value = x.value;

			serialized = choice_value.serialize_to_text();

			if (!values[serialized]) values[serialized] = 0;
			values[serialized]++;

			if (x.display !== undefined && x.display.length) {
				let serialized_display = x.display;
				if (!display_values[serialized_display])
					display_values[serialized_display] = 0;
				display_values[serialized_display]++;
			}
		});
		num_culled = 0;
		choices = choices.filter(function (x, i) {
			// check choice value type - depending on type (e.g. widget.eq, tree, answer) serialization method may differ
			let serialized = '';
			let choice_value = x.value;

			serialized = choice_value.serialize_to_text();

			// if previous values have been filtered out, the index value of this item needs to be reduced
			x.index -= num_culled;

			let serialized_display = '';
			if (x.display !== undefined && x.display.length) {
				serialized_display = x.display;
			}

			// ensure correct_index choice is not culled
			if (values[serialized] > 1 && (i - num_culled) != correct_index) {
				values[serialized]--;

				// if a lower index value is culled, decrement correct_index
				if ((i - num_culled) < correct_index) correct_index--;
				num_culled++;

				// decrement display_values count for corresponding serialized_display
				if (display_values[serialized_display]) {
					display_values[serialized_display]--;
				}

				return false;
			} else if (serialized_display && display_values[serialized_display] > 1 && (i - num_culled) != correct_index) {
				display_values[serialized_display]--;

				// if a lower index value is culled, decrement correct_index
				if ((i - num_culled) < correct_index) correct_index--;
				num_culled++;

				// decrement values count for corresponding serialized value
				if (values[serialized]) {
					values[serialized]--;
				}

				return false;
			} else return true;
		});

		// if regenerating order from regen_data, then ignore sort and shuffle flags
		if (options.regen_data) {
			// sort choices to match regen_data choice order
			let reordered_choices = [];
			for (let i = 0; i < options.regen_data.choices.length; i++) {
				for (let j = 0; j < choices.length; j++) {
					if (options.regen_data.choices[i].index == choices[j].index) reordered_choices.push(choices[j]);
				}
			}
			choices = reordered_choices;

		} else if (deserialized.sort_answers) {
			choices.sort(function (a, b) {
				a = a.value.serialize_to_text();
				b = b.value.serialize_to_text();
				if (!Number.isNaN(Number(a)) && !Number.isNaN(Number(a))) {
					// if both values are numbers, do numerically compare
					a = Number(a);
					b = Number(b);
				}

				return a < b ? -1 : a > b ? 1 : 0;
			});
		} else if (deserialized.shuffle_answers) {
			choices = shuffle(choices);
		}

		// build map and resolve any [$name] placeholders in display_options
		let display_options = QEHelper.resolveOptionsString(deserialized.display_options, resolved_data);

		// check if there was an unresolved dependency
		if (!display_options) return null;

		let widget = new QEWidgetMC({ choices: choices }, display_options);

		// set non-public values
		widget.correct_index = correct_index;
		widget.init_correct_index = init_correct_index;
		widget.user_index = user_index;
		widget.user_value = user_value;

		return widget;
	}

	/**
	* Returns string representing serialized value of correct/user choice
	* @returns {string} Serialized value string
	*/
	serialize_to_text(options: { [key: string]: string | boolean } = {}): string {
		if (options.correct_value) {
			const correct_data = this.getCorrectChoice();
			if (correct_data) {
				return correct_data.value.serialize_to_text(options);
			} else {
				log.warn('Error: correct choice for mc not set: ', this);
			}
		} else if (options.user_value) {
			const choice_data = this.user_value;
			if (choice_data) {
				return choice_data.value.serialize_to_text(options);
			} else {
				log.wanr('Error: user choice for mc not set: ', this);
			}
		} else if (options.whole_widget) {
			// TODO: serialize display config and all choices
		} else {
			// default
			log.warn('ERROR: serializing MC needs option flag(correct_value|user_value|whole_widget): ', this);
		}
	}

	/**
	 * Returns widget markup for inclusion in question output
	 * @returns {string} Generated display markup
	 */
	display(options) {
		
		options = options || {};

		let display_options = Object.assign({}, this.display_options);
		let ml = '';
		
		display_options = Object.assign(display_options, options);

		let container_classes = display_options.container_classes || "";
		let container_styles = "";
		let item_classes = display_options.item_classes || "";;
		let item_styles = "";

		if (display_options.container_styles) {
			container_styles = ' style="' + display_options.container_styles + '"';
		}

		if (display_options.item_styles) {
			item_styles = ' style="' + display_options.item_styles + '"';
		}

		if (options.show_correct) {
			// if show_correct flag set, display ONLY the correct choice
			for (let i = 0; i < this.choices.length; i++) {
				let choice = this.choices[i];
				if (choice.index != this.correct_index) continue;
				// render according to display_options
				if (display_options.display_as && display_options.display_as.value instanceof QEWidget) {
					// given that choice values may be of type widget, tree, answer, etc., need to display accordingly
					let choice_value = choice.value;
					// pass current value to designated display widget
					display_options.value = choice_value;
					ml += display_options.display_as.value.display(display_options);
				} else {
					// if "display" populated, present that instead of "value"
					if (choice.display.length) {
						// TODO: call populateTemplate on "display" field here instead of during widget instantiation?
						ml += choice.display;
					} else {
						// pass display_options, so options specified at the MC widget level are reflected by individual choices
						ml += QEHelper.resolvePlaceholderToMarkup(choice.value_key, options.resolved).value;
					}
				}
			}
			return ml;
		}

		ml += '<div class="choice_set ' + container_classes + '" ' + container_styles +'>';
		
		for (let i = 0; i < this.choices.length; i++) {
			let choice = this.choices[i];
			ml += '<div class="answer '+ item_classes +'" ' + item_styles + '>';
			// render according to display_options
			if (display_options.display_as && display_options.display_as.value instanceof QEWidget) {
				// given that choice values may be of type widget, tree, answer, etc., need to display accordingly
				let choice_value = choice.value;
				// pass current value to designated display widget
				display_options.value = choice_value;
				ml += display_options.display_as.value.display(display_options);
			} else {
				// if "display" populated, present that instead of "value"
				if (choice.display.length) {
					// TODO: call populateTemplate on "display" field here instead of during widget instantiation?
					ml += choice.display;
				} else {
					// pass display_options, so options specified at the MC widget level are reflected by individual choices
					ml += QEHelper.resolvePlaceholderToMarkup(choice.value_key, options.resolved).value;
				}
			}
			ml += '</div>';
		}
		
		ml += '</div>';

		return ml;
	}

	bindEventHandlers(widget_container) {
		const self = this;

		widget_container.on('click', '.choice_set .answer:not(.disable_input)', function(){
			if (widget_container.is('.disable_input')) return; // skip if disabled

			var event_item = jQuery(this);
			var choice_index = event_item.index();

			// select choice
			event_item.parent().find('.answer').removeClass('selected');
			event_item.addClass('selected');

			self.setInputValue(choice_index);
		});
	}

	/**
	 * Sets the widget selected input choice index and value
	 * @param {number} choice_index
	 */
	setInputValue(choice_index) {
		var choice = this.choices[choice_index];
		this.user_index = choice.index;
		this.user_value = choice.value;
	}

	/**
	 * Gets the widget selected input choice value
	 */
	getInputValue(input_widgets?) { return this.user_value; }

	isUserInputComplete(): boolean {
		return typeof this.user_value !== null;
	}

	isUserInputAutoSubmittable(): boolean {
		return typeof this.user_value !== null;
	}

	/**
	 * Returns the choice data for the choice marked as is_correct
	 */
	getCorrectChoice() {
		// go through this.choices and return the choice with original index == correct_index (choice order can change due to sorting or shuffling)
		for (var i = 0; i < this.choices.length; i++) {
			if (this.choices[i].index == this.correct_index) return this.choices[i];
		}
		return null;
	}

	exportValue(options: {allow_private?: boolean} = {}) {
		const export_value = {
			type: 'multi-choice',
			choices: this.choices.map(function (x) {
				return {
					index: x.index,
					value: x.value.serialize_to_text(),
					display: x.display === undefined ? '' : x.display,
				};
			}),
			init_correct_index: undefined,
		};
		if (options.allow_private) {
			export_value.init_correct_index = this.init_correct_index;
		}

		return export_value;
	}
}
